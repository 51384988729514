import React from "react";
import "./notes.css";

export default function Notes() {
  return (
    <div className="noteParent">
      <div className="noteContainer d-flex flex-column gap-2">
        {/* <div class="container"> */}
        <div className="d-flex justify-content-between align-items-center">
          <h5 style={{fontWeight:'700', fontSize:'clamp(12px, 1.2vw, 15px)'}} className="mb-0 text-black">Notes</h5>
          <button type="button" class="btn btn-light d-flex align-items-center" style={{height:'30px',  fontSize:'clamp(12px, 1.2vw, 15px)'}}>
          <i class="bi bi-plus"></i> Add
          </button>
        </div>
        <div class="note d-flex justify-content-between flex-wrap px-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label text-nowrap" for="exampleRadios1" style={{fontSize:'clamp(12px, 1.2vw, 15px)'}}>
                Meeting with V-Star
              </label><br />
              <small class="text-muted" style={{fontSize:'clamp(12px, 1.2vw, 13px)'}}>
                  Discuss the Redesign...
            </small>
            </div>
            <div class="d-flex justify-content-start ps-3" style={{height:'min-content'}}>
              <span class="badge bg-danger me-2">Today</span>
              <span class="badge bg-warning">Meeting</span>
              {/* <small class="text-muted ms-auto">May 13</small> */}
            </div>
        </div>
        <div class="note d-flex justify-content-between flex-wrap px-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label class="form-check-label text-nowrap" for="exampleRadios1" style={{fontSize:'clamp(12px, 1.2vw, 15px)'}}>
                Meeting with Nike
              </label><br />
              <small class="text-muted" style={{fontSize:'clamp(12px, 1.2vw, 13px)'}}>
                  Discuss the Products...
            </small>
            </div>
            <div class="d-flex justify-content-start flex-wrap ps-3" style={{height:'min-content'}}>
              <span class="badge bg-danger me-2">Tomorrow</span>
              <span class="badge bg-warning">Meeting</span>
              {/* <small class="text-muted ms-auto">May 14</small> */}
            </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
