import { Pagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./mainCard.css";

const header = [
  { name: "name", label: "Name", active: true, width: "30%" },
  { name: "job_position", label: "Job Title", active: true, width: "25%" },
  { name: "contact_info", label: "Contact Info", active: true, width: "30%" },
  { name: "Status", label: "Active", active: true, width: "15%" },
];

export default function EmployeeList() {
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const rowsPerPage = 3;
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/account/users-list/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEmployees(response.data);
      setTotalPages(Math.ceil(response.data.length / rowsPerPage));
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getStatusColor = (status) => {
    return status ? "green" : "tomato";
  };

  const getStatusText = (status) => {
    return status ? "Active" : "Inactive";
  };

  const handleRowClick = (id) => {
    navigate(`/user/empDetails/${id}`);
  };

  const paginatedEmployees = employees.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const SkeletonRow = () => (
    <tr style={{ height: "40px" }}>
      {header.map((item) => (
        <td key={item.name}>
          <span className="placeholder-glow w-100">
            <span className="placeholder col-12"></span>
          </span>
        </td>
      ))}
    </tr>
  );

  const UpcomingRow = () => (
    <tr style={{ height: "40px" }}>
      {header.map((item, index) => (
        <td 
          key={item.name} 
          style={{ 
            color: "grey",
            fontSize: "clamp(12px, 1.3vw, 15px)",
            fontWeight: "600",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...(index === 0 ? { paddingLeft: "40px" } : {})
          }}
        >
          Upcoming
        </td>
      ))}
    </tr>
  );

  return (
    <div>
      <div className="empContainer rounded-4 overflow-auto">
        <div className="empHeader d-flex justify-content-between align-items-center">
          <Link to="/user/emp" className="text-decoration-none text-black">
            {loading ? (
              <span className="placeholder-glow pt-2" style={{ width: "100px", height: "24px" }}>
                <span className="placeholder col-12 h-100"></span>
              </span>
            ) : (
              <h6 className="pt-2">Employees</h6>
            )}
          </Link>
          {/* {loading ? (
            <span className="placeholder-glow" style={{ width: "80px", height: "38px" }}>
              <span className="placeholder col-12 h-100"></span>
            </span>
          ) : (
            <div className="dropdown">
              <button
                className="btn dropdown-toggle d-flex justify-content-between align-items-center gap-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "white" }}
              >
                <i className="lni lni-list"></i>filter
              </button>
              <ul className="dropdown-menu p-0">
                <li><p className="dropdown-item">Job Title</p></li>
                <li><p className="dropdown-item">Team Lead</p></li>
                <li><p className="dropdown-item">Status</p></li>
              </ul>
            </div>
          )} */}
        </div>
        <table className="w-100" style={{ tableLayout: "fixed" }}>
          <colgroup>
            {header.map((item) => item.active && (
              <col key={item.name} style={{ width: item.width }} />
            ))}
          </colgroup>
          <thead style={{ backgroundColor: loading ? "transparent" : "#120e4d", color: loading ? "black" : "white" }}>
            <tr style={{
              height: "40px",
              borderRadius: "10px",
              overflow: "hidden",
              fontSize: "clamp(13px, 1.3vw, 15px)",
              fontWeight: "600",
            }}>
              {loading ? (
                <th colSpan={header.length}>
                  <span className="placeholder-glow" style={{ width: "70%" }}>
                    <span className="placeholder col-12"></span>
                  </span>
                </th>
              ) : (
                header.map((item) => item.active && (
                  <th key={item.name} style={{ backgroundColor: "#120e4d !important" }}>
                    {item.label}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: rowsPerPage }).map((_, index) => (
                <SkeletonRow key={index} />
              ))
            ) : (
              <>
                {paginatedEmployees.map((employee) => (
                  <tr
                    key={employee.id}
                    style={{
                      height: "40px",
                      fontSize: "clamp(12px, 1.3vw, 15px)",
                      fontWeight: "600",
                      cursor: "pointer", // Add cursor pointer for row hover effect
                    }}
                    onClick={() => handleRowClick(employee.id)} // Add click handler
                  >
                    <td className="d-flex gap-3 align-items-center" style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                      <img
                        style={{
                          width: "25px",
                          height: "25px",
                          flexShrink: 0,
                          borderRadius: "50%",
                        }}
                        src={employee.profile_photo || "/assets/user.png"}
                        alt="user img"
                      />
                      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        {employee.name || "N/A"}
                      </div>
                    </td>
                    <td style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                      {employee.job_position?.job_title || "N/A"}
                    </td>
                    <td style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                      {employee.email}
                    </td>
                    <td style={{
                      color: getStatusColor(employee.status),
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                      {getStatusText(employee.status)}
                    </td>
                  </tr>
                ))}
                {paginatedEmployees.length < rowsPerPage && Array.from({ length: rowsPerPage - paginatedEmployees.length }).map((_, index) => (
                  <UpcomingRow key={`upcoming-${index}`} />
                ))}
              </>
            )}
          </tbody>
        </table>
        <div className="empFooter d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap-reverse">
          <p style={{ minHeight: "50px", color: "grey"}} className="showText d-flex align-items-center mb-0">
            {loading ? (
              <span className="placeholder-glow w-100">
                <span className="placeholder col-12"></span>
              </span>
            ) : (
              `Showing ${(page - 1) * rowsPerPage + 1} to ${Math.min(page * rowsPerPage, employees.length)} of ${employees.length} Entries`
            )}
          </p>
          {loading ? (
            <span className="placeholder-glow" style={{ width: "200px" }}>
              <span className="placeholder col-12" style={{ height: "32px" }}></span>
            </span>
          ) : (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="small"
              className="mt-1 mt-sm-0"
            />
          )}
        </div>
      </div>
    </div>
  );
}
