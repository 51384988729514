import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import clsx from 'clsx';

export default function MuiSelect({ options, label, className, onChange, error, helperText, value, name }) {
  const handleChange = (event, newValue) => {
    onChange({
      target: {
        name: name,
        value: newValue ? newValue : null
      }
    });
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') return option;
    return option.location ? `${option.name} - ${option.location}` : option.name;
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      sx={{ 
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: error ? 'red' : undefined,
          },
          '&:hover fieldset': {
            borderColor: error ? 'red' : undefined,
          },
          '&.Mui-focused fieldset': {
            borderColor: error ? 'red' : undefined,
          },
        },
      }}
      value={value}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label} 
          error={error}
          helperText={helperText}
          size='small'
        />
      )}
      className={clsx('d-flex align-items-center', className)}
      style={{ fontFamily: '"Montserrat", sans-serif' }}
      size='small'
      onChange={handleChange}
      clearIcon={value ? undefined : null}
    />
  );
}