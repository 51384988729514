import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../redux/emp";
import { InputOtp } from 'primereact/inputotp';
import CircularProgress from "@mui/material/CircularProgress";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import './login.css'

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [loginError, setLoginError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [showForgotForm, setShowForgotForm] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [token, setToken] = useState("");
  const [accessToken, setAccessToken] = useState(""); // Save access token after OTP verification
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveEmail, setSaveEmail] = useState(""); // Save the email after OTP generation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitLogin = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/login/",
        {
          email: data.emailLogin,
          password: data.password,
        }
      );

      if (response.status === 200) {
        const { access, refresh, user } = response.data;
        console.log("user is",user)
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("userId", user.id)
        dispatch(setUserData(user));
        navigate("/user/home");
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          setError("emailLogin", { message: data.message });
          setError("password", { message: data.message });
          setLoginError(data.message);
        } else {
          setLoginError("Email or Password is not Valid. Please try again.");
        }
      } else {
        setLoginError("Network error. Please check your connection.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitForgotPassword = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/generate_otp/",
        {
          email: data.email,
        }
      );

      if (response.status === 200) {
        setSaveEmail(data.email); // Save the email when OTP is generated
        setShowForgotForm(false);
        setShowOtpForm(true); // Show OTP form after successful OTP generation
        console.log("OTP has been sent to your email.", response.data);
      } else {
        setForgotError("Password reset request failed. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          setForgotError(data.detail);
        } else {
          setForgotError("An unexpected error occurred. Please try again.");
        }
      } else {
        setForgotError("Network error. Please check your connection.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitOtp = async () => {
    if (token.length === 6) {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          "https://hrmsbackend.prosevo.com/api/account/verify_otp/",
          {
            otp: parseInt(token),
            email: saveEmail, // Send the saved email along with the OTP
          }
        );

        console.log("OTP submitted successfully:", response.data);
        setAccessToken(response.data.access); // Save the access token from the API response
        setShowOtpForm(false); // Hide OTP form
        setShowResetPasswordForm(true); // Show reset password form
      } catch (error) {
        console.error("Error submitting OTP:", error);
        setForgotError("Failed to submit OTP. Please try again.");
        // console.log("forget error",forgotError)
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setForgotError("Please enter a valid 6-digit OTP.");
    }
  };

  const onSubmitResetPassword = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setForgotError("Passwords do not match.");
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/reset_password/",
        {
          new_password: data.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use saved access token in headers
          },
        }
      );

      console.log("Password updated successfully:", response.data);
      setShowResetPasswordForm(false); // Hide reset password form
      setShowForgotForm(false); // Hide forgot form
      setShowOtpForm(false); // Hide OTP form
    } catch (error) {
      console.error("Error updating password:", error);
      setForgotError("Failed to update password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="loginContainer w-100 h-100 d-flex align-items-center justify-content-center position-relative"
      style={{
        minHeight: "100vh",
        backgroundImage:
          "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsFj58uX-BW1EDozMCFqkH0Q_v8Tp8YrNOtQ&s)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundBlendMode: "hard-light",
      }}
    >
      <div className="position-absolute d-flex flex-column" style={{left: 20, top: 20}} >
        <p className="mb-0" style={{fontSize:'clamp(20px, 2.5vw, 30px)'}}>PROSEVO</p>
        <p style={{fontSize:'clamp(12px, 1.1vw, 16px)'}}>TECHNOLOGIES</p>
      </div>
      <div
        className="Logincontent rounded-4 border border-2 w-100"
        style={{
          maxWidth: 500,
          width: "50%",
          height: "450px",
          maxHeight: 600,
          backdropFilter: "blur(20px)",
          minWidth: 300,
          boxShadow: "0 0 40px rgba(8, 7, 16, 0.6)",
        }}
      >
        {showResetPasswordForm ? (
          <form
            className="w-100 h-100 d-flex flex-column gap-4 px-4 px-md-5 py-3 position-relative"
            onSubmit={handleSubmit(onSubmitResetPassword)}
          >
            <h3 className="align-self-center mt-4">Set New Password</h3>
            <input
              type="password"
              className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
              placeholder="New Password"
              style={{background: '#ead8f9'}}
              {...register("newPassword", { required: true })}
            />
            <input
              type="password"
              className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
              placeholder="Confirm Password"
              style={{background: '#ead8f9'}}
              {...register("confirmPassword", { required: true })}
            />
            <button
              className="btn mt-3 text-white"
              type="submit"
              style={{ background: "#120e4d" }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              ) : (
                "Update Password"
              )}
            </button>
            {forgotError && (
              <p className="text-danger align-self-center">{forgotError}</p>
            )}
          </form>
        ) : showOtpForm ? (
          <div className="w-100 h-100 d-flex flex-column gap-4 px-md-5 px-4 py-3 position-relative">
            <h4 className="align-self-center mt-4">Submit your OTP</h4>
            <div className="card flex justify-content-center border-0" style={{background:'inherit'}}>
              <InputOtp value={token} onChange={(e) => setToken(e.value)} integerOnly length={6}/>
              <button
                onClick={onSubmitOtp}
                className="btn mt-5 text-white"
                disabled={isSubmitting || token.length < 6}
                style={{ background: "#120e4d" }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                ) : (
                  "Submit OTP"
                )}
              </button>
            </div>
            {forgotError && (
              <p className="text-danger align-self-center">{forgotError}</p>
            )}
          </div>
        ) : showForgotForm ? (
          <form
            className="w-100 h-100 d-flex flex-column gap-4 px-4 px-md-5 py-3 position-relative"
            onSubmit={handleSubmit(onSubmitForgotPassword)}
          >
            <h4 className="align-self-center mt-4">Forgot Password</h4>
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Enter your email"
              style={{background: '#ead8f9'}}
              {...register("email", { required: true })}
            />
            <button
              className="btn mt-5 text-white"
              type="submit"
              style={{ background: "#120e4d" }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              ) : (
                "Reset Password"
              )}
            </button>
            {forgotError && (
              <p className="text-danger align-self-center">{forgotError}</p>
            )}
          </form>
        ) : (
          <form
            className="w-100 h-100 d-flex flex-column gap-4 px-4 px-md-5 py-3 position-relative"
            onSubmit={handleSubmit(onSubmitLogin)}
          >
            <h4 className="align-self-center mt-4">Sign In</h4>
            <input
              type="email"
              className={`form-control ${errors.emailLogin ? "is-invalid" : ""}`}
              placeholder="Enter email"
              style={{background: '#ead8f9'}}
              {...register("emailLogin", { required: true })}
            />
            <input
              type="password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              placeholder="Enter password"
              style={{background: '#ead8f9'}}
              {...register("password", { required: true })}
            />
            <button
              className="btn mt-4 text-white"
              type="submit"
              style={{ background: "#120e4d" }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              ) : (
                "Login"
              )}
            </button>
            <p
              className="align-self-center position-absolute"
              style={{ bottom: "20px", cursor: "pointer", fontSize:'clamp(13px, 1.3vw, 15px)' }}
              onClick={() => setShowForgotForm(true)}
            >
              Can't access your Account?{" "}
              <span className="text-primary text-decoration-underline">
                Forgot
              </span>
            </p>
            {loginError && (
              <p className="text-danger align-self-center">{loginError}</p>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
