import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Spinner from '../ogComponent/spinner';

export default function Shifts() {
    const [shifts, setShifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchShifts = async () => {
            const token = localStorage.getItem("accessToken"); // Get token from localStorage
            if (!token) {
                setError('No token found');
                return;
            }

            try {
                const response = await axios.get("https://hrmsbackend.prosevo.com/api/account/shifts/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Setting the shifts data received from the API
                setShifts(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching shifts data:", err);
                setError('Error fetching shifts data');
                setLoading(false);
            }
        };

        fetchShifts();
    }, []);

    const handleCardClick = (id) => {
        console.log(`Shift ${id} clicked`);
        navigate(`/user/shift/${id}`);
    };

    return (
        <div className='container-fluid px-3 px-md-4 d-flex flex-column gap-3'>
            <div className="d-flex flex-column justify-content-start mt-3 mb-1">
                <h3 className="fw-light mb-0">Shifts</h3>
                <p
                    className="align-self-start mb-0"
                    style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight: '600' }}
                >
                    Total {shifts.length} Shift{shifts.length !== 1 ? "s" : ""}
                </p>
            </div>

            {loading ? (
                <Spinner />
            ) : error ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                >
                    <p className="text-danger">Error loading shifts data</p>
                </div>
            ) : shifts.length === 0 ? (
                <div
                    className="d-flex justify-content-center align-items-end position-relative"
                    style={{ height: "50vh" }}
                >
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{
                            backgroundImage: "url(/assets/file.png)",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            opacity: 0.3,
                        }}
                    ></div>
                    <p className="m-0 fw-light fs-5 position-relative">No Shifts!</p>
                </div>
            ) : (
                <div className="row">
                    {shifts.map((item) => (
                        <div
                            key={item.id}
                            className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            style={{ cursor: "pointer", minWidth: "270px" }}
                            onClick={() => handleCardClick(item.id)}
                        >
                            <div className="card h-100 px-3">
                                <div className="card-body d-flex flex-column py-2 px-0">
                                    <div className="p-2">
                                        <h6
                                            className="card-title"
                                            style={{
                                                fontSize: "clamp(12px, 1.2vw, 14px)",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "100%",
                                            }}
                                        >
                                            {item.shift_name}
                                        </h6>
                                    </div>
                                    <div
                                        className="rounded-3 px-3 py-2 mb-2"
                                        style={{
                                            backgroundColor: "#f8f7f7",
                                            fontSize: "clamp(12px, 1.2vw, 14px)",
                                        }}
                                    >
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-clock me-2"></i>
                                            Start Time: {item.login_time || "N/A"}
                                        </p>
                                        <p className="card-text" style={{ marginBottom: "0.5rem" }}>
                                            <i className="bi bi-clock me-2"></i>
                                            End Time: {item.logout_time || "N/A"}
                                        </p>
                                        <p className="card-text" style={{ marginBottom: "0.5rem" }}>
                                            <i className="bi bi-stopwatch me-2"></i>
                                            Min Overtime: {item.time_bound_for_overtime || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
    