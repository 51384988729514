import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axios from "axios";
import "./details.css";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import { useNavigate } from 'react-router-dom';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DetailsofEmp() {
  const [value, setValue] = useState(0);
  const [employeeData, setEmployeeData] = useState(null);
  const { id: employeeId } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigation = () => {
    navigate(`/user/salaryslip/${employeeId}`);
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/account/users-list/${employeeId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployeeData(response.data);
        console.log("emp is", response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    if (employeeId) {
      fetchEmployeeData();
    }
  }, [employeeId]);

  // Dummy JSON data for personal details
  const personalDetails = [
    { label: "Name", value: "Raasi c" },
    { label: "Gender", value: "Male" },
    { label: "Nationality", value: "Indian" },
    { label: "Blood Group", value: "A+" },
    { label: "Marital Status", value: "Married" },
    { label: "Educational Level", value: "Plus Two" },
  ];

  // Data for job details
  const jobDetails = [
    { label: "Job Position", value: "Mern Stack Dev" },
    { label: "Job Type", value: "Full-time" },
    { label: "Department", value: "Development" },
    { label: "Branch", value: "Calicut" },
    { label: "Experience", value: "1 Year" },
    { label: "Joining Date", value: "26-02-2023" },
    { label: "End of Probation", value: "26-04-2023" },
    { label: "Effective Date", value: "25-08-2023" },
    { label: "Basic Salary", value: "5000" },
    { label: "Salary Type", value: "Monthly" },
    { label: "Currency", value: "Rupees" },
    { label: "Benefits", value: "tea" },
    { label: "Bonus", value: "300" },
    { label: "Deduction", value: "300" },
  ];

  // Data for contact details
  const contactDetails = [
    { label: "Email", value: "admin@gmail.com" },
    { label: "Phone Number", value: "1234567890" },
    { label: "Office Phone", value: "0483 6785453" },
    { label: "Address", value: "Areekode, malappuram" },
    { label: "City", value: "Areekode" },
    { label: "State", value: "Kerala" },
    { label: "Pincode", value: "673639" },
    { label: "Relative Name", value: "Abu" },
    { label: "Relation", value: "freind" },
    { label: "Rel Phone-No", value: "1234567890" },
  ];

  const showData = {
    firstHeader: "Working Days",
    firstCount: "176",
    secondHeader: "Attendance",
    secondCount: "92%",
    thirdHeader: "Leaves",
    thirdCount: "13",
  };

  return (
    <>
      <div className="DetailsContainer w-100 h-100 mx-2 mx-sm-4 pt-2 pt-sm-3 pb-3 d-flex gap-4">
        <div className="profileInfo rounded-3 p-3 position-relative">
          <div className="imgContainer d-flex flex-column justify-content-center align-items-center">
            <img
              className="mb-2 border border-1 border-white shadow-lg"
              style={{ width: "150px", height: "150px", borderRadius: "15px" }}
              src={employeeData?.profile_photo || "/assets/user.png"}
              alt="profilePic"
            />
            <h6>{employeeData?.name || "N/A"}</h6>
            <h6 style={{ fontSize: "10px" }}>
              ID - #{employeeData?.id || "PRO-0002"}
            </h6>
          </div>
          <div className="profileDatas pt-3">
            <p>Job Position</p>
            <h6 style={{ fontSize: "13px" }}>
              {employeeData?.job_position?.job_title || "N/A"}
            </h6>
            <p>Department</p>
            <h6 style={{ fontSize: "13px" }}>
              {employeeData?.department?.dep_name || "N/A"}
            </h6>
            <p>Email</p>
            <h6 style={{ fontSize: "13px" }}>{employeeData?.email || "N/A"}</h6>
            <p>Contact No</p>
            <h6 style={{ fontSize: "13px" }}>
              {employeeData?.phone_number || "N/A"}
            </h6>
            <p>Place</p>
            <h6 style={{ fontSize: "13px" }}>{employeeData?.city || "N/A"}</h6>
            <p>Job Status</p>
            <h6 style={{ fontSize: "13px" }}>
              {employeeData?.status ? "Active" : "Inactive"}
            </h6>
          </div>
        </div>
        <div className="mainDetails d-flex flex-column justify-content-start rounded-3 gap-3">
          {/* Attendance & leave section */}
          <LeaveShowGrid showData={showData} />

          <button
            className="btn btn-warning"
            style={{ width: '200px' }}
            onClick={handleNavigation}
          >
            Show Salary Slip
          </button>

          {/* Tabs for Employee Details */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="employee details tabs"
                sx={{
                  minHeight: "35px",
                  "& .MuiTab-root": {
                    minHeight: "35px",
                    height: "35px",
                    padding: "0 16px",
                    fontSize: 'clamp(12px, 1.2vw, 12px)'
                  },
                }}
              >
                <Tab label="Personal Details" {...a11yProps(0)} />
                <Tab label="Job Details" {...a11yProps(1)} />
                <Tab label="Contact Details" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {/* Personal Details */}
              {personalDetails.map((detail, index) => (
                <div
                  key={index}
                  className="personal-detail px-2 pt-1 rounded-2"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "clamp(9px, 1vw, 11px)",
                      color: "darkgray",
                      fontWeight: "500",
                    }}
                  >
                    {detail.label}
                  </p>
                  <h6
                    className="mb-2"
                    style={{ fontSize: "clamp(12px, 1.2vw, 14px)" }}
                  >
                    {detail.value}
                  </h6>
                </div>
              ))}
              {/* <div className="btn btn-success align-self-end" style={{fontSize:'clamp(12px, 1.2vw, 14px)', width:'100px'}}>edit <i class="bi bi-pencil-square ms-1"></i></div> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {/* Job Details */}
              {jobDetails.map((detail, index) => (
                <div
                  key={index}
                  className="job-detail px-2 pt-1 rounded-2"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "clamp(9px, 1vw, 11px)",
                      color: "darkgray",
                      fontWeight: "500",
                    }}
                  >
                    {detail.label}
                  </p>
                  <h6
                    className="mb-2"
                    style={{ fontSize: "clamp(12px, 1.2vw, 14px)" }}
                  >
                    {detail.value}
                  </h6>
                </div>
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {/* Contact Details */}
              {contactDetails.map((detail, index) => (
                <div
                  key={index}
                  className="contact-detail px-2 pt-1 rounded-2"
                  style={{ backgroundColor: "#eaedff" }}
                >
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "clamp(9px, 1vw, 11px)",
                      color: "darkgray",
                      fontWeight: "500",
                    }}
                  >
                    {detail.label}
                  </p>
                  <h6
                    className="mb-2"
                    style={{ fontSize: "clamp(12px, 1.2vw, 14px)" }}
                  >
                    {detail.value}
                  </h6>
                </div>
              ))}
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </>
  );
}
