import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import dayjs from 'dayjs';
import * as React from 'react';

export default function ResponsiveDatePickers({label, value, onChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'MobileDatePicker',
          'DesktopDatePicker',
          'StaticDatePicker',
        ]}
        sx={{
          width: '100%',
          paddingTop: '0'
        }}
      >
        <DemoItem label={label}>
          <DesktopDatePicker 
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                size: "small", // This makes the TextField smaller
                fullWidth: true,
              },
            }}
          />
        </DemoItem> 
      </DemoContainer>
    </LocalizationProvider>
  );
}
