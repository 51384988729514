import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import {
  FormControl,
  // FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import './createUser.css'

export default function CreateUser() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      selectedGroups: [],
      selectedPermissions: [],
      is_staff: false,
      is_superuser: false,
    },
  });
  const [groupOptions, setGroupOptions] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const { register, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const token = localStorage.getItem("accessToken");
  // Watch both checkboxes
  const isStaff = watch("is_staff");
  const isSuperuser = watch("is_superuser");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dee2e6",
      borderColor: state.isFocused
        ? "#2684FF"
        : errors[state.selectProps.name]
        ? "#ff000080"
        : "#cccccc",
      "&:hover": {
        borderColor: state.isFocused
          ? "#2684FF"
          : errors[state.selectProps.name]
          ? "#ff000080"
          : "#cccccc",
      },
      boxShadow: errors[state.selectProps.name]
        ? "0 0 0 1px #ff000080"
        : provided.boxShadow,
      
    }),
  };

  const clearForm = () => {
    reset({
      email: "",
      password: "",
      confirmPassword: "",
      selectedGroups: [],
      selectedPermissions: [],
      is_staff: false,
      is_superuser: false,
    });
  };

  useEffect(() => {
    let timer;
    if (submitSuccess || submitError) {
      timer = setTimeout(() => {
        setSubmitSuccess(false);
        setSubmitError(null);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [submitSuccess, submitError]);

  useEffect(() => {
    const fetchGroupOptions = async () => {
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/account/groups/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const formattedOptions = response.data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setGroupOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching group options:", error);
      }
    };

    const fetchPermissionOptions = async () => {
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/account/permissions/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const formattedOptions = response.data.map((permission) => ({
          value: permission.id,
          label: permission.name,
        }));
        setPermissionOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching permission options:", error);
      }
    };

    fetchGroupOptions();
    fetchPermissionOptions();
  }, [token]);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        email: data.email,
        password: data.password,
        password_confirm: data.confirmPassword,
        groups: data.selectedGroups.map((group) => group.value),
        permissions: data.selectedPermissions.map(
          (permission) => permission.value
        ),
        is_staff: data.is_staff,
        is_superuser: data.is_superuser,
      };

      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/users/",
        formattedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("User created successfully:", response.data);
      setSubmitSuccess(true);
      setSubmitError(null);
      clearForm();
    } catch (error) {
      console.error(
        "Error creating user:",
        error.response ? error.response.data : error.message
      );

      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (
          errorData.email &&
          errorData.email.includes("user data with this email already exists.")
        ) {
          setError("email", {
            type: "manual",
            message: "A user with this email already exists.",
          });
        } else {
          setSubmitError(
            Object.entries(errorData)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          );
        }
      } else {
        setSubmitError("An unexpected error occurred while creating the user.");
      }
      setSubmitSuccess(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const cardStyle = {
    width: "100%",
    maxWidth: "500px",
    height: "auto",
  };

  const cardStyleSmallDevice = {
    ...cardStyle,
    height: "100%",
  };

  const isSmallDevice = window.innerWidth <= 576;

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-start w-100 align-items-sm-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div
          className={`card shadow ${isSmallDevice ? "rounded-0" : "rounded-4"}`}
          style={isSmallDevice ? cardStyleSmallDevice : cardStyle}
        >
          <div className="card-body">
            <h3 className="text-center mb-4">New User</h3>
            {submitSuccess && (
              <div className="alert alert-success">
                User created successfully{" "}
                <i class="bi bi-check-circle-fill text-primary ms-2"></i>
              </div>
            )}
            {submitError && (
              <div className="alert alert-danger">{submitError}</div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex gap-3 mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input ${
                      errors.is_staff ? "border-danger" : ""
                    }`}
                    id="is_staff"
                    {...register("is_staff", {
                      validate: (value) =>
                        value ||
                        isSuperuser ||
                        "At least one role must be selected",
                    })}
                  />
                  <label className="form-check-label" htmlFor="is_staff">
                    Staff
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input ${
                      errors.is_superuser ? "border-danger" : ""
                    }`}
                    id="is_superuser"
                    {...register("is_superuser", {
                      validate: (value) =>
                        value ||
                        isStaff ||
                        "At least one role must be selected",
                    })}
                  />
                  <label className="form-check-label" htmlFor="is_superuser">
                    Admin
                  </label>
                </div>
              </div>
              <Controller
                name="selectedGroups"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={groupOptions}
                    className="basic-multi-select mb-3 "
                    classNamePrefix="select"
                    placeholder="Choose Groups..."
                    styles={customStyles}
                  />
                )}
              />

              <Controller
                name="selectedPermissions"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={permissionOptions}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    placeholder="Choose Permissions..."
                    styles={customStyles}
                  />
                )}
              />
              <div className="mb-3">
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.email}
                  // className="mb-3"
                  size="small"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-email"
                    style={{ fontSize: "1rem",zIndex:0 }}
                  >
                    E-mail
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="email"
                    {...register("email", { required: "E-mail is required" })}
                    label="E-mail"
                    // placeholder="E-mail"
                  />
                  {/* {errors.email && (
                    <FormHelperText>{errors.email.message}</FormHelperText>
                  )} */}
                </FormControl>
              </div>
              <div className="mb-3 position-relative">
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.password}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    style={{ fontSize: "1rem", zIndex:0}}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {/* {errors.password && (
                    <FormHelperText>{errors.password.message}</FormHelperText>
                  )} */}
                </FormControl>
              </div>
              <div className="mb-3 position-relative">
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.confirmPassword}
                  // className="w-100"
                  size="small"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-confirm-password"
                    style={{ fontSize: "1rem",zIndex:0 }}
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    type={showConfirmPassword ? "text" : "password"}
                    {...register("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (val) => {
                        if (watch("password") !== val) {
                          return "Your passwords do not match";
                        }
                      },
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  {/* {errors.confirmPassword && (
                    <FormHelperText>
                      {errors.confirmPassword.message}
                    </FormHelperText>
                  )} */}
                </FormControl>
              </div>
              <div className="d-grid gap-3 mt-5">
                <button
                  type="submit"
                  className="btn text-white"
                  style={{ backgroundColor: "#120e4d" }}
                >
                  Confirm
                </button>
                <button
                  type="reset"
                  className="btn btn-light"
                  onClick={clearForm}
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
