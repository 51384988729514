import React, { useState, useEffect } from "react";

const DurationPicker = ({ value, onChange }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    // Format the selected hours and minutes into HH:mm:00 format
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
    onChange(formattedTime); // Sending formatted string to parent component
  }, [hours, minutes, onChange]);

  const handleHoursChange = (e) => {
    const value = Math.max(0, Math.min(23, parseInt(e.target.value)));
    setHours(isNaN(value) ? 0 : value);
  };

  const handleMinutesChange = (e) => {
    const value = Math.max(0, Math.min(59, parseInt(e.target.value)));
    setMinutes(isNaN(value) ? 0 : value);
  };

  return (
    <div className="d-flex gap-2">
      <div className="d-flex flex-column">
        <label style={{ fontSize: 'clamp(8px, 1.1vw, 10px)' }} className="fw-bold">Hours:</label>
        <input
          type="number"
          value={hours}
          onChange={handleHoursChange}
          style={{ width: '50px', height: '40px' }}
          className="fs-4 fw-light ps-2 rounded-1 border border-black"
        />
      </div>

      <div className="d-flex flex-column">
        <label style={{ fontSize: 'clamp(8px, 1.1vw, 10px)' }} className="fw-bold">Minutes:</label>
        <input
          type="number"
          value={minutes}
          onChange={handleMinutesChange}
          style={{ width: '50px', height: '40px' }}
          className="fs-4 fw-light ps-2 rounded-1 border border-black"
        />
      </div>
    </div>
  );
};

export default DurationPicker;
