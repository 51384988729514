import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../ogComponent/spinner";
import { toast } from "react-toastify";

export default function LeaveDetail() {
  const { empId, id } = useParams(); // Get the leave ID from URL parameters
  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaveDetails = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/employee/${empId}/leave/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployeeData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leave details:", error);
        toast.error("Failed to fetch leave details.");
      }
    };

    fetchLeaveDetails();
  }, [empId, id]); // Removed employeeData from the dependency array

  const handleReject = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/${id}/reject_leave/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Leave request rejected successfully.");
      navigate("/user/leaverequest/");
    } catch (error) {
      console.error("Error rejecting leave:", error);
      toast.error("Failed to reject leave request.");
    }
  };

  const handleApprove = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/${id}/approve_leave/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Leave request approved successfully.");
      navigate("/user/leaverequest/");
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave request.");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!employeeData) {
    return <div>No data found</div>;
  }

  const {
    start_date,
    end_date,
    total_leave_days,
    leave_type,
    reason,
    status,
    employee,
  } = employeeData;

  return (
    <div className="leaveDetailsContainer w-100 d-flex justify-content-center align-items-start align-items-md-center">
      <div
        className="tableContainer w-100 d-flex flex-column gap-3 my-0 my-md-3 py-3 py-md-3 rounded-4 shadow bg-body-tertiary"
        style={{
          maxWidth: "800px",
          height: "min-content",
          border: "3px solid #80808042",
        }}
      >
        <div className="tableHead d-flex align-items-center w-100 justify-content-around flex-wrap row-gap-2">
          <div
            className="tableprofile w-100 d-flex justify-content-start px-4 px-md-5 gap-2 gap-md-4 align-items-center"
            style={{ minWidth: "200px" }}
          >
            <img
              className="imgDiv shadow"
              src={employee.profile_photo || "/assets/user.png"}
              alt="profilePic"
              style={{ width: "120px", height: "120px", borderRadius: "15px" }}
            />
            <div className="imagedatas h-100 d-flex flex-column ps-2 ps-md-3">
              <div
                className="columns px-2 px-lg-3 py-1"
                style={{
                  minWidth: "120px",
                  maxWidth: "100%",
                  width: "min-content",
                }}
              >
                <p
                  className="mb-0 fw-bolder text-nowrap"
                  style={{ fontSize: "clamp(14px 1.4vw, 16px)" }}
                >
                  {employee.name || "N/A"}
                </p>
              </div>
              <div
                className="columns px-2 px-lg-3 pb-sm-1"
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                  width: "min-content",
                }}
              >
                <p
                  className="mb-0 text-nowrap"
                  style={{ fontSize: "clamp(13px 1.3vw, 15px)" }}
                >
                  {employee.job_position?.job_title || "N/A"}
                </p>
              </div>
              <div
                className="columns px-2 px-lg-3 pb-sm-1"
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                  width: "min-content",
                }}
              >
                <p
                  className="mb-0  text-nowrap"
                  style={{ fontSize: "clamp(13px 1.3vw, 15px)" }}
                >
                  {`${employee.branch?.branch_name} - ${employee.branch?.location}` || "N/A"}
                </p>
              </div>
              <div
                className="columns px-2 px-lg-3 pb-sm-1"
                style={{
                  minWidth: "150px",
                  maxWidth: "100%",
                  width: "min-content",
                }}
              >
                <p
                  className="mb-0  text-nowrap"
                  style={{ fontSize: "clamp(13px 1.3vw, 15px)" }}
                >
                  {employee.email || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-1 m-md-3" />
        <div
          className="tableBody"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "16px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              Start Date
            </h6>
            <p className="mb-0 fs-6">{start_date}</p>
          </div>
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              End Date
            </h6>
            <p className="mb-0 fs-6">{end_date}</p>
          </div>
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              Days
            </h6>
            <p className="mb-0 fs-6">{total_leave_days}</p>
          </div>
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              Leave Type
            </h6>
            <p className="mb-0 fs-6">{leave_type}</p>
          </div>
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              Reason
            </h6>
            <p className="mb-0 fs-6">{reason}</p>
          </div>
          <div
            className="columns w-100 px-3 py-2 rounded-3 border-secondary"
            style={{ backgroundColor: "white" }}
          >
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: "clamp(12px, 1.1vw, 12px)" }}
            >
              Status
            </h6>
            <p
              className={`mb-0 ${status === "Pending"
                  ? "text-primary"
                  : status === "Approved"
                    ? "text-success"
                    : status === "Rejected"
                      ? "text-danger"
                      : ""
                }`}
            >
              {status}
            </p>
          </div>
        </div>
        {/* Conditionally render buttons based on the leave status */}
        <div className="tableFooter w-100 d-flex gap-3 pe-3 flex-wrap justify-content-end my-md-3 my-1 my-md-2">
          {status === "Pending" && (
            <>
              <button className="btn btn-danger" onClick={handleReject}>Reject</button>
              <button className="btn btn-success" onClick={handleApprove}>Approve</button>
            </>
          )}
          {status === "Approved" && (
            <button className="btn btn-danger" onClick={handleReject}>Reject</button>
          )}
          {status === "Rejected" && (
            <button className="btn btn-success" onClick={handleApprove}>Approve</button>
          )}
        </div>
      </div>
    </div>
  );
}
