import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function ModalTable({ selectedDate }) {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedDate) {
        setLoading(true);
        const endDate = new Date(selectedDate);
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 30);

        const formattedEndDate = endDate.toISOString().split('T')[0];
        const formattedStartDate = startDate.toISOString().split('T')[0];
        // const employeeId = localStorage.getItem('userID');
        const employeeId = 32;

        try {
          const response = await axios.get('https://hrmsbackend.prosevo.com/api/attendance/attendance/', {
            params: {
              start_date: formattedStartDate,
              end_date: formattedEndDate,
              employee_id: employeeId
            }
          });
          setAttendanceData(response.data);
        } catch (error) {
          console.error("Error fetching attendance data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedDate]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog" style={{maxWidth:'640px'}}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {selectedDate ? formatDate(selectedDate) : 'Attendance Data'}
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div style={{ maxHeight: '580px', overflowY: 'auto' }}>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table>
                    <thead className="position-sticky top-0 text-white z-2" style={{ backgroundColor: '#120e4d' }}>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Check-In</th>
                        <th>Check-Out</th>
                      </tr>
                    </thead>
                    <tbody className='overflow-scroll'>
                      {attendanceData.map((item, index) => {
                        const isSelectedDate = selectedDate && new Date(item.date).toDateString() === selectedDate.toDateString();
                        return (
                          <tr key={index} style={isSelectedDate ? { backgroundColor: '#e6f7ff' } : {}}>
                            <td>{index+1}</td>
                            <td>{formatDate(item.date)}</td>
                            <td>{item.day}</td>
                            <td>{item.clock_in}</td>
                            <td>{item.clock_out}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}