import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const BranchForm = ({ fetchBranches }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formatText = (text) =>
      text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    const formattedData = {
      ...data,
      branch_name: formatText(data.branch_name),
      branch_head: formatText(data.branch_head),
      location: formatText(data.location),
      contact_info: data.contact_info
    };

    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        "https://hrmsbackend.prosevo.com/api/employee/branches/",
        formattedData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchBranches();
      reset();
      toast.success("New Branch Added!", {
        position: "bottom-right",
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Error saving branch data:", error);
      toast.error("Failed to save branch data. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex justify-content-center align-items-start gap-4 flex-column"
    >
      <Controller
        name="branch_name"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Branch Name"
            variant="outlined"
            size="small"
            error={!!errors.branch_name}
            helperText={errors.branch_name?.message}
            sx={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="branch_head"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Branch Head"
            variant="outlined"
            size="small"
            error={!!errors.branch_head}
            helperText={errors.branch_head?.message}
            sx={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="location"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Location"
            variant="outlined"
            size="small"
            error={!!errors.location}
            helperText={errors.location?.message}
            sx={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="contact_info"
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: {
            value: /^[0-9]{10}$/,
            message: "Contact info must be a 10-digit number",
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Contact info"
            variant="outlined"
            size="small"
            error={!!errors.contact_info}
            helperText={errors.contact_info?.message}
            sx={{ width: "100%" }}
          />
        )}
      />
      <Button type="submit" variant="contained" className="align-self-end px-4">
        Save <i class="bi bi-check-circle ms-2"></i>
      </Button>
    </form>
  );
};

export default BranchForm;
