export const ComponentIds = {
    // Roles
    SystemManagement: 1,
    AttendanceManagement: 2,
    LeaveManagement: 3,
    EmployeeManagement: 4,
    RolesManagement: 5,
    Notification: 6,
    
    // Permissions
    
    // System Management
    CanAddTimeManage: 53,
    CanChangeTimeManage: 54,
    CanDeleteTimeManage: 55,
    CanViewTimeManage: 56,
    CanAddBranch: 21,
    CanChangeBranch: 22,
    CanDeleteBranch: 23,
    CanViewBranch: 24,
    CanAddDepartments: 25,
    CanChangeDepartments: 26,
    CanDeleteDepartments: 27,
    CanViewDepartments: 28,
    CanAddHr: 41,
    CanChangeHr: 42,
    CanDeleteHr: 43,
    CanViewHr: 44,
    CanAddJobTypes: 29,
    CanChangeJobTypes: 30,
    CanDeleteJobTypes: 31,
    CanViewJobTypes: 32,
    CanAddSalaryType: 33,
    CanChangeSalaryType: 34,
    CanDeleteSalaryType: 35,
    CanViewSalaryType: 36,
    CanAddHolidays: 57,
    CanChangeHolidays: 58,
    CanDeleteHolidays: 59,
    CanViewHolidays: 60,
    
    // Attendance Management
    CanAddAttendance: 45,
    CanChangeAttendance: 46,
    CanDeleteAttendance: 47,
    CanViewAttendance: 48,
    CanAddDummyAttendance: 49,
    CanChangeDummyAttendance: 50,
    CanDeleteDummyAttendance: 51,
    CanViewDummyAttendance: 52,
    
    // Leave Management
    ApproveLeaves: 85,
    RejectLeaves: 86,
    CanAddLeaveRequest: 61,
    CanChangeLeaveRequest: 62,
    CanDeleteLeaveRequest: 63,
    CanViewLeaveRequest: 64,
    
    // Employee Management
    CanAddUserData: 17,
    CanChangeUserData: 18,
    CanDeleteUserData: 19,
    CanViewUserData: 20
};
