import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./offCanvas.css";
import { ComponentIds } from "../../constant/cmpID";


// Define the OffCanvas component
export default function OffCanvas({
  mainwidth,
  setMainWidth,
  opaacity,
  setOpacity,
  canvasWidth,
  setCanvasWidth,
  hide,
  setHide,
  product,
  closeAllCollapses,
}) {

  const accesibleData = [
    {
        "id": "DashBoard",
        "header": "DashBoard",
        "icon": "lni lni-grid-alt",
        "link": "/user/home"
    },
    {
        "id": ComponentIds.SystemManagement,
        "header": "Admin",
        "icon": "lni lni-user",
        "Childs": [
            {"id": "Functionalities", "Name": "Functionalities", "link": "/user/functions"},
            {"id": "Branchs", "Name": "Branchs",  "link": "/user/branchs"},
            {"id": "Departments", "Name": "Departments", "link": "/user/deps"},
            {"id": "JobPositions", "Name": "JobPositions", "link": "/user/positions"},
            {"id": "Shifts", "Name": "Shifts", "link": "/user/shifts"},
            {"id": "allowance", "Name": "Allowance", "link": "/user/allowance"},
        ]
    },
    {
        "id": ComponentIds.EmployeeManagement,
        "header": "Employee",
        "icon": "lni lni-users",
        "Childs": [
            {"id": "TotalEmployees", "Name": "Total Employees", "link": "/user/emp"},
            {"id": "AddEmployee", "Name": "Add Employee", "link": "/user/addemp"},
            {"id": "EmployeeDetails", "Name": "Employee Details", "link": "/user/empDetails"},
            {"id": "Courses", "Name": "Courses", "link": "/user/courses"},
            {"id": "subCourses", "Name": "Subjects", "link": "/user/subcourses"}
        ]
    },
    {
        "id": ComponentIds.LeaveManagement,
        "header": "Leaves",
        "icon": "lni lni-protection",
        "Childs": [
            {"id": "TodayLeaves", "Name": "Today Leaves", "link": "/user/todayLeaves"},
            {"id": "LeaveRequests", "Name": "Leave Requests", "link": "/user/leaveRequest"},
            {"id": "LeaveDetails", "Name": "Leave Details", "link": "/user/leaveDetails"},
            {"id": "LeaveReport", "Name": "Leave Report", "link": "/user/leaveReport"},
            {"id": "MyLeaves", "Name": "My Leaves", "link": "/user/selfLeave"},
            {"id": "CompanyLeaves", "Name": "Company Leaves", "link": "/user/leaveShow"}
        ]
    },
    {
        "id": ComponentIds.RolesManagement,
        "header": "Roles",
        "icon": "lni lni-layout",
        "Childs": [
            {"id": "TotalRoles", "Name": "Total Roles", "link": "/user/userdetails"},
            {"id": "AddNewRole", "Name": "Add New Role", "link": "/user/createUser"}
        ]
    },
    {
        "id": ComponentIds.AttendanceManagement,
        "header": "Attendence",
        "icon": "lni lni-popup",
        "Childs": [
            {"id": "AllAttendence", "Name": "All Attendence", "link": "/user/attendence"},
            {"id": "EarlyLate", "Name": "Early & Late", "link": "/user/earlyLate"},
            {"id": "MyAttendence", "Name": "My Attendence", "link": "/user/myattendence"}
        ]
    },
    {
        "id": ComponentIds.Notification,
        "header": "Notification",
        "icon": "lni lni-coin",
        "Childs": [
            {"id": "Earnings", "Name": "Earnings"},
            {"id": "Salarys", "Name": "Salarys"},
            {"id": "Deduction", "Name": "Deduction"},
            {"id": "Bonus", "Name": "Bonus"}
        ]
    }
  ]
  

  // Function to toggle the sidebar width
  function settingWidth() {
    if (mainwidth === false) {
      setMainWidth(true);
      setCanvasWidth("250px");
      setTimeout(() => {
        setOpacity("100%");
        setHide("block");
      }, 300);
    } else {
      setMainWidth(false);
      setCanvasWidth("50px");
      setOpacity("0%");
      setHide("none");
      closeAllCollapses(); // Close all collapses when collapsing the off-canvas menu
    }
  }

  // Function to handle dropdown toggle
  const handleDropdown = (id) => {
    const dropdownMenu = document.getElementById(id);
    if (dropdownMenu) {
      if (dropdownMenu.classList.contains("show")) {
        dropdownMenu.classList.remove("show");
      } else {
        dropdownMenu.classList.add("show");
      }
    }
  };

  // Filter the product array based on accesibleData
  const filteredProduct = product.filter((item) =>
    accesibleData.some((access) => access.id === item.id)
  );

  // Map through filteredProduct and add accessible childs
  const finalProduct = filteredProduct.map((item, index) => {
    const accessItem = accesibleData.find((access) => access.id === item.id);
    if (accessItem && accessItem.Childs) {
      return {
        ...item,
        Childs: item.Childs.filter((child) =>
          accessItem.Childs.some((accessChild) => accessChild.id === child.id)
        ),
      };
    }
    return item;
  });

  return (
    <div>
      <div
        style={{ width: canvasWidth, left: "0" }}
        className="sideBar position-fixed"
      >
        <div
          className="accordion accordion-flush"
          style={{ width: canvasWidth }}
          id="accordionFlushExample"
        >
          {finalProduct.map((item, index) => (
            <div className="accordion-item w-100" key={index}>
              <div
                className="button_Container w-100"
                onMouseEnter={() => handleDropdown(`dropdownMenu_${item.id}`)}
                onMouseLeave={() => handleDropdown(`dropdownMenu_${item.id}`)}
              >
                {item.link ? (
                  <Link
                    to={item.link}
                    className="w-100 text-decoration-none d-flex align-items-center h-100"
                    style={{ color: "inherit" }}
                  >
                    <i
                      className={`icons ${item.icon} d-flex justify-content-center`}
                      onClick={() => settingWidth()}
                    ></i>
                    <p
                      className="icon-text mb-0 w-100 h-100 text-nowrap"
                      style={{ display: hide, opacity: opaacity }}
                    >
                      {item.header}
                    </p>
                  </Link>
                ) : (
                  <>
                    <i
                      className={`icons ${item.icon} d-flex justify-content-center`}
                      onClick={() => settingWidth()}
                    ></i>
                    <p
                      className="icon-text mb-0"
                      style={{ display: hide, opacity: opaacity }}
                    >
                      {item.header}
                    </p>
                  </>
                )}
                {item?.Childs && (
                  <button
                    style={{ display: hide }}
                    className="accordion-button collapsed d-flex justify-content-center rounded-0 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${item.id}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${item.id}`}
                  >
                    <i
                      className="lni lni-chevron-down"
                      style={{ display: hide }}
                    ></i>
                  </button>
                )}
                {item?.Childs && (
                  <div className="dropdown dropend">
                    <ul
                      className="dropdown-menu p-0"
                      id={`dropdownMenu_${item.id}`}
                    >
                      {item.Childs.map((child, index) => (
                        <li key={index} style={{ height: "35px" }}>
                          {child.link ? (
                            <Link
                              to={child.link}
                              className="dropdown-item"
                              style={{ zIndex: "10" }}
                            >
                              {child.Name}
                            </Link>
                          ) : (
                            <p className="dropdown-item" style={{ zIndex: "10" }}>
                              {child.Name}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {item?.Childs && (
                <div
                  id={`flush-collapse${item.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {item.Childs.map((child, index) => (
                      <button key={index} className="btn w-100 text-start rounded-0">
                        {child.link ? (
                          <Link className="w-100 h-100" to={child.link}>{child.Name}</Link>
                        ) : (
                          child.Name
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
