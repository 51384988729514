import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";

const DepartmentForm = ({ branchOptions, fetchDepartments }) => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const branchIds = data.branch.map(branch => branch.value);
    const formatText = (text) =>
      text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    
    const payload = {
      branches: branchIds,
      contact_info: data.contact_info,
      dep_head: formatText(data.dep_head),
      dep_name: formatText(data.dep_name),
    };
  
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        "https://hrmsbackend.prosevo.com/api/employee/departments/",
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchDepartments();
      reset({
        dep_name: "",
        dep_head: "",
        branches: [],
        contact_info: "",
      });
      toast.success("New Department Added!", {
        position: "bottom-right",
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Error saving department data:", error);
      toast.error("Failed to save department data. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex justify-content-center align-items-start gap-4 flex-column"
    >
      <Controller
        name="dep_name"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Department Name"
            variant="outlined"
            size="small"
            error={!!errors.dep_name}
            helperText={errors.dep_name?.message}
            className="w-100"
          />
        )}
      />
      <Controller
        name="branch"
        control={control}
        defaultValue={[]}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            options={branchOptions.map((branch) => ({
              value: branch.id,
              label: `${branch.name} - ${branch.location}`,
            }))}
            className={`bg-white z-index-10 w-100 ${error ? "error-border" : ""}`}
            isMulti
            placeholder="Select Branches"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: error ? 'tomato' : provided.borderColor,
              }),
            }}
          />
        )}
      />
      <Controller
        name="dep_head"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Department Head"
            variant="outlined"
            size="small"
            error={!!errors.dep_head}
            helperText={errors.dep_head?.message}
            className="w-100"
          />
        )}
      />
      <Controller
        name="contact_info"
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: {
            value: /^[0-9]{10}$/,
            message: "Contact info must be a 10-digit number",
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Contact info"
            variant="outlined"
            size="small"
            error={!!errors.contact_info}
            helperText={errors.contact_info?.message}
            className="w-100"
          />
        )}
      />
      <Button type="submit" variant="contained" className="align-self-end px-4">
        Save <i className="bi bi-check-circle ms-2"></i>
      </Button>
    </form>
  );
};

export default DepartmentForm;
