import React from "react";
// import Notes from "./notes";
import Tasks from "./tasks";
// import MainCard from "./mainCard";
import EmployeeList from "./mainCard";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
// import Dashboard from "./dashboard";
// import { Link } from "react-router-dom";

export default function LeftContainer() {
  const showData ={
    "firstHeader":"Total Employees",
    "firstCount":"786",
    "secondHeader":"Attendence",
    "secondCount":"97%",
    "thirdHeader":"Leaves",
    "thirdCount":"15"
  }
  return (
    <div
      class="leftcontainer d-flex gap-5 ps-3 ps-md-4 pt-3 pt-md-4 pb-2 pb-md-3"
      style={{ paddingTop: "20px" }}
    >
      <div className="fullContainer d-flex flex-column w-100 row-gap-3 row-gap-xxl-4">
        <LeaveShowGrid showData={showData}/>
        <Tasks />
        <EmployeeList />
      </div>
    </div>
  );
}
