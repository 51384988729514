import { IconPlus } from '@tabler/icons-react';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../ogComponent/modal';
import { useNavigate } from 'react-router-dom';

export default function Subjects() {
    const toast = useRef(null);
    const [courseCount, setCourseCount] = useState(3);
    const [showModal, setShowModal] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const navigate = useNavigate();

    const Courses = [
        {
            id: 1,
            course_name: "Mern Stack",
            label: 'Mern Stack',
            course_code: "MERN01",
            category: "Day Batch (Normal)",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 180,
            start_date: "2024-11-01",
            end_date: "2024-11-30",
            course_fee: 39990,
            course_instructor: { name: "Abbobacker", id: 1 },
            description: "Mern full stack dev",
            student_count: 15,
        },
        {
            id: 2,
            course_name: "Python D-jango",
            label: 'Python D-jango',
            course_code: "PY01",
            category: "Weekend Batch",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 90,
            start_date: "2024-12-01",
            end_date: "2024-12-30",
            course_fee: 19990,
            course_instructor: { name: 'Anagha P.A', id: 2 },
            description: "Python django backend.",
            student_count: 7,
        },
        {
            id: 3,
            course_name: "Odoo Erp Dev",
            label: 'Odoo Erp Dev',
            course_code: "ODOO01",
            category: "Night Batch",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 60,
            start_date: "2025-01-01",
            end_date: "2025-02-15",
            course_fee: 19990,
            course_instructor: { name: 'Sreya', id: 3 },
            description: "Odoo Erp Development",
            student_count: 5,
        }
    ];

    const subCourses = [
        { course_name: "Html", label: 'html', id: '1', course_instructor: { name: 'Aboobacker', id: 1 }, student_count: 15, course_duration: '20 Days' },
        { course_name: "Css", label: 'css', id: '2', course_instructor: { name: 'Lukman', id: 2 }, student_count: 15, course_duration: '25 Days' },
        { course_name: "Javascript", label: 'javascript', id: '3', course_instructor: { name: 'Adeen', id: 3 }, student_count: 10, course_duration: '40 Days' },
    ];

    useEffect(() => {
        // Fetch instructor options on component mount
        const fetchInstructors = async () => {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch("https://hrmsbackend.prosevo.com/api/account/users-list/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const instructorOptions = data.map(instructor => ({
                        value: instructor.id,
                        label: instructor.name,
                    }));
                    setInstructorOptions(instructorOptions);
                } else {
                    console.error("Failed to fetch instructors:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching instructors:", error);
            }
        };

        fetchInstructors();

        if (showModal) {
            const modalElement = document.getElementById('staticBackdrop');
            const modalInstance = new window.bootstrap.Modal(modalElement);
            modalInstance.show();

            modalElement.addEventListener('hidden.bs.modal', () => setShowModal(false));
        }
    }, [showModal]);

    const courseFields = [
        { name: 'subject_name', label: 'Subject Name', type: 'text', required: true },
        { name: 'subject_code', label: 'Subject Code', type: 'number', required: true },
        { name: 'courses', label: 'Courses', type: 'multiSelect', options: Courses || [], required: false },
        { name: 'subject_duration', label: 'Subject Duration (days)', type: 'number', required: true },
        { name: 'start_date', label: 'Start Date', type: 'date', required: true },
        { name: 'end_date', label: 'End Date', type: 'date', required: true },
        { name: 'subject_instructor', label: 'Subject Instructor', type: 'multiSelect', options: instructorOptions, required: true },
        { name: 'description', label: 'Description', type: 'textarea', required: true },
    ];

    const handleSub_CourseSubmit = async (formData) => {
        // Transform data to the required format
        const transformedData = {
            ...formData,
            subject_instructor: formData.subject_instructor?.map(instructor => instructor.value) || [],
            courses: formData.courses?.map(course => course.id) || [],
        };

        const token = localStorage.getItem('accessToken'); // Get token from localStorage

        try {
            const response = await fetch('https://hrmsbackend.prosevo.com/api/employee/sub_courses/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(transformedData),
            });

            if (response.ok) {
                console.log("Course added successfully");
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: "New Course Created!",
                    life: 3000,
                });
                setShowModal(false); // Close the modal only if submission is successful
            } else {
                console.error("Failed to add course:", response.statusText);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Failed to Create Course",
                    life: 3000,
                });
            }
        } catch (error) {
            console.error("Error submitting course data:", error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: "Failed to Create Course",
                life: 3000,
            });
        }
    };

    const handleCourseClick = (id) => {
        navigate(`/user/subcourses/${id}`);
    };


    const handleAddCourse = () => {
        setShowModal(true);
    };

    return (
        <div className="container-fluid px-3 px-md-4 d-flex flex-column gap-3">
            <Toast ref={toast} position="bottom-right" />
            <div className="d-flex flex-column justify-content-start mt-3 mb-1">
                <h5 className="fw-light mb-0">Subjects</h5>
                <p
                    className="align-self-start mb-0"
                    style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight: '600' }}
                >
                    Total {courseCount} Subject{courseCount !== 1 ? "s" : ""}
                </p>
            </div>
            <button className="btn btn-success" style={{ width: '150px' }} onClick={handleAddCourse}>
                Add New <IconPlus stroke={2} />
            </button>
            {courseCount === 0 ? (
                <div
                    className="d-flex justify-content-center align-items-end position-relative"
                    style={{ height: "50vh" }}
                >
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{
                            backgroundImage: 'url(/assets/file.png)',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            opacity: 0.3,
                        }}
                    ></div>
                    <p className="m-0 fw-light fs-5 position-relative">No Courses!</p>
                </div>
            ) : (
                <div className="row">
                    {subCourses.map((course) => (
                        <div
                            key={course.id}
                            className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCourseClick(course.id)}
                        >
                            <div className="card h-100 px-3 pb-2">
                                <div className="card-body d-flex flex-column py-1 px-0">
                                    <div className="p-2">
                                        <h6
                                            className="card-title mb-0"
                                            style={{
                                                fontSize: 'clamp(12px, 1.2vw, 14px)',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                width: '100%'
                                            }}
                                            title={course?.course_name}
                                        >
                                            {course?.course_name}
                                        </h6>
                                    </div>
                                    <div
                                        className="rounded-3 px-3 py-2"
                                        style={{ backgroundColor: "#f8f7f7", fontSize: 'clamp(12px, 1.2vw, 14px)' }}
                                    >
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-person me-2"></i>
                                            {course?.course_instructor?.name}
                                        </p>
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-box-seam me-2"></i>
                                            {course?.course_duration}
                                        </p>
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-people me-2"></i>
                                            Students : {course.student_count}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Modal
                heading="Add Sub Course"
                fields={courseFields}
                onSubmit={handleSub_CourseSubmit}
                type={"course"}
            />
        </div>
    )
}
