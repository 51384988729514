import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BranchForm from "./BranchForm";
import DepartmentForm from "./DepartmentForm";
import JobPositionForm from "./JobPositionForm";
import CompanyLeaveForm from "./companyleaveForm";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShiftForm from "./shiftForm";
import OthersForm from "./OthersForm";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import { Margin } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Functionalities() {
  const [value, setValue] = useState(0);
  const [branchOptions, setBranchOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchBranches = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/employee/branches/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const branches = response.data.map((branch) => ({
        name: branch.branch_name,
        id: branch.id,
        location: branch.location
      }));
      setBranchOptions(branches);
      console.log("Branch data fetched successfully");
    } catch (error) {
      console.error("Error fetching branch data:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/employee/departments/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const departments = response.data.map((dep) => ({
        id: dep.id,
        name: dep.dep_name,
      }));
      setDepartmentOptions(departments);
      console.log("Department data fetched successfully");
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
    fetchDepartments();
  }, []);

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', maxWidth:"900px", paddingX:'5%', paddingTop:'3%' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '100%' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="functionalities tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          // sx={{paddingBottom:'1.8rem'}}
        >
          <Tab label="Branch" {...a11yProps(0)} />
          <Tab label="Department" {...a11yProps(1)} />
          <Tab label="Job Position" {...a11yProps(2)} />
          <Tab label="Shift" {...a11yProps(3)} />
          <Tab label="Company Leave" {...a11yProps(4)} />
          <Tab label="Others" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <Box sx={{ maxWidth: '100%', overflow: 'hidden', paddingTop:'1.8rem'}}>
        <CustomTabPanel value={value} index={0}>
          <BranchForm fetchBranches={fetchBranches} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DepartmentForm
            branchOptions={branchOptions}
            fetchDepartments={fetchDepartments}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <JobPositionForm departmentOptions={departmentOptions} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ShiftForm />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <CompanyLeaveForm />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <OthersForm/>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}