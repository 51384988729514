import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

const OthersForm = ({ departmentOptions }) => {
    const [hrPositionOptions, setHrPositionOptions] = useState([]);

    const {
        control: jobTypeControl,
        handleSubmit: handleJobTypeSubmit,
        reset: resetJobType,
        formState: { errors: jobTypeErrors },
    } = useForm();
    const {
        control: salaryTypeControl,
        handleSubmit: handleSalaryTypeSubmit,
        reset: resetSalaryType,
        formState: { errors: salaryTypeErrors },
    } = useForm();
    const {
        control: HRpositionControl,
        handleSubmit: handleHRpositionSubmit,
        reset: resetHRposition,
        formState: { errors: hrpositionErrors },
    } = useForm();
    const {
        control: allowanceControl,
        handleSubmit: handleAllowanceSubmit,
        reset: resetAllowance,
        formState: { errors: allowanceErrors },
    } = useForm();

    const formatText = (text) =>
        text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    // Fetch HR positions
    useEffect(() => {
        const fetchHrPositions = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                const response = await axios.get(
                    "https://hrmsbackend.prosevo.com/api/employee/jobpositions/",
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setHrPositionOptions(
                    response.data.map((hrPosition) => ({
                        value: hrPosition.id,
                        label: `${hrPosition.job_title} - ${hrPosition.department_details.name}`,
                    }))
                );
            } catch (error) {
                console.error("Error fetching HR positions:", error);
                toast.error("Failed to fetch HR positions. Please try again later.");
            }
        };

        fetchHrPositions();
    }, []);

    // Job Type Submit
    const onSubmitJobType = async (data) => {
        try {
            const token = localStorage.getItem("accessToken");
            await axios.post(
                "https://hrmsbackend.prosevo.com/api/employee/jobtypes/",
                {
                    job_type: formatText(data.job_type),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            resetJobType();
            toast.success("New Job Type Added!", {
                position: "bottom-right",
                autoClose: 5000,
            });
        } catch (error) {
            console.error("Error saving job type data:", error);
            toast.error("Failed to save Job Type data. Please try again later.", {
                position: "bottom-right",
                autoClose: 5000,
            });
        }
    };

    // Salary Type Submit
    const onSubmitSalaryType = async (data) => {
        try {
            const token = localStorage.getItem("accessToken");
            await axios.post(
                "https://hrmsbackend.prosevo.com/api/employee/salarytypes/",
                {
                    type_of_salary: formatText(data.salary_title),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            resetSalaryType();
            toast.success("New Salary Type Added!", {
                position: "bottom-right",
                autoClose: 5000,
            });
        } catch (error) {
            console.error("Error saving salary type data:", error);
            toast.error("Failed to save Salary Type data. Please try again later.", {
                position: "bottom-right",
                autoClose: 5000,
            });
        }
    };

    // HR Position Submit
    const onSubmitHRposition = async (data) => {
        try {
            const token = localStorage.getItem("accessToken");
            await axios.post(
                "https://hrmsbackend.prosevo.com/api/common/hrroles/",
                {
                    hr_role_ids: data.hrPositions.map((pos) => pos.value),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            resetHRposition();
            toast.success("HR Position assigned successfully!", {
                position: "bottom-right",
                autoClose: 5000,
            });
        } catch (error) {
            console.error("Error assigning HR position(s):", error);
            toast.error("Failed to assign HR position(s). Please try again later.", {
                position: "bottom-right",
                autoClose: 5000,
            });
        }
    };

    // Allowance Submit
    const onSubmitAllowance = async (data) => {
        if (data.percentage < 1 || data.percentage > 50) {
            toast.error("Allowance must be between 1% and 50%",{
                position:"bottom-right",
                autoClose: 5000,
            });
            return;
        }

        try {
            const token = localStorage.getItem("accessToken");
            await axios.post(
                "https://hrmsbackend.prosevo.com/api/common/allowance/",
                {
                    allowance_name: data.allowance_name,
                    percentage: parseFloat(data.percentage),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            resetAllowance();
            toast.success("New Allowance Added!",{
                position:"bottom-right",
                autoClose: 5000,
            });
        } catch (error) {
            toast.error("Failed to add Allowance.",{
                position:"bottom-right",
                autoClose: 5000,
            });
        }
    };

    return (
        <div className="d-flex flex-column gap-4">
            {/* Job Type Form */}
            <form
                onSubmit={handleJobTypeSubmit(onSubmitJobType)}
                className="d-flex justify-content-center align-items-start gap-4 flex-column"
            >
                <div className="d-flex align-items-center w-100">
                    <Controller
                        name="job_type"
                        control={jobTypeControl}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Job Type"
                                variant="outlined"
                                size="small"
                                error={!!jobTypeErrors.job_type}
                                helperText={jobTypeErrors.job_type?.message}
                                className="w-100"
                            />
                        )}
                    />
                    <Button type="submit" variant="contained" className="ms-2 px-3">
                        Save <i className="bi bi-check-circle ms-2"></i>
                    </Button>
                </div>
            </form>

            {/* Salary Type Form */}
            <form
                onSubmit={handleSalaryTypeSubmit(onSubmitSalaryType)}
                className="d-flex justify-content-center align-items-start gap-4 flex-column"
            >
                <div className="d-flex align-items-center w-100">
                    <Controller
                        name="salary_title"
                        control={salaryTypeControl}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Salary Title"
                                variant="outlined"
                                size="small"
                                error={!!salaryTypeErrors.salary_title}
                                helperText={salaryTypeErrors.salary_title?.message}
                                className="w-100"
                            />
                        )}
                    />
                    <Button type="submit" variant="contained" className="ms-2 px-3">
                        Save <i className="bi bi-check-circle ms-2"></i>
                    </Button>
                </div>
            </form>

            {/* HR Position Form */}
            <form
                onSubmit={handleHRpositionSubmit(onSubmitHRposition)}
                className="d-flex justify-content-center align-items-start gap-4 flex-column"
            >
                <div className="d-flex align-items-center w-100">
                    <Controller
                        name="hrPositions"
                        control={HRpositionControl}
                        defaultValue={[]}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                            <Select
                                {...field}
                                options={hrPositionOptions}
                                className={`bg-white z-index-10 w-100 ${error ? "error-border" : ""}`}
                                isMulti
                                placeholder="Select HR Positions"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={(selectedPositions) => field.onChange(selectedPositions)}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: error ? 'tomato' : provided.borderColor,
                                    }),
                                }}
                            />
                        )}
                    />
                    <Button type="submit" variant="contained" className="ms-2 px-3">
                        Save <i className="bi bi-check-circle ms-2"></i>
                    </Button>
                </div>
            </form>

            {/* Allowance Form */}
            <form
                onSubmit={handleAllowanceSubmit(onSubmitAllowance)}
                className="d-flex justify-content-center align-items-start gap-4 flex-column"
            >
                <Controller
                    name="allowance_name"
                    control={allowanceControl}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Allowance Name"
                            variant="outlined"
                            size="small"
                            error={!!allowanceErrors.allowance_name}
                            helperText={allowanceErrors.allowance_name?.message}
                            className="w-100"
                        />
                    )}
                />

                <Controller
                    name="percentage"
                    control={allowanceControl}
                    defaultValue=""
                    rules={{
                        required: "Allowance percentage is required",
                        min: { value: 1, message: "Minimum 1%" },
                        max: { value: 50, message: "Maximum 50%" },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Percentage"
                            variant="outlined"
                            size="small"
                            type="number"
                            error={!!allowanceErrors.percentage}
                            helperText={allowanceErrors.percentage?.message}
                            className="w-100"
                        />
                    )}
                />

                <Button
                    type="submit"
                    variant="contained"
                    className="align-self-end px-4"
                >
                    Save <i className="bi bi-check-circle ms-2"></i>
                </Button>
            </form>
        </div>
    );
};

export default OthersForm;
