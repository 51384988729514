import { Navigate, Route, Routes } from "react-router-dom";
// import About from "./components/aboutPage/about";
// import Registration from "./components/RegPage/registration";
// import Login from "./components/loginPage/login";
// import Signup from "./components/signupPage/signUp";
import "./App.css";
// import Home from "./components/homePage/home";
// import Container from "./components/landingPage/fixedComponents";
import FixedNavAndCanvas from "./components/landingPage/fixedComponents";
// import Home from "./components/LoginPage/home";
import Protected from "./protected";
import Login from "./components/LoginPage/Login";
// import Employee from "./components/aboutPage/employee";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#f8fafb"}}>
      <Routes>
        <Route index element={<Navigate replace to={'/user/home'} />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/login" element={<Container />} /> */}
        {/* <Route path="/signup" element={<Container />} /> */}
        {/* <Route path="/registration" element={<Registration />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        <Route
          path="/user/*"
          element={
            <Protected>
              <FixedNavAndCanvas />
            </Protected>
          }
        />
        <Route path="*" element={<div>Page not found</div>} />
        {/* <Route path="/Emp" element={<Employee />} /> */}
      </Routes>
    </div>
  );
}

export default App;
