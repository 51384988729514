import React from 'react';
import { Link } from 'react-router-dom';
import './offCanvas.css';

export default function BackdropOffcanvas({ product }) {
  const accesibleData = [
    {
      Id: "DashBoard",
    },
    {
      Id: "AdminPanel",
      Childs: [{ Id: "Functionalities" }, { Id: "Branchs" }, { Id: "Departments" }, { Id: "JobPositions" }],
    },
    {
      Id: "Employee",
      Childs: [{ Id: "TotalEmployees" }, { Id: "AddEmployee" }, { Id: "EmployeeDetails" }],
    },
    {
      Id: "Leaves",
      Childs: [
        { Id: "TodayLeaves" }, 
        { Id: "LeaveRequests" }, 
        { Id: "LeaveDetails" }, 
        { Id: "LeaveReport" }, 
        { Id: "MyLeaves" }, 
        { Id: "CompanyLeaves" }
      ],
    },
    {
      Id: "Attendence",
      Childs: [
        { Id: "AllAttendence" }, 
        { Id: "EarlyLate" }, 
        { Id: "MyAttendence" }
      ],
    },
    {
      Id: "Roles",
      Childs: [{ Id: "TotalRoles" }, { Id: "AddNewRole" }],
    },
    {
      Id: "Notification",
      Childs: [
        { Id: "Earnings" }, 
        { Id: "Salarys" }, 
        { Id: "Deduction" }, 
        { Id: "Bonus" }
      ],
    },
  ];


  // Filter the product array based on accesibleData
  const filteredProduct = product.filter((item) =>
    accesibleData.some((access) => access.Id === item.id)
  );

  // Map through filteredProduct and add accessible childs
  const finalProduct = filteredProduct.map((item) => {
    const accessItem = accesibleData.find((access) => access.Id === item.id);
    if (accessItem && accessItem.Childs) {
      return {
        ...item,
        Childs: item.Childs.filter((child) =>
          accessItem.Childs.some((accessChild) => accessChild.Id === child.id)
        ),
      };
    }
    return item;
  });

  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ width: '250px' }}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">Circle Soft</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="accordion accordion-flush p-0" id="accordionFlushExample">
          {finalProduct.map((item, index) => (
            <div className="accordion-item w-100" key={index}>
              <div className="button_Container w-100">
                {item.link ? (
                  <Link
                    to={item.link}
                    className="w-100 text-decoration-none d-flex align-items-center h-100"
                    style={{ color: 'inherit' }}
                  >
                    <i className={`icons ${item.icon} d-flex justify-content-center`}></i>
                    <p className="icon-text mb-0" style={{ display: 'block', opacity: '100%' }}>
                      {item.header}
                    </p>
                  </Link>
                ) : (
                  <>
                    <i className={`icons ${item.icon} d-flex justify-content-center`}></i>
                    <p className="icon-text mb-0" style={{ display: 'block', opacity: '100%' }}>
                      {item.header}
                    </p>
                  </>
                )}
                {item.Childs && (
                  <button
                    className="accordion-button collapsed d-flex justify-content-center text-black"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${item.id}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${item.id}`}
                  >
                    <i className="lni lni-chevron-down" style={{ display: 'block' }}></i>
                  </button>
                )}
              </div>
              {item.Childs && (
                <div
                  id={`flush-collapse${item.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {item.Childs.map((child, childIndex) => (
                      child.link ? (
                        <Link
                          key={childIndex}
                          to={child.link}
                          className="btn w-100 text-start text-decoration-none p-0"
                          style={{ zIndex: '10', color: 'inherit' }}
                        >
                          <button className='w-100 ps-5'>{child.Name}</button> 
                        </Link>
                      ) : (
                        <button key={childIndex} className="btn w-100 text-start p-0" style={{ zIndex: '10' }}>
                          {child.Name}
                        </button>
                      )
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
