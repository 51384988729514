import React from 'react'

export default function Spinner2() {
  return (
    <>
     <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className='spinner2'></div>
    </div>
    </>
  )
}