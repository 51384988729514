import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
// import { setUserData } from './redux/emp'; 
import Spinner from './components/ogComponent/spinner';

export default function Protected({ children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://hrmsbackend.prosevo.com/api/account/users-profile/', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        console.log("response",response.data);
        // dispatch(setUserData(response.data));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          // Token is invalid or expired
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
      }
    };

    fetchUserData();
  }, [navigate, dispatch]);

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      {children}
    </>
  );
}
