import { FormControl, MenuItem, Modal, Select } from '@mui/material';
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import Table from '../ogComponent/table';
import { useNavigate } from 'react-router-dom';
import CustomModal from "../ogComponent/modal";
import { IconEdit, IconTrash } from '@tabler/icons-react';

export default function CourseDetails() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [showEditModal, setShowEditModal] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    const header = [
        { name: "Si No", label: "#", active: true },
        { name: "name", label: "Name", active: true },
        { name: "job_position", label: "Job Position", active: true },
        { name: "contact_info", label: "Contact Info", active: true },
        { name: "Status", label: "Active", active: true },
    ];

    const course = {
        id: 1,
        course_name: "Mern Stack",
        course_code: "MERN01",
        category: "Day Batch (Normal)",
        sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }],
        course_duration: 180,
        start_date: "2024-11-01",
        end_date: "2024-11-30",
        course_fee: 39990,
        course_instructor: { name: "Abbobacker", id: 1 },
        description: "Mern full stack dev",
        student_count: 15,
    }

    const Courses = [
        {
            id: 1,
            course_name: "Mern Stack",
            course_code: "MERN01",
            category: "Day Batch (Normal)",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 180,
            start_date: "2024-11-01",
            end_date: "2024-11-30",
            course_fee: 39990,
            course_instructor: { name: "Abbobacker", id: 1 },
            description: "Mern full stack dev",
            student_count: 15,
        },
        {
            id: 2,
            course_name: "Python D-jango",
            course_code: "PY01",
            category: "Weekend Batch",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 90,
            start_date: "2024-12-01",
            end_date: "2024-12-30",
            course_fee: 19990,
            course_instructor: { name: 'Anagha P.A', id: 2 },
            description: "Python django backend.",
            student_count: 7,
        },
        {
            id: 3,
            course_name: "Odoo Erp Dev",
            course_code: "ODOO01",
            category: "Night Batch",
            sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
            course_duration: 60,
            start_date: "2025-01-01",
            end_date: "2025-02-15",
            course_fee: 19990,
            course_instructor: { name: 'Sreya', id: 3 },
            description: "Odoo Erp Development",
            student_count: 5,
        }
    ];

    const subCourses = [
        { name: "Html", label: 'html', id: '1' },
        { name: "Css", label: 'css', id: '2' },
        { name: "Javascript", label: 'javascript', id: '3' },
    ];

    const categoryoptions = [
        { value: 'Day Batch (Normal)', label: 'Day Batch (Normal)' },
        { value: 'Weekend Batch', label: 'Weekend Batch' },
        { value: 'Night Batch', label: 'Night Batch' }
    ]

    useEffect(() => {
        // Fetch instructor options on component mount
        const fetchInstructors = async () => {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch("https://hrmsbackend.prosevo.com/api/account/users-list/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const instructorOptions = data.map(instructor => ({
                        value: instructor.id,
                        label: instructor.name,
                    }));
                    setInstructorOptions(instructorOptions);
                } else {
                    console.error("Failed to fetch instructors:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching instructors:", error);
            }
        };

        fetchInstructors();

        if (showModal) {
            const modalElement = document.getElementById('staticBackdrop');
            const modalInstance = new window.bootstrap.Modal(modalElement);
            modalInstance.show();

            modalElement.addEventListener('hidden.bs.modal', () => setShowModal(false));
        }
    }, [showModal]);


    const formatText = (text) => {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
    };

    const editModalFields = [
        {
            name: "course_name",
            label: "Course Name",
            type: "text",
            value: formatText(course?.course_name),
            required: true,
        },
        {
            name: "course_code",
            label: "Course Code",
            type: "text",
            value: course?.course_code,
            required: true,
        },
        {
            name: "category",
            label: "Category",
            type: "select",
            options: categoryoptions,
            value: categoryoptions?.map(item => ({
                value: item.value,
                label: `${item.name}`,
            })) || [],
            required: true,
        },
        {
            name: "sub_courses",
            label: "Sub Courses",
            type: "multiSelect",
            options: subCourses || [],
            value: subCourses?.map(course => ({
                value: course?.id,
                label: `${course?.course_name}`,
            })) || [],
            required: true,
        },
        {
            name: 'course_duration', label: 'Course Duration (days)', type: 'number', value: course.course_duration, required: true
        },
        {
            name: 'start_date', label: 'Start Date', type: 'date', value: course.start_date, required: true
        },
        {
            name: 'end_date', label: 'End Date', type: 'date', value: course.end_date, required: true
        },
        {
            name: 'course_fee', label: 'Course Fee', type: 'number', value: course.course_fee, required: true
        },
        {
            name: 'course_instructor', label: 'Course Instructor', type: 'select', options: instructorOptions,
            value: instructorOptions.map(instructor => ({
                value: instructor.id,
                label: `${instructor.name}`,
            })) || [],
            required: true
        },
        {
            name: 'description', label: 'Description', value: course.description, type: 'textarea', required: true
        },
    ];


    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleDeleteClick = () => {
        if (course.student_count > 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'Cannot Delete Branch',
                detail: "This branch contains employees. Please remove employees before deleting.",
                life: 3000,
            });
        } else {
            setOpenDeleteModal(true);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleRowClick = (item) => {
        navigate(`/user/empdetails/${item.id}`);
    };


    const handleEditSubmit = (data) => {
        console.log("data submited succesfully", data)
    }



    return (
        <>
            <div className="singleCourseContainer px-md-5 px-3 pt-4 w-100">
                <Toast ref={toast} position="bottom-right" />
                {course ? (
                    <>
                        <h4>{course.course_name}</h4>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Course Head:{" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {course.course_instructor.name}
                            </span>
                        </p>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Category:{" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {course.category}
                            </span>
                        </p>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Total students:{" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {course.student_count}
                            </span>
                        </p>

                        <div className="employeeTableContainer w-100">
                            <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={handleEditClick}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                >
                                   <IconEdit stroke={2} />
                                </button>
                                <button className="btn btn-outline-danger"
                                    onClick={handleDeleteClick}
                                >
                                   <IconTrash stroke={2} />
                                </button>
                                {/* <FormControl variant="outlined" style={{minWidth: 200}}>
                  <Select
                    value={selectedPosition}
                    onChange={handlePositionchChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="All Positions">All Positions</MenuItem>
                    {jobPosition.map((position) => (
                      <MenuItem key={position.id} value={position.id}>
                        {position.job_title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                            </div>
                            {/* {filteredEmployees.length > 0 ? (
                <Table
                  header={header}
                  product={transformEmployeeData(filteredEmployees)}
                  onRowClick={handleRowClick}
                />
              ) : (
                <p>No employees found in this department.</p>
              )} */}
                        </div>
                    </>
                ) : (
                    <p>Department not found.</p>
                )}
                <CustomModal
                    heading="Edit Course Data"
                    fields={editModalFields}
                    onSubmit={handleEditSubmit}
                    type={"course"}
                />
                <Modal
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ outline: "none" }}
                >
                    <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', maxWidth: '400px', margin: 'auto' }}>
                        <h2 id="modal-modal-title">Confirm Delete</h2>
                        <p id="modal-modal-description">Are you sure you want to delete this course?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <button onClick={handleCloseDeleteModal} className="btn btn-secondary">Cancel</button>
                            <button onClick={() => {
                                // Add your delete logic here
                                handleCloseDeleteModal();
                            }} className="btn btn-danger">Confirm</button>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    )
}
