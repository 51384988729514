import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../ogComponent/spinner";

export default function Branchs() {
  const [branches, setBranches] = useState([]);
  const [branchCount, setBranchCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBranches = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/employee/branches/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBranches(response.data);
        setBranchCount(response.data.length);
      } catch (error) {
        console.error("Error fetching branches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container-fluid px-3 px-md-4 d-flex flex-column gap-3">
      <div className="d-flex flex-column justify-content-start mt-3 mb-1">
        <h5 className="fw-light mb-0">Branches</h5>
        <p
          className="align-self-start mb-0"
          style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight:'600' }}
        >
          Total {branchCount} Branch{branchCount !== 1 ? "es" : ""}
        </p>
      </div>
      {branchCount === 0 ? (
        <div
          className="d-flex justify-content-center align-items-end position-relative"
          style={{ height: "50vh" }}
        >
          <div
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{
              backgroundImage: 'url(/assets/file.png)',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              opacity: 0.3,
            }}
          ></div>
          <p className="m-0 fw-light fs-5 position-relative">No Branches !</p>
        </div>
      ) : (
        <div className="row">
          {branches.map((branch) => (
            <div
              key={branch.id}
              className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/user/branchs/${branch.id}/`)}
            >
              <div className="card h-100 px-3 pb-2">
                <div className="card-body d-flex flex-column py-1 px-0">
                  <div className="p-2">
                    <h6 
                      className="card-title mb-0" 
                      style={{
                        fontSize: 'clamp(12px, 1.2vw, 14px)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}
                      title={`${branch.branch_name} - ${branch.location}`}
                    >
                      {branch.branch_name} - {branch.location}
                    </h6>
                  </div>
                  <div
                    className="rounded-3 px-3 py-2"
                    style={{ backgroundColor: "#f8f7f7", fontSize:'clamp(12px, 1.2vw, 14px)' }}
                  >
                    <p className="card-text" style={{marginBottom:'0.5rem'}}>
                      <i className="bi bi-person me-2"></i>
                      {branch.branch_head}
                    </p>
                    <p className="card-text" style={{marginBottom:'0.5rem'}}>
                      <i className="bi bi-telephone me-2"></i>
                      +91 {branch.contact_info}
                    </p>
                    <p className="card-text" style={{marginBottom:'0.5rem'}}>
                      <i className="bi bi-people me-2"></i>
                      Employees : {branch.employee_count}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
