import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../ogComponent/spinner";
import Table from "../ogComponent/table";
import { Select, MenuItem, FormControl, Modal, Box } from "@mui/material";
import CustomModal from "../ogComponent/modal";
import { Toast } from 'primereact/toast';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconEdit, IconTrash } from "@tabler/icons-react";

export default function BranchDetails() {
  const toast = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [branch, setBranch] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px',
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "name", label: "Name", active: true },
    { name: "job_position", label: "Job Position", active: true },
    { name: "contact_info", label: "Contact Info", active: true },
    { name: "Status", label: "Active", active: true },
  ];


  useEffect(() => {
    const fetchDepartments = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`https://hrmsbackend.prosevo.com/api/employee/departments/?branches=${id}`, {
          headers: {
            Authorization: `Bearer ${token}` // Bearer token is added to the request header
          }
        });
        setDepartments(response.data); // Set the fetched data to departments
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    if (id) {
      fetchDepartments(); // Call the function to fetch data when component mounts
    }
  }, [id]);

  const handleDeleteClick = () => {
    if (branch.employee_count > 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Cannot Delete Branch',
        detail: "This branch contains employees. Please remove employees before deleting.",
        life: 3000,
      });
    } else {
      setOpenDeleteModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/employee/branches/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Handle successful deletion
      setOpenDeleteModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: "Branch Deleted Successfully",
        life: 3000,
      });

      // Redirect after 3 seconds
      setTimeout(() => {
        navigate('/user/branchs/');
      }, 3000);
    } catch (error) {
      console.error("Error deleting branch:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleEditClick = () => {
    console.log("current branch is,", branch);
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const formatText = (text) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  const handleEditSubmit = async (data) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.put(
        `https://hrmsbackend.prosevo.com/api/employee/branches/${id}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response is ,", response);
      setBranch(response.data);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating branch details:", error);
    }
  };
  console.log("branch update,", branch);

  const editModalFields = [
    {
      name: "branch_name",
      label: "Branch Name",
      type: "text",
      value: formatText(branch?.branch_name),
      required: true,
    },
    {
      name: "branch_head",
      label: "Branch Head",
      type: "text",
      value: formatText(branch?.branch_head),
      required: true,
    },
    {
      name: "location",
      label: "Location",
      type: "text",
      value: formatText(branch?.location),
      required: true,
    },
    {
      name: "contact_info",
      label: "Contact Info",
      type: "text",
      value: branch?.contact_info,
      required: true,
    },
  ];

  const handleRowClick = (item) => {
    navigate(`/user/empdetails/${item.id}`);
  };

  console.log("modal feilds", editModalFields)

  useEffect(() => {
    const fetchBranchDetails = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/employee/branches/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBranch(response.data);
        // setDepartments(response.data.departments || []);
      } catch (error) {
        console.error("Error fetching branch details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBranchDetails();
  }, [id]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("accessToken");
      setLoading(true);
      try {
        const departmentParam =
          selectedDepartment !== "All Departments"
            ? `&department=${selectedDepartment}`
            : "";
        console.log("dep id", departmentParam);
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/account/users-list/?branch=${id}${departmentParam}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFilteredEmployees(response.data || []);
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [id, selectedDepartment]);

  const transformEmployeeData = (employees) => {
    return employees.map((employee, index) => ({
      id: employee.id,
      "Si No": index + 1,
      name: (
        <td
          className="d-flex gap-3 align-items-center px-0"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img
            style={{
              width: "25px",
              height: "25px",
              flexShrink: 0,
              borderRadius: "50%",
            }}
            src={
              employee.profile_photo
                ? employee.profile_photo
                : "/assets/user.png"
            }
            alt="user img"
          />
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {employee.name || "N/A"}
          </div>
        </td>
      ),
      job_position: employee.job_position?.job_title || "N/A",
      contact_info: employee.phone_number || "N/A",
      Status: (
        <div style={{ color: employee.status ? "green" : "tomato" }}>
          <span
            style={{
              display: "inline-block",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: employee.status ? "green" : "tomato",
              marginRight: "5px",
            }}
          ></span>
          {employee.status ? "Active" : "Inactive"}
        </div>
      ),
    }));
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <style>
        {`
      @media (max-width: 500px) {
        .modalmainbox {
          min-width: 75% !important;
        }
      }
    `}
      </style>
      <div className="singleBranchContainer px-md-5 px-3 pt-4 w-100">
        <Toast ref={toast} position="bottom-right" />
        {branch ? (
          <>
            <h4>
              {branch.branch_name} - {branch.location}
            </h4>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Branch Head:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {branch.branch_head}
              </span>
            </p>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Contact Info:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {branch.contact_info}
              </span>
            </p>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Total Employees:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {branch.employee_count}
              </span>
            </p>

            <div className="employeeTableContainer w-100">
              <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  <Select
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="All Departments">All Departments</MenuItem>
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.id}>
                        {dept.dep_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <button
                  className="btn btn-outline-primary"
                  onClick={handleEditClick}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <IconEdit stroke={2} />
                </button>
                <button className="btn btn-outline-danger" onClick={handleDeleteClick}>
                  <IconTrash stroke={2} />
                </button>

              </div>
              {filteredEmployees.length > 0 ? (
                <Table
                  header={header}
                  product={transformEmployeeData(filteredEmployees)}
                  onRowClick={handleRowClick}
                />
              ) : (
                <p>No employees found in this department.</p>
              )}
            </div>
          </>
        ) : (
          <p>Branch not found.</p>
        )}
        <CustomModal
          heading="Edit Branch Data"
          fields={editModalFields}
          onSubmit={handleEditSubmit}
          type={""}
        />
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ outline: "none" }}
        >
          <Box
            sx={{ ...modalStyle, outline: "none", padding: "25px" }}
            className="modalmainbox"
          >
            <div className="w-100 d-flex align-items-center flex-column">
              <WarningAmberIcon
                sx={{ color: "#FFA000", fontSize: 60, width: "100%" }}
              />
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "clamp(13px, 1.4vw, 17px)",
                  mb: 2,
                }}
              >
                Confirm Delete
              </Box>
              <Box
                sx={{
                  color: "#555",
                  mb: 2,
                  fontSize: "clamp(12px, 1.2vw, 14px)",
                  textWrap: "wrap",
                  textAlign: "center",
                  paddingX: "10px",
                }}
              >
                Are you sure want to delete this branch?
              </Box>
            </div>
            <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
              <button
                className="btn btn-danger"
                onClick={handleConfirmDelete}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleCloseDeleteModal}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
