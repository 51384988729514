import { Box, TextField, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import Select from "react-select"; // Import react-select
import MuiSelect from "./muiSelect";
import axios from "axios"; // Import axios
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const CustomCheckbox = ({ checked, indeterminate }) => (
  <div
    className={`custom-checkbox ${checked ? "checked" : ""} ${
      indeterminate ? "half-checked" : ""
    }`}
  />
);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

function createNestedArray(flatArray) {
  const nodeMap = {};
  const rootNodes = [];

  // First pass: create all nodes
  flatArray.forEach((item) => {
    nodeMap[item.id] = {
      value: item.id,
      label: item.menu_display_name,
      children: [],
    };
  });

  // Second pass: establish parent-child relationships
  flatArray.forEach((item) => {
    if (item.parent_id === null) {
      rootNodes.push(nodeMap[item.id]);
    } else {
      const parentNode = nodeMap[item.parent_id];
      if (parentNode) {
        parentNode.children.push(nodeMap[item.id]);
      }
    }
  });

  return rootNodes;
}

const findAllParentIDs = (data, id) => {
  const result = [];
  let currentID = id;

  const findParent = (itemID) => {
    // Find the item with the given ID
    const item = data.find((d) => d.id === itemID);
    if (item && item.parent_id !== null) {
      // If the item has a parent, add the parent ID to the result array
      result.push(item.parent_id);
      // Recursively find the parent of the parent
      findParent(item.parent_id);
    }
  };

  findParent(currentID);
  return result;
};

export default function RoleModal({setOpenModal}) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [branches, setBranches] = useState([]); // State for departments
  const [nodes, setNodes] = useState([]); // State for checkbox tree nodes
  const [backendNodes, setBackendNodes] = useState([]); // State for checkbox tree nodes

  const [roleName, setRoleName] = useState(""); // State for role name
  const [roleError, setRoleError] = useState(false); // State for role name error
  const [roleHelperText, setRoleHelperText] = useState(""); // State for role name helper text
  const [employees, setEmployees] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [permissionError, setPermissionError] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  // Fetch departments from API
  useEffect(() => {
    const fetchBranches = async () => {
      const token = localStorage.getItem("accessToken"); // Get token from local storage
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/employee/branches/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const formattedBranches = response.data.map((branch) => ({
          name: branch.branch_name, // Adjust based on the API response structure
          id: branch.id,
          head: branch.branch_head,
          location: branch.location,
          contact: branch.contact_info,
        }));
        setBranches(formattedBranches); // Assuming you have a setBranches state setter
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, []);

  // Fetch roles and permissions from API
  useEffect(() => {
    const fetchRoles = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/common/menus/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBackendNodes(response.data);
        const nestedNodes = createNestedArray(response.data);
        setNodes(nestedNodes);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        let url = "https://hrmsbackend.prosevo.com/api/account/users-list/";
        if (selectedBranch && selectedBranch !== "all") {
          url += `?branch=${selectedBranch}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const formattedEmployees = response.data.map((employee) => ({
          value: employee.id,
          label: employee.name || employee.email,
        }));
        setEmployees(formattedEmployees);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedBranch]);

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption?.target?.value?.id);
    // console.log("selected option is", selectedOption);
  };

  const handleRoleChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions); // Update selected roles
  };

  const handleConfirmClick = async () => {
    let hasError = false;

    if (!roleName) {
      setRoleError(true);
      setRoleHelperText("Role name is required.");
      hasError = true;
    } else {
      setRoleError(false);
      setRoleHelperText("");
    }

    if (checked.length === 0) {
      setPermissionError(true);
      hasError = true;
    } else {
      setPermissionError(false);
    }

    if (hasError) {
      return;
    }

    const selectedPermissionIds = checked.map((permissionId) =>
      parseInt(permissionId)
    );

    let completeParents = [];
    selectedPermissionIds.forEach((roles) => {
      const parents = findAllParentIDs(backendNodes, roles);
      if (parents.length > 0) {
        completeParents = [...new Set([...completeParents, ...parents])];
      }
    });

    const completeRoles = [
      ...new Set([...completeParents, ...selectedPermissionIds]),
    ];
    const userIds = selectedRoles.map((role) => role.value);
    
    const formatText = (text) =>
      text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    const data = {
      role_name: formatText(roleName),
      menus: completeRoles,
      user: userIds,
    };

    console.log(data);

    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/common/roles/",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
      setOpenSuccessModal(true); // Set openSuccessModal to true
      setTimeout(() => {
        setOpenSuccessModal(false); // Hide success modal after 3 seconds
      }, 3000);
      resetForm()
      setOpenModal(false)
    } catch (error) {
      console.error("Error Saving role:", error);
    }
  };

  const resetForm = () => {
    setChecked([]);
    setExpanded([]);
    setSelectedRoles([]);
    setRoleName("");
    setRoleError(false);
    setRoleHelperText("");
    setSelectedBranch("all");
    setPermissionError(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Create New Role
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetForm}
              ></button>
            </div>
            <div className="modal-body d-flex flex-column">
              <TextField
                label="Role Name"
                variant="outlined"
                size="small"
                placeholder="Employee Role"
                error={roleError} // Set this based on your validation logic
                helperText={roleHelperText} // Set this based on your validation logic
                value={roleName} // Bind roleName state
                onChange={(e) => {
                  setRoleName(e.target.value);
                  setRoleError(false);
                  setRoleHelperText("");
                }}
                InputProps={{ sx: { height: "40px" } }}
              />
              <div className="checkbox-tree-container">
                <CheckboxTree
                  nodes={nodes}
                  checked={checked}
                  expanded={expanded}
                  onCheck={setChecked}
                  onExpand={setExpanded}
                  icons={{
                    check: <CustomCheckbox checked />,
                    uncheck: <CustomCheckbox />,
                    halfCheck: <CustomCheckbox indeterminate />,
                    expandClose: <FaChevronRight className="expand-icon" />,
                    expandOpen: <FaChevronDown className="expand-icon" />,
                    parentClose: null,
                    parentOpen: null,
                    leaf: null,
                  }}
                />
              </div>
              <MuiSelect
                label="All"
                options={branches}
                className={`bg-white z-index-10 mt-3`}
                onChange={handleBranchChange}
                value={
                  branches.find((branch) => branch.value === selectedBranch) ||
                  branches[0]
                }
              />
              <Select
                isMulti
                options={employees}
                className="basic-multi-select mt-3"
                classNamePrefix="select"
                onChange={handleRoleChange}
                placeholder="Select Users"
                value={selectedRoles}
              />
            </div>
            <div className="modal-footer" style={{ borderTop: "none" }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={resetForm} // Reset form fields on reset
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConfirmClick}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            ...modalStyle, 
            outline: "none" ,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
            bgcolor: "background.paper",
          }}
        >
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "green" }} />
          <h2 id="success-modal-title">Success!</h2>
          <p id="success-modal-description">
            Role has been saved successfully.
          </p>
        </Box>
      </Modal>
      <style jsx>{`
        .checkbox-tree-container {
          margin-top: 10px;
        }
        .react-checkbox-tree label:hover {
          background: none;
        }
        .custom-checkbox {
          width: 16px;
          height: 16px;
          border: 1px solid #9c9c9c;
          display: inline-block;
          position: relative;
          background-color: white;
        }
        .custom-checkbox.checked {
          background-color: #f44336;
          border-color: #f44336;
        }
        .custom-checkbox.checked::after {
          content: "✓";
          position: absolute;
          top: -3px;
          left: 2px;
          color: white;
          font-size: 14px;
        }
        .custom-checkbox.half-checked {
          background-color: white;
          border-color: #9c9c9c;
        }
        .custom-checkbox.half-checked::after {
          content: "✓";
          position: absolute;
          top: -3px;
          left: 2px;
          color: #8b4513; /* Brown color */
          font-size: 14px;
        }
        .expand-icon {
          color: #9c9c9c;
          font-size: 12px;
        }
        :global(.react-checkbox-tree) {
          font-size: 14px;
          color: #333;
        }
        :global(.react-checkbox-tree .rct-node-icon) {
          display: none;
        }
        :global(.react-checkbox-tree .rct-text) {
          margin-left: 5px;
        }
        :global(
            .react-checkbox-tree .rct-node-expanded > .rct-text > .rct-title
          ) {
          font-weight: bold;
        }
      `}</style>
    </>
  );
}
