import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ResponsiveDatePickers from "../ogComponent/datePicker";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import axios from "axios";

const CompanyLeaveForm = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors: leaveErrors },
  } = useForm({
    defaultValues: {
      leave_name: "",
      start_date: dayjs(),
      end_date: dayjs(),
      days: "1",
      image: null,
    },
  });

  const [fileInputValue, setFileInputValue] = useState(null);

  const startDate = watch("start_date");
  const endDate = watch("end_date");

  useEffect(() => {
    if (startDate && endDate) {
      const start = dayjs(startDate).startOf("day");
      const end = dayjs(endDate).startOf("day");
      const diffInDays = end.diff(start, "day") + 1;
      setValue("days", diffInDays.toString());
    }
  }, [startDate, endDate, setValue]);

  const onSubmitLeave = async (data) => {
    const start = dayjs(data.start_date).startOf("day");
    const end = dayjs(data.end_date).startOf("day");
    const today = dayjs().startOf("day");
    const diffInDays = end.diff(start, "day") + 1;

    if (diffInDays <= 0 || start.isBefore(today) || end.isBefore(today)) {
      toast.error("Please choose a valid date", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");

      const formData = new FormData();
      formData.append("title", `${data.leave_name}`);
      formData.append("start_date", data.start_date.format("YYYY-MM-DD"));
      formData.append("end_date", data.end_date.format("YYYY-MM-DD"));
      formData.append("days", diffInDays.toString());

      if (data.image && data.image.length > 0) {
        formData.append("image", data.image[0]);
      }

      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/leavemanagement/company-holiday/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Leave data saved successfully", response.data);
      reset(); // Reset the form to its default values
      setFileInputValue(null); // Clear the file input value
      toast.success("New leave Created!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("There was an error saving the leave data!", error);
      toast.error("Failed to save leave. Please try again later.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <form
      className="d-flex justify-content-center align-items-start gap-4 flex-column"
      onSubmit={handleSubmit(onSubmitLeave)}
    >
      <Controller
        name="leave_name"
        control={control}
        rules={{ required: "Leave name is required" }}
        render={({ field }) => (
          <TextField
            {...field}
            id="leave-name"
            label="Leave Name"
            variant="outlined"
            size="small"
            error={!!leaveErrors.leave_name}
            helperText={leaveErrors.leave_name?.message}
            className="w-100"
          />
        )}
      />
      <Controller
        name="image"
        control={control}
        render={({ field: { onChange, ref, ...rest } }) => (
          <TextField
            id="image"
            type="file"
            onChange={(e) => {
              onChange(e.target.files);
              setFileInputValue(e.target.value);
            }}
            ref={ref}
            size="small"
            className="w-100"
            value={fileInputValue || ""}
          />
        )}
      />
      <Controller
        name="start_date"
        control={control}
        rules={{ required: "Start Date is required" }}
        render={({ field }) => (
          <ResponsiveDatePickers
            label="Start Date"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        name="end_date"
        control={control}
        rules={{ required: "End Date is required" }}
        render={({ field }) => (
          <ResponsiveDatePickers
            label="End Date"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        name="days"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="days"
            label="Duration (days)"
            variant="outlined"
            size="small"
            disabled
            error={!!leaveErrors.duration}
            helperText={leaveErrors.duration?.message}
            className="w-100"
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        className="align-self-end px-4"
      >
        Save <i className="bi bi-check-circle ms-2"></i>
      </Button>
    </form>
  );
};

export default CompanyLeaveForm;
