import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./tasks.css";

export default function Tasks() {
  const [punchDetails, setPunchDetails] = useState([]);
  const [loading, setLoading] = useState(true); // For skeleton loading
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch employee_id and accessToken from local storage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");

    // Get today's date in YYYY-MM-DD format
    const todayDate = new Date().toISOString().split("T")[0];
    console.log("date",todayDate)

    // Fetch attendance data
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/attendance/single-attendance/`,
          {
            params: { punch_date: todayDate, employee__id: userId },
            headers: {
              Authorization: `Bearer ${token}`, // Use the token in headers
            },
          }
        );

        const attendanceData = response.data[0];
        if (attendanceData && attendanceData.punch_details) {
          // Set only the first 3 punch details
          setPunchDetails(attendanceData.punch_details.slice(0, 3));
        } else {
          setPunchDetails([]); // No data case
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setPunchDetails([]); // In case of error, consider no data
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    fetchData();
  }, []);

  // Handle "View All" button click to navigate to attendance page
  const handleViewAll = () => {
    navigate("/user/myattendence/");
  };

  return (
    <div className="taskConatiner d-flex gap-3">
      {/* MainTasks Table */}
      <div
        className="MainTasks rounded-3 overflow-hidden p-2"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="Taskbody">
          <table className="w-100">
            <thead>
              <tr
                className="text-white"
                style={{ backgroundColor: "#cf8f17", height: "35px" }}
              >
                <th>Time</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {/* Show skeleton while loading */}
              {loading ? (
                <>
                  <tr style={{height:'35px', marginTop:'5px'}}>
                    <td className="skeleton-loader"></td>
                    <td className="skeleton-loader"></td>
                  </tr>
                  <tr style={{height:'35px', marginTop:'5px'}}>
                    <td className="skeleton-loader"></td>
                    <td className="skeleton-loader"></td>
                  </tr>
                  <tr style={{height:'35px', marginTop:'5px'}}>
                    <td className="skeleton-loader"></td>
                    <td className="skeleton-loader"></td>
                  </tr>
                </>
              ) : punchDetails.length > 0 ? (
                punchDetails.map((punch, index) => (
                  <tr key={index}>
                    <td>{punch.punch_time}</td>
                    <td>
                      {punch.punch_direction === "clock-in" ? "Check-in" : "Check-out"}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                 <tr style={{height: 33}}>
                  <td colSpan="2"></td>
                </tr>
                <tr>
                  <td colSpan="2" className="text-center">
                    No punch details today
                  </td>
                </tr>
                <tr style={{height: 33}}>
                  <td colSpan="2"></td>
                </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div
          className="taskFooter d-flex justify-content-end ps-3 pt-2 pe-2"
          style={{
            fontWeight: "500",
            fontSize: "clamp(13px, 1.3vw, 15px)",
            cursor: "pointer",
          }}
          onClick={handleViewAll}
        >
          View All
        </div>
      </div>

      {/* notification side */}
      <div
        className="MainGraphs rounded-3 overflow-hidden"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="overviewBody w-100">
          <div className="overviewHeader d-flex w-100 justify-content-between">
            <h6>Notifications</h6>
            <p>View all</p>
          </div>
          <div className="notification-item d-flex align-items-center py-1 border-bottom">
            <img
              src="https://via.placeholder.com/40"
              alt="User Img"
              className="rounded-circle me-3"
            />
            <div className="details flex-grow-1">
              <p className="m-0" style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}>
                You have Received a <strong>Leave</strong> request
              </p>
              <div className="d-flex" style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}>
                <div className="time me-2">16m ago</div>
                <div className="category">• General</div>
              </div>
            </div>
          </div>
          <div className="notification-item d-flex align-items-center py-1">
            <img
              src="https://via.placeholder.com/40"
              alt="Userimg"
              className="rounded-circle me-3"
            />
            <div className="details flex-grow-1">
              <p className="m-0" style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}>
                <strong>Shushmitha</strong> added a New Employee
              </p>
              <div className="d-flex" style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}>
                <div className="time me-2">2h ago</div>
                <div className="category">• General</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
