import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Table from "../ogComponent/table";
import _ from "lodash";
import Spinner2 from "../ogComponent/Spinner2";
import { Box, Modal } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconTrash, IconPencilMinus } from '@tabler/icons-react';


export default function Employee() {
  const navigate = useNavigate();

  const [product, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("All Types");
  const [searchQuery, setSearchQuery] = useState("");
  const [departments, setDepartments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [empToDelete, setEmpToDelete] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState({
    name: "All Employees",
    id: "",
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px',
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "name", label: "Name", active: true },
    { name: "job_position", label: "Job Title", active: true },
    { name: "contact_info", label: "Contact Info", active: true },
    { name: "Status", label: "Active", active: true },
    { name: "delete", label: "", active: true },
    { name: 'edit', label: '', active: true }
  ];

  const handleOpenModal = (item) => {
    setEmpToDelete(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmpToDelete(null);
  };

  const fetchDepartments = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/employee/departments/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchData = async (query = "", departmentId = "", status = "") => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `https://hrmsbackend.prosevo.com/api/account/users-list/?name=${query}&department=${departmentId}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const transformedData = response.data.map((item, index) => ({
        id: item.id,
        "Si No": index + 1,
        delete: (
          <IconTrash stroke={2} onClick={(e) => {
            e.stopPropagation()
            handleOpenModal(item)
          }} />
        ),
        edit: (<IconPencilMinus stroke={2} onClick={(e) => {
          e.stopPropagation();
          navigate(`/user/editEmp/${item.id}`);
        }} />),
        name: (
          <td
            className="d-flex gap-3 align-items-center px-0"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <img
              style={{
                width: "25px",
                height: "25px",
                flexShrink: 0,
                borderRadius: "50%",
              }}
              src={item.profile_photo || "/assets/user.png"}
              alt="user img"
            />
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {item.name || "N/A"}
            </div>
          </td>
        ),
        job_position: item.job_position?.job_title || "N/A",
        contact_info: item.phone_number || "N/A",
        Status: (
          <div style={{ color: item.status ? "green" : "tomato" }}>
            <span
              style={{
                display: "inline-block",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                backgroundColor: item.status ? "green" : "tomato",
                marginRight: "5px",
              }}
            ></span>
            {item.status ? "Active" : "Inactive"}
          </div>
        ),
      }));

      setData(transformedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDepartments();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/account/users/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchData();
      fetchDepartments()
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  };

  const handleRowClick = (item) => {
    navigate(`/user/empdetails/${item.id}`);
  };

  const handleSearch = useCallback(
    _.debounce((query) => {
      setLoading(true);
      fetchData(
        query,
        selectedEmployee.id,
        selectedType === "Active"
          ? "true"
          : selectedType === "Non-Active"
            ? "false"
            : ""
      );
    }, 1000),
    [selectedEmployee, selectedType]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const handleSelectEmployee = (employee, departmentId) => {
    setSelectedEmployee({ name: employee, id: departmentId });
    setLoading(true);
    fetchData(
      searchQuery,
      departmentId,
      selectedType === "Active"
        ? "true"
        : selectedType === "Non-Active"
          ? "false"
          : ""
    );
  };

  const handleSelectType = (type) => {
    setSelectedType(type);
    setLoading(true);
    fetchData(
      searchQuery,
      selectedEmployee.id,
      type === "Active" ? "true" : type === "Non-Active" ? "false" : ""
    );
  };

  const TableSkeletonLoading = () => (
    <div className="table-responsive">
      <div className="skeleton-table" style={{ paddingTop: "500px" }}>
        <Spinner2 />
      </div>
    </div>
  );

  const NoEmployeesMessage = () => (
    <div
      className="noTableData d-flex justify-content-center align-items-end position-relative"
      style={{ height: "50vh" }}
    >
      <div
        className=" position-absolute top-0 start-0 w-100 h-100"
        style={{
          backgroundImage: "url(/assets/file.png)",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
        }}
      ></div>
      <p className="m-0 fw-light fs-5 position-relative">No Employees !</p>
    </div>
  );

  return (
    <>
      <style>
        {`
      @media (max-width: 500px) {
        .modalmainbox {
          min-width: 75% !important;
        }
      }
    `}
      </style>
      <div className="EmployeeContainer w-100 h-100 px-2 px-lg-4 pt-3">
        <div className="emp_Header my-2 mx-2 mx-lg-0">
          <h4>EMPLOYEES</h4>
        </div>

        <Link
          to="/user/addemp"
          className="text-decoration-none text-black mx-2 mx-lg-0"
        >
          <button
            className="btn bg-warning mb-2 mb-md-3"
            style={{ fontSize: "clamp(12px, 1.2vw, 13px)", height: "33px" }}
          >
            <i className="lni lni-plus me-1"></i> Add Employee
          </button>
        </Link>

        <div
          className="tableMainContainer w-100 d-flex flex-column justify-content-start align-items-center rounded-3 px-3 px-md-4 py-3"
          style={{
            backgroundColor: "#5853b70d",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 10px 29px 5px",
          }}
        >
          <div className="filters w-100 d-flex justify-content-between pb-3 gap-2">
            <div className="LeftDropdown d-flex gap-1 gap-lg-3">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle text-white border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "clamp(12px, 1.2vw, 14px)",
                    backgroundColor: "#120e4d",
                  }}
                >
                  {selectedEmployee.name} <span></span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <p
                      className="dropdown-item"
                      onClick={() => handleSelectEmployee("All Employees", "")}
                    >
                      All Employees
                    </p>
                  </li>
                  {departments.map((department) => (
                    <li key={department.id}>
                      <p
                        className="dropdown-item"
                        onClick={() =>
                          handleSelectEmployee(department.dep_name, department.id)
                        }
                      >
                        {department.dep_name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="dropdown">
                <button
                  className="btn dropdown-toggle text-white border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "clamp(12px, 1.2vw, 14px)",
                    backgroundColor: "#120e4d",
                  }}
                >
                  {selectedType}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <p
                      className="dropdown-item"
                      onClick={() => handleSelectType("All Types")}
                    >
                      All Types
                    </p>
                  </li>
                  <li>
                    <p
                      className="dropdown-item"
                      onClick={() => handleSelectType("Active")}
                    >
                      Active
                    </p>
                  </li>
                  <li>
                    <p
                      className="dropdown-item"
                      onClick={() => handleSelectType("Non-Active")}
                    >
                      Non-Active
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Tablesearch d-flex align-items-end position-relative align-items-md-center">
              <input
                className="form-control border-0"
                type="search"
                placeholder="Search..."
                aria-label="Search"
                style={{ height: "33px" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span style={{ position: "absolute", right: "15px" }}>
                <i className="bi bi-search"></i>
              </span>
            </div>
          </div>

          {loading ? (
            <TableSkeletonLoading />
          ) : product.length > 0 ? (
            <Table
              header={header}
              product={product}
              onRowClick={handleRowClick}
            />
          ) : (
            <NoEmployeesMessage />
          )}
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ outline: "none" }} // Remove focus outline from Modal
        >
          <Box sx={{ ...modalStyle, outline: "none", padding: '25px' }} className="modalmainbox">
            <div className="w-100 d-flex align-items-center flex-column">
              <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 60, width: '100%' }} />
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "clamp(13px, 1.4vw, 17px)",
                  mb: 2,
                }}
              >
                Confirm Delete
              </Box>
              <Box
                sx={{
                  color: "#555",
                  mb: 2,
                  fontSize: "clamp(12px, 1.2vw, 14px)",
                  textWrap: "wrap",
                  textAlign: 'center',
                  paddingX: '10px'
                }}
              >
                Are you sure want to delete this employee ?
              </Box>
            </div>
            <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(empToDelete?.id)}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleCloseModal}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
