import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IconTrash, IconEdit } from '@tabler/icons-react'; // Importing Tabler icons
import Spinner from '../ogComponent/spinner'; // Assuming Spinner is your loading component
import Modal from '@mui/material/Modal'; // Assuming you're using MUI for modals
import Box from '@mui/material/Box';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // For warning icon
import TextField from '@mui/material/TextField'; // MUI text field
// import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form'; // For form control
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Allowance() {
    const [allowances, setAllowances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedAllowance, setSelectedAllowance] = useState(null);
    const token = localStorage.getItem("accessToken");

    const { control: allowanceControl, handleSubmit: handleAllowanceedit, formState: { errors: allowanceErrors }, setValue } = useForm();


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        maxWidth: '500px', // Set a max-width for responsiveness
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2, // Adds rounded corners
        outline: 'none'
    };


    useEffect(() => {
        const fetchAllowances = async () => { // Get token from localStorage
            if (!token) {
                setError('No token found');
                return;
            }

            try {
                const response = await axios.get("https://hrmsbackend.prosevo.com/api/common/allowance/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Setting the allowances data received from the API
                setAllowances(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching allowances data:", err);
                setError('Error fetching allowances data');
                setLoading(false);
            }
        };

        fetchAllowances();
    }, []);

    const handleDeleteClick = (allowance) => {
        setSelectedAllowance(allowance);
        setOpenDeleteModal(true);
    };

    const handleEditClick = (allowance) => {
        setSelectedAllowance(allowance);
        setValue('allowance_name', allowance.allowance_name);
        setValue('percentage', allowance.percentage);
        setOpenEditModal(true);
    };

    const handleCloseModal = () => {
        setOpenDeleteModal(false);
        setOpenEditModal(false);
        setSelectedAllowance(null);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://hrmsbackend.prosevo.com/api/common/allowance/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAllowances(allowances.filter(item => item.id !== id)); // Remove the deleted item from state
            setOpenDeleteModal(false); // Close modal
    
            // Show success toast
            toast.success("Allowance deleted!",{
                position:"bottom-right",
                autoClose: 5000,
            });
        } catch (err) {
            console.error("Error deleting allowance:", err);
            toast.error("Allowance deleting Failed!",{
                position:"bottom-right",
                autoClose: 5000,
            });
        }
    };
    
    const onEditAllowance = async (data) => {
        try {
            const response = await axios.put(`https://hrmsbackend.prosevo.com/api/common/allowance/${selectedAllowance.id}/`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Update the allowances list with the edited data
            setAllowances(allowances.map(item => item.id === response.data.id ? response.data : item));
            setOpenEditModal(false); // Close modal
    
            // Show success toast
            toast.success("Allowance Updated!",{
                position:"bottom-right",
                autoClose: 5000,
            });
        } catch (err) {
            console.error("Error editing allowance:", err);
            toast.error("Allowance editing failed!",{
                position:"bottom-right",
                autoClose: 5000,
            });
        }
    };
    

    return (
        <div className='container-fluid px-3 px-md-4 d-flex flex-column gap-3'>
            <div className="d-flex flex-column justify-content-start mt-3 mb-1">
                <h3 className="fw-light mb-0">Allowances</h3>
                <p
                    className="align-self-start mb-0"
                    style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight: '600' }}
                >
                    Total {allowances.length} Allowance{allowances.length !== 1 ? "s" : ""}
                </p>
            </div>

            {loading ? (
                <Spinner />
            ) : error ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                >
                    <p className="text-danger">Error loading allowances data</p>
                </div>
            ) : allowances.length === 0 ? (
                <div
                    className="d-flex justify-content-center align-items-end position-relative"
                    style={{ height: "50vh" }}
                >
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{
                            backgroundImage: "url(/assets/file.png)",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            opacity: 0.3,
                        }}
                    ></div>
                    <p className="m-0 fw-light fs-5 position-relative">No Allowances!</p>
                </div>
            ) : (
                <div className="row">
                    {allowances.map((item) => (
                        <div
                            key={item.id}
                            className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            style={{ cursor: "pointer", minWidth: "270px" }}
                        >
                            <div className="card h-100 px-3">
                                <div className="card-body d-flex flex-column py-2 px-0">
                                    <div className="p-2">
                                        <h6
                                            className="card-title"
                                            style={{
                                                fontSize: "clamp(12px, 1.2vw, 14px)",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "100%",
                                            }}
                                        >
                                            {item.allowance_name}
                                        </h6>
                                    </div>
                                    <div
                                        className="rounded-3 px-3 py-2 mb-2 d-flex justify-content-between"
                                        style={{
                                            backgroundColor: "#f8f7f7",
                                            fontSize: "clamp(12px, 1.2vw, 14px)",
                                        }}
                                    >
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            Percentage: {item.percentage ? `${parseFloat(item.percentage).toFixed(2)}%` : "N/A"}
                                        </p>
                                        <div className="d-flex gap-3">
                                            <IconEdit stroke={2} onClick={() => handleEditClick(item)} />
                                            <IconTrash stroke={2} onClick={() => handleDeleteClick(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Delete Confirmation Modal */}
            <Modal
                open={openDeleteModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ outline: "none" }}
            >
                <Box sx={{ ...modalStyle, padding: '25px' }} className="modalmainbox">
                    <div className="w-100 d-flex align-items-center flex-column">
                        <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 60, width: '100%' }} />
                        <Box sx={{ fontWeight: "bold", fontSize: "clamp(13px, 1.4vw, 17px)", mb: 2 }}>
                            Confirm Delete
                        </Box>
                        <Box sx={{ color: "#555", mb: 2, fontSize: "clamp(12px, 1.2vw, 14px)", textAlign: 'center', paddingX: '10px' }}>
                            Are you sure want to delete this allowance?
                        </Box>
                    </div>
                    <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
                        <button className="btn btn-danger" onClick={() => handleDelete(selectedAllowance?.id)} style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}>
                            Confirm
                        </button>
                        <button className="btn btn-secondary" onClick={handleCloseModal} style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}>
                            Cancel
                        </button>
                    </div>
                </Box>
            </Modal>

            {/* Edit Allowance Modal */}
            <Modal
                open={openEditModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ outline: "none" }}
            >
                <Box sx={{ ...modalStyle, outline: "none", padding: '25px' }} className="modalmainbox">
                    <h6 className='mb-4'>Edit Allowance</h6>
                    <form className="d-flex flex-column gap-3" onSubmit={handleAllowanceedit(onEditAllowance)}>
                        <Controller
                            name="allowance_name"
                            control={allowanceControl}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} label="Allowance Name" variant="outlined" fullWidth error={!!allowanceErrors.allowance_name} />
                            )}
                        />
                        <Controller
                            name="percentage"
                            control={allowanceControl}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} label="Percentage" variant="outlined" type="number" fullWidth error={!!allowanceErrors.percentage} />
                            )}
                        />
                        <div className="d-flex justify-content-between">
                            <button className='btn btn-danger' onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className='btn btn-primary' type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
            <ToastContainer />
        </div>
    );
}
