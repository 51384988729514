import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../ogComponent/spinner";

export default function JobPosition() {
  const [jobPositions, setJobPositions] = useState([]);
  const [jobPositionCount, setJobPositionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobPositions = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          "https://hrmsbackend.prosevo.com/api/employee/jobpositions/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setJobPositions(response.data);
        setJobPositionCount(response.data.length);
      } catch (error) {
        console.error("Error fetching job positions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobPositions();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/user/positions/${id}`);
  };

  // const renderDepartments = (department) => {
  //   if (Array.isArray(department)) {
  //     return department.map(dept => dept.dep_name).join(", ");
  //   } else if (typeof department === 'object' && department !== null) {
  //     return department.dep_name || 'N/A';
  //   } else {
  //     return department || 'N/A';
  //   }
  // };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container-fluid px-3 px-md-4 d-flex flex-column gap-3">
        <div className="d-flex flex-column justify-content-start mt-3 mb-1">
          <h5 className="fw-light mb-0">Job Positions</h5>
          <p
            className="align-self-start mb-0"
            style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight:'600' }}
          >
            Total {jobPositionCount} Job Position
            {jobPositionCount !== 1 ? "s" : ""}
          </p>
        </div>
        {jobPositionCount === 0 ? (
          <div
            className="d-flex justify-content-center align-items-end position-relative"
            style={{ height: "50vh" }}
          >
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{
                backgroundImage: "url(/assets/file.png)",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
              }}
            ></div>
            <p className="m-0 fw-light fs-5 position-relative">
              No Job Positions!
            </p>
          </div>
        ) : (
          <div className="row">
            {jobPositions.map((job) => (
              <div
                key={job.id}
                className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                style={{ cursor: "pointer", minWidth: "270px" }}
                onClick={() => handleCardClick(job.id)}
              >
                <div className="card h-100 px-3">
                  <div className="card-body d-flex flex-column py-2 px-0">
                    <div className="p-2">
                      <h6
                        className="card-title"
                        style={{
                          fontSize: "clamp(12px, 1.2vw, 14px)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        {job.job_title}
                      </h6>
                    </div>
                    <div
                      className="rounded-3 px-3 py-2 mb-2"
                      style={{
                        backgroundColor: "#f8f7f7",
                        fontSize: "clamp(12px, 1.2vw, 14px)",
                      }}
                    >
                      <p className="card-text" style={{marginBottom:'0.5rem'}}>
                        <i className="bi bi-building me-2"></i>
                        Departments: {job.department_details.name}
                      </p>
                      <p
                        className="card-text"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <i className="bi bi-people me-2"></i> Employees: {job.employee_count || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}