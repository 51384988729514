import React, { useState, useEffect } from 'react';
import Table from '../ogComponent/table';
import Spinner from '../ogComponent/spinner';
import axios from 'axios';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Select, MenuItem } from '@mui/material';

export default function EarlyLate() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('1'); // 1 = Late-in, 2 = Early-out, 3 = Overtime
  const [startDate, setStartDate] = useState(dayjs()); // Initialize with today's date
  const [endDate, setEndDate] = useState(dayjs());     // Initialize with today's date

  const header = [
    { name: 'Si No', label: '#', active: true },
    { name: 'employee_code', label: 'ID', active: true },
    { name: 'name', label: 'Name', active: true },
    { name: 'Type', label: 'Type', active: true },
    { name: 'Date', label: 'Date', active: true },
    { name: 'Clock-In', label: 'Check-In', active: true },
    { name: 'Clock-Out', label: 'Check-Out', active: true },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      let apiUrl = '';
      if (filter === '1') {
        apiUrl = `https://hrmsbackend.prosevo.com/api/attendance/late_in/?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
      } else if (filter === '2') {
        apiUrl = `https://hrmsbackend.prosevo.com/api/attendance/early_out/?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
      } else if (filter === '3') {
        apiUrl = `https://hrmsbackend.prosevo.com/api/attendance/overtime/?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
      }

      const response = await axios.get(apiUrl);
      const formattedData = response.data.map((item, index) => ({
        'Si No': index + 1,
        employee_code: item.employee.employee_code,
        name: item.employee.name,
        Type: filter === '1' ? 'Late-in' : filter === '2' ? 'Early-out' : 'Overtime',
        Date: item.date,
        'Clock-In': item.clock_in,
        'Clock-Out': item.clock_out
      }));
      setProduct(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when filter, startDate, or endDate changes
  useEffect(() => {
    fetchData(); 
  }, [filter, startDate, endDate]);

  return (
    <div className="EarlyLateContainer w-100 h-100 px-3 px-lg-4 pt-3 d-flex gap-3 flex-column">
      <h3 className='pt-2'>Late-in & Early-out</h3>

      <div className="d-flex w-100 gap-4 align-items-center justify-content-between">
        {/* Filter Dropdown */}
        <Select
          className="form-control w-auto"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ fontSize: 'clamp(12px, 1.4vw, 16px)' }}
        >
          <MenuItem value="1">Late-In</MenuItem>
          <MenuItem value="2">Early-Out</MenuItem>
          <MenuItem value="3">Overtime</MenuItem>
        </Select>

        {/* Date Pickers for Start and End Dates */}
        <div className="d-flex align-items-center gap-3">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                className='MuidatePicker'
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <input {...params} className="form-control" />}
              />
            </LocalizationProvider>
          </div>

          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                className='MuidatePicker'
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <input {...params} className="form-control" />}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>

      {/* Conditionally render either the table or the "No Attendance" message */}
      {loading ? (
        <Spinner />
      ) : product.length === 0 ? (
        <div className="d-flex justify-content-center align-items-end position-relative" style={{ height: "50vh" }}>
          <div className="position-absolute top-0 start-0 w-100 h-100" style={{
            backgroundImage: 'url(/assets/file.png)',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}></div>
          <p className="m-0 fw-light fs-5 position-relative">No Attendance !</p>
        </div>
      ) : (
        <Table header={header} product={product} onRowClick={() => { }} />
      )}
    </div>
  );
}
