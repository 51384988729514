import React from 'react'
import LeftContainer from '../landingPage/leftContainer'
import RightContainer from '../landingPage/rightContainer'

export default function Dashboard() {
  return (
    <>
      <LeftContainer />
      <RightContainer />
    </>
  )
}
