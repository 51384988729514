import React, { useEffect, useState } from "react";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import Table from "../ogComponent/table";
import axios from "axios";
import Spinner from "../ogComponent/spinner";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function TodayLeaves() {
  const [product, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const showData = {
    firstHeader: "Total Employees",
    firstCount: "786",
    secondHeader: "Attendance",
    secondCount: "97%",
    thirdHeader: "Leaves",
    thirdCount: product.length,
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "employee_code", label: "User Code", active: true },
    { name: "Employee", label: "Employee", active: true },
    { name: "branch", label: "Branch", active: true },
    { name: "Leave Type", label: "Leave Type", active: true },
    { name: 'status', label:'Status', active: true},
    { name: "Reason", label: "Reason", active: true }
  ];

  const handleRowClick = (item) => {
    console.log(item);
    navigate(`/user/leavedetails/${item.employee.id}/${item.id}/`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      const today = dayjs().format("YYYY-MM-DD");

      try {
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/filter_by_date/`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              start_date: today,
              end_date: today,
            },
          }
        );

        const data = response.data.map((item, index) => ({
          ...item,
          "Si No": index + 1,
          "employee_code": item.employee?.employee_code || "N/A",
          Employee: (
            <div className="d-flex align-items-center">
              <img
                src={item.employee.profile_photo || "/assets/user.png"}
                alt={"profile"}
                style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
              />
              {item.employee.name || "N/A"}
            </div>
          ),
          "branch": item.employee?.branch?.branch_name || "N/A",
          "Leave Type": item.leave_type || "N/A",
          Reason: item.reason || "N/A"
        }));

        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="todayLeavesContainer w-100 d-flex flex-column p-3 p-md-4 gap-3 gap-md-4">
      <LeaveShowGrid showData={showData} />
      <h3>Today Leaves</h3>

      {product.length === 0 ? (
        <div className="d-flex justify-content-center align-items-end position-relative" style={{ height: "50vh" }}>
          <div className="position-absolute top-0 start-0 w-100 h-100" style={{ 
            backgroundImage: 'url(/assets/file.png)', 
            backgroundSize: 'contain', 
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}></div>
          <p className="m-0 fw-light fs-5 position-relative">No Leaves Today!</p>
        </div>
      ) : (
        <Table header={header} product={product} onRowClick={handleRowClick} />
      )}
    </div>
  );
}
