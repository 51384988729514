import React from 'react'

export default function LeaveShowGrid({showData}) {
  return (
    <>
    {/* attendence & leave section  */}
    <div class="displayContainer d-flex gap-3 me-0 mb-0 overflow-auto flex-wrap">
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey", cursor:'pointer'}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#eaedff'}}><i class="bi bi-people" style={{fontSize:'clamp(20px, 1.5vw, 25px)', color:'blue'}}></i></div>   
          </div>
          <div className="textHead d-flex h-100">
            <div className="headerText">{showData.firstHeader}</div>
            <div className="countText" style={{ fontSize: "clamp(15px, 1.5vw, 20px)", fontWeight: "700"}}>{showData.firstCount}</div>
          </div>
        </div>
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey", cursor:'pointer'}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#e3fdd9' }}><i class="bi bi-person-check" style={{fontSize:'25px', color:'green'}}></i></div>   
          </div>
          <div className="textHead d-flex  h-100">
            <p className="headerText">{showData.secondHeader}</p>
            <p className="countText" style={{fontSize: "clamp(15px, 1.5vw, 20px)", fontWeight: "700"}}>{showData.secondCount}</p>
          </div>
        </div>
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey", cursor:'pointer'}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#fde9cf'}}><i class="bi bi-person-x" style={{fontSize:'25px', color:'orange'}}></i></div>   
          </div>
          <div className="textHead d-flex h-100" >
            <div className="headerText">{showData.thirdHeader}</div>
            <div className="countText" style={{fontSize: "clamp(15px, 1.5vw, 20px)", fontWeight: "700"}}>{showData.thirdCount}</div>
          </div>
        </div>
            </div>
    </>
  )
}
