import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import Spinner from "../ogComponent/spinner";
import Table from "../ogComponent/table";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconCheck, IconX } from "@tabler/icons-react";

export default function RequestView() {
  const [selectedRequest, setSelectedRequest] = useState("Pending Requests");
  const [product, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const showData = {
    firstHeader: "Total Employees",
    firstCount: "786",
    secondHeader: "Attendance",
    secondCount: "97%",
    thirdHeader: "Leaves",
    thirdCount: "15",
  };

  const header = [
    {
      name: "siNo",
      label: "#",
      active: true,
    },
    {
      name: "name",
      label: "Name",
      active: true,
    },
    {
      name: "leave_type",
      label: "Leave Type",
      active: true,
    },
    {
      name: "total_leave_days",
      label: "Duration",
      active: true,
    },
    {
      name: "status",
      label: "Status",
      active: true,
    },
    {
      name: "cancel",
      label: "",
      active: selectedRequest === "Pending Requests" || selectedRequest === "Approved Requests",
    },
    {
      name: "approved",
      label: "",
      active: selectedRequest === "Pending Requests" || selectedRequest === "Rejected Requests",
    },
  ];

  const handleSelect = (request) => {
    setSelectedRequest(request);
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      let url = "";
      switch (selectedRequest) {
        case "Pending Requests":
          url = "https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/pending_leave/";
          break;
        case "Approved Requests":
          url = "https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/approved_leaves/";
          break;
        case "Rejected Requests":
          url = "https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/rejected_leaves/";
          break;
        default:
          url = "https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/pending_leave/";
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data.map((item, index) => ({
        ...item,
        siNo: index + 1,
        status: item.status,
        name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={item.employee.profile_photo || "/assets/user.png"}
              alt="Profile"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            {item.employee.name || "N/A"}
          </div>
        ),
      }));

      if (selectedRequest === "Pending Requests") {
        data.forEach((item) => {
          item.cancel = (
            <button className="btn btn-outline-danger d-flex align-items-center" style={{height:'25px'}} onClick={(e) => handleRejectLeave(e, item.employee.name, item.id)}>
              <IconX stroke={2} />
            </button>
          );
          item.approved = (
            <button className="btn btn-outline-success d-flex align-items-center" style={{height:'25px'}} onClick={(e) => handleApproveLeave(e, item.employee.name, item.id)}>
              <IconCheck stroke={2} />
            </button>
          );
        });
      } else if (selectedRequest === "Approved Requests") {
        data.forEach((item) => {
          item.cancel = (
            <button className="btn btn-outline-danger d-flex align-items-center" style={{height:'25px'}} onClick={(e) => handleRejectLeave(e, item.employee.name, item.id)}>
              <IconX stroke={2} />
            </button>
          );
          item.approved = null;
        });
      } else if (selectedRequest === "Rejected Requests") {
        data.forEach((item) => {
          item.approved = (
            <button className="btn btn-outline-success d-flex align-items-center" style={{height:'25px'}} onClick={(e) => handleApproveLeave(e, item.employee.name, item.id)}>
              <IconCheck stroke={2} />
            </button>
          );
          item.cancel = null;
        });
      }

      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRequest]);

  const handleApproveLeave = async (e, employeeName, leaveId) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/${leaveId}/approve_leave/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`${employeeName}'s leave approved!`);
      fetchData();
    } catch (error) {
      toast.error("Error approving leave");
      console.error("Error approving leave:", error);
    }
  };

  const handleRejectLeave = async (e, employeeName, leaveId) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/${leaveId}/reject_leave/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.info(`${employeeName}'s leave rejected!`);
      fetchData();
    } catch (error) {
      toast.error("Error rejecting leave");
      console.error("Error rejecting leave:", error);
    }
  };

  const handleRowClick = (item) => {
    navigate(`/user/leaveDetails/${item.employee.id}/${item.id}/`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="LeaveViewContainer w-100 d-flex flex-column p-3 p-md-4 gap-3 gap-md-4">
        <LeaveShowGrid showData={showData} />
        <div className="dropdown d-flex align-items-center gap-2 gap-md-3">
          <button
            className="btn dropdown-toggle text-white border-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              fontSize: "clamp(12px, 1.4vw, 16px)",
              backgroundColor: "#ba6f01",
              maxHeight: "40px",
              minWidth: "200px",
            }}
          >
            {selectedRequest}
          </button>
          <ul className="dropdown-menu">
            <li onClick={() => handleSelect("Pending Requests")}>
              <Link className="dropdown-item">Pending Requests</Link>
            </li>
            <li onClick={() => handleSelect("Approved Requests")}>
              <Link className="dropdown-item">Approved Requests</Link>
            </li>
            <li onClick={() => handleSelect("Rejected Requests")}>
              <Link className="dropdown-item">Rejected Requests</Link>
            </li>
          </ul>
        </div>
        {product.length > 0 ? (
          <Table header={header} product={product} onRowClick={handleRowClick} />
        ) : (
          <div className="d-flex justify-content-center align-items-end position-relative" style={{ height: "50vh" }}>
            <div className="position-absolute top-0 start-0 w-100 h-100" style={{ 
              backgroundImage: 'url(/assets/file.png)', 
              backgroundSize: 'contain', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              opacity: 0.3,
            }}></div>
            <p className="m-0 fw-light fs-5 position-relative">No Leaves !</p>
          </div>
        )}
      </div>
    </>
  );
}