import React, { useEffect, useState } from 'react';
import Spinner from '../ogComponent/spinner';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from '../ogComponent/table';

export default function SingleDayFullPunch() {
  const { userId } = useParams();  // Get user_id from the URL
  const [product, setProduct] = useState([]);
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectDate, setSelectDate] = useState(dayjs());

  const header = [
    { name: 'Si No', label: '#', active: true },
    { name: 'Date', label: 'Date', active: true },
    { name: 'Day', label: 'Day', active: true },
    { name: 'type', label: 'Type', active: true },
    { name: 'time', label: 'Time', active: true }
  ];

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    const fetchAttendanceData = async () => {
      try {
        setLoading(true);
        const formattedDate = selectDate.format('YYYY-MM-DD');

        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/attendance/single-attendance/`,
          {
            params: { punch_date: formattedDate, employee__id: userId },
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const attendanceData = response.data[0];

        if (attendanceData) {
          setEmployee(attendanceData.employee_details);
          const formattedPunchData = attendanceData.punch_details.map((punch, index) => ({
            "Si No": index + 1,
            Date: attendanceData.punch_date,
            Day: attendanceData.day,
            type: punch.punch_direction,
            time: punch.punch_time
          }));
          setProduct(formattedPunchData);
        } else {
          setProduct([]); // Set to empty array if no attendance data
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setLoading(false);
        setProduct([]); // Ensure product is empty on error as well
      }
    };

    fetchAttendanceData();
  }, [selectDate, userId]);  // Adding userId as a dependency to refetch if it changes

  return (
    <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
      <h3 className='pt-2'>Single Day Data</h3>

      <div className='empData'>
        <p className='text-secondary m-0'>
          Name: <span className='text-black'>{employee.Name}</span>
        </p>
        <p className='m-0 text-secondary'>
          Branch: <span className='text-black'>{employee.Branch_name} - {employee.Branch_location}</span>
        </p>
        <p className='m-0 text-secondary'>
          Position: <span className='text-black'>{employee.Job_position}</span>
        </p>
        <p className='m-0 text-secondary'>
          Phone: <span className='text-black'>{employee.Phone}</span>
        </p>
        <p className='m-0 text-secondary'>
          Email: <span className='text-black'>{employee.Email}</span>
        </p>
      </div>

      <div className='d-flex w-100 gap-4 mb-3 align-items-center justify-content-end'>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select a Date"
              value={selectDate}
              className='MuidatePicker1'
              onChange={(newValue) => setSelectDate(newValue)}
              renderInput={(params) => <input {...params} className="form-control" />}
            />
          </LocalizationProvider>
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : product.length > 0 ? (
        <Table
          header={header}
          product={product}
          loading={loading}
          onRowClick={() => { }}
        />
      ) : (
        <div className="d-flex justify-content-center align-items-end position-relative" style={{ height: "50vh" }}>
          <div className="position-absolute top-0 start-0 w-100 h-100" style={{
            backgroundImage: 'url(/assets/file.png)',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}></div>
          <p className="m-0 fw-light fs-5 position-relative">No Attendance or Leave Data!</p>
        </div>
      )}
    </div>
  );
}
