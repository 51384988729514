import React, { useEffect, useState } from "react";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import Table from "../ogComponent/table";
import Spinner from "../ogComponent/spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Function to get the first day of the current month
const getFirstDayOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
};

// Function to get today's date
const getToday = () => {
  return new Date().toISOString().split('T')[0];
};

export default function LeaveReport() {
  const [product, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getToday());
  const [noLeaves, setNoLeaves] = useState(false);
  const navigate = useNavigate();  

  const showData = {
    firstHeader: "Total Employees",
    firstCount: "786",
    secondHeader: "Attendance",
    secondCount: "97%",
    thirdHeader: "Leaves",
    thirdCount: "15",
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "employee_code", label: "Id", active: true },
    { name: "Employee", label: "Name", active: true },
    // { name: "branch", label: "Branch", active: true },
    { name: "Leave Type", label: "Leave Type", active: true },
    { name: 'date', label: "Date", active: true},
    // { name:'day', label:'Day', active: true},
    // { name: "Duration", label: "Duration", active: true },
    { name: "Reason", label: "Reason", active: true },
  ];

  const fetchData = async (startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    setLoading(true); // Set loading to true at the start of data fetching
    try {
      const response = await axios.get(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/filter_by_date/?start_date=${startDate}&end_date=${endDate}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const filterData = response.data;

      if (filterData.length === 0) {
        setNoLeaves(true);
        setData([]);
      } else {
        setNoLeaves(false);

        const formattedData = filterData.map((item, index) => ({
          ...item,
          "Si No": index + 1,
          "employee_code": item.employee?.employee_code || "N/A",
          // "branch": item.employee?.branch?.branch_name || "N/A",
          Employee: (
            <div className="d-flex align-items-center">
              <img
                src={item.employee.profile_photo || "/assets/user.png"}
                alt="profile"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              />
              {item.employee.name || "N/A"} 
            </div>
          ),
          "Leave Type": item.leave_type,
          // Duration: `${item.total_leave_days} day` || "N/A",
          Reason: item.reason || "N/A",
          date: item.start_date || "N/A",
          // day: item.day
        }));

        setData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [startDate, endDate]);

  const handleRowClick = (item) => {
    navigate(`/user/leaveDetails/${item.employee.id}/${item.id}/`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="leaveReportContainer w-100 d-flex flex-column p-3 p-md-4 gap-3 gap-md-4">
      <LeaveShowGrid showData={showData} />

      {/* Filter by date */}
      <div className="dateFilterContainer d-flex gap-3 justify-content-between justify-content-sm-start">
        <div className="startDate d-flex flex-column">
          <label
            htmlFor="startDate"
            style={{ fontSize: "clamp(10px, 1.2vw, 13px)" }}
            className="fw-bold"
          >
            Start Date
          </label>
          <input
            id="startDate"
            type="date"
            className="rounded-1 px-2 px-md-3 py-1 bg-white"
            style={{
              border: "2px solid grey",
              fontSize: "clamp(12px, 1.3vw, 15px)",
            }}
            placeholder="dd-mm-yy"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="endDate d-flex flex-column">
          <label
            htmlFor="endDate"
            style={{ fontSize: "clamp(10px, 1.2vw, 13px)" }}
            className="fw-bold"
          >
            End Date
          </label>
          <input
            id="endDate"
            type="date"
            className="rounded-1 px-2 px-md-3 py-1 bg-white"
            style={{
              border: "2px solid grey",
              fontSize: "clamp(12px, 1.4vw, 16px)",
            }}
            placeholder="dd-mm-yy"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>

      {/* Conditional rendering for no leaves */}
      {noLeaves ? (
        <div className="d-flex justify-content-center align-items-end position-relative" style={{ height: "50vh" }}>
          <div className="position-absolute top-0 start-0 w-100 h-100" style={{ 
            backgroundImage: 'url(/assets/file.png)', 
            backgroundSize: 'contain', 
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.3,
          }}></div>
          <p className="m-0 fw-light fs-5 position-relative">No Leaves in this Date!</p>
        </div>
      ) : (
        <Table header={header} product={product} onRowClick={handleRowClick} />
      )}
    </div>
  );
}
