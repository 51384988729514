import React, { useEffect, useState } from "react";
import Table from "../ogComponent/table";
import Spinner from "../ogComponent/spinner";
import axios from "axios";
import { IconPencilMinus, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditLeaveModal from "./cl_edit_modal";
import { toast, ToastContainer } from "react-toastify";

const LeaveShow = () => {
  const navigate = useNavigate();
  const [header, setHeader] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/leavemanagement/company-holiday/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        const transformedHeader = transformHeader(response.data);
        const transformedProduct = transformProduct(response.data);

        setHeader(transformedHeader);
        setProduct(transformedProduct);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const transformHeader = (data) => {
    if (data.length === 0) return [];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem);

    const transformedHeader = keys.map((key) => ({
      name: key,
      label: key.toUpperCase().replace(/_/g, " "),
      active: key !== "id",
    }));

    transformedHeader.unshift({
      name: "Si No",
      label: "#",
      active: true,
    });
    transformedHeader.push({ name: "edit", label: "", active: true });
    transformedHeader.push({ name: "delete", label: "", active: true });

    return transformedHeader;
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const handleEditClick = (leaveId) => {
    setSelectedLeaveId(leaveId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLeaveId(null);
  };

  const transformProduct = (data) => {
    return data.map((item, index) => ({
      ...item,
      "Si No": index + 1,
      image: item.image ? (
        <img
          src={item.image}
          alt=""
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ) : (
        <img
          src="https://via.placeholder.com/30"
          alt="Placeholder"
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ),
      delete: (
        <IconTrash stroke={2} onClick={(e) => handleOpenModal(e, item)} />
      ),
      edit: (
        <IconPencilMinus
          stroke={2}
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick(item.id);
          }}
        />
      ),
    }));
  };

  const handleLeaveUpdated = (updatedLeave) => {
    setProduct((prevProduct) => {
      return prevProduct.map((item) => {
        if (item.id === updatedLeave.id) {
          return {
            ...item,
            title: updatedLeave.title,
            start_date: updatedLeave.start_date,
            end_date: updatedLeave.end_date,
            days: updatedLeave.days,
            image: updatedLeave.image
              ? (
                <img
                  src={updatedLeave.image}
                  alt=""
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                />
              )
              : (
                <img
                  src="https://via.placeholder.com/30"
                  alt="Placeholder"
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                />
              ),
          };
        }
        return item;
      });
    });
  };

  const handleOpenModal = (e, item) => {
    e.stopPropagation();
    setSelectedRow(item);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    if (!selectedRow) return;

    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/company-holiday/${selectedRow.id}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProduct(product.filter((item) => item.id !== selectedRow.id));
      handleCloseDeleteModal(); // Close the modal after successful deletion
      toast.success("Leave Deleted succesfully !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="leaveShowContainer w-100 d-flex flex-column px-3 px-md-4">
      <h3 className="my-3 my-md-4">Company Leaves</h3>
      <div className="d-flex justify-content-end pb-2">
        <div className="btn btn-success d-flex align-items-center">
          <i className="lni lni-plus me-1"></i>
          <span>New Leave</span>
        </div>
      </div>

      {product.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-end position-relative"
          style={{ height: "50vh" }}
        >
          <div
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{
              backgroundImage: "url(/assets/file.png)",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              opacity: 0.3,
            }}
          ></div>
          <p className="m-0 fw-light fs-5 position-relative">No Leaves!</p>
        </div>
      ) : (
        <Table header={header} product={product} onRowClick={() => { }} />
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteModalOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseDeleteModal();
          }
        }}
        disableEscapeKeyDown
      >
        <Box sx={{ ...modalStyle, outline: "none", padding: "25px" }} className="modalmainbox">
          <div className="w-100 d-flex align-items-center flex-column">
            <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 60, width: "100%" }} />
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "clamp(13px, 1.4vw, 17px)",
                mb: 2,
              }}
            >
              Confirm Delete
            </Box>
            <Box
              sx={{
                color: "#555",
                mb: 2,
                fontSize: "clamp(12px, 1.2vw, 14px)",
                textAlign: "center",
                paddingX: "10px",
              }}
            >
              Are you sure you want to delete this Leave?
            </Box>
          </div>
          <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
            <button
              className="btn btn-danger"
              onClick={handleDelete}
              style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
            >
              Confirm
            </button>
            <button
              className="btn btn-secondary"
              onClick={handleCloseDeleteModal}
              style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      {/* Edit Leave Modal */}
      {isModalOpen && (
        <EditLeaveModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          leaveId={selectedLeaveId}
          onLeaveUpdated={handleLeaveUpdated}
        />
      )}
       <ToastContainer />
    </div>
  );
};

export default LeaveShow;