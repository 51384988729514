import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import Table from "../ogComponent/table";
import Spinner from "../ogComponent/spinner";
import Modal from "../ogComponent/modal";
import axios from "axios";
import { Toast } from 'primereact/toast';

const SelfLeaveTable = () => {
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const toast = useRef(null);
  const token = localStorage.getItem("accessToken");

  const showData = {
    firstHeader: "Total Days",
    firstCount: "176",
    secondHeader: "Attendance",
    secondCount: "94%",
    thirdHeader: "Leaves",
    thirdCount: "08",
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "LeaveType", label: "Leave Type", active: true },
    { name: "Days", label: "Days", active: true },
    { name: "Status", label: "Status", active: true },
  ];

  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/employee/${userId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formattedData = response.data.map((item, index) => ({
          "Si No": index + 1,
          LeaveType: item.leave_type,
          "Days": item.total_leave_days || calculateDays(item.start_date, item.end_date),
          Status: (
            <span
              className={`badge ${
                item.status === "A"
                  ? "bg-success"
                  : item.status === "P"
                  ? "bg-warning text-dark"
                  : "bg-danger"
              }`}
            >
              {item.status === "A" ? "Approved" : item.status === "P" ? "Pending" : "Rejected"}
            </span>
          ),
          id: item.id,
          employeeId: item.employee.id,
        }));

        setLeaveData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leave data:", error);
        setLoading(false);
      }
    };

    fetchLeaveData();
  }, [userId, token]); // Only depend on userId and token

  const calculateDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const handleRowClick = (item) => {
    navigate(`/user/leaveDetails/${userId}/${item.id}/`);
  };

  const handleSubmit = async (data) => {
    try {
      const leaveData = {
        end_date: data.end_date,
        half_day_leave: data.half_day_leave,
        leave_type: data.leave_type,
        reason: data.reason,
        start_date: data.start_date,
        total_leave_days: data.total_leave_days,
        emp_id: userId,
      };

      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/",
        leaveData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Leave request submitted successfully:", response.data);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: "Leave Applied",
        life: 3000,
      });
    } catch (error) {
      console.error("Error submitting leave request:", error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: "Error submitting, please try again!",
        life: 3000,
      });
    }
  };

  const modalFieldsConfig = [
    {
      name: "half_day_leave",
      label: "Half Day Leave",
      type: "toggle",
      required: false,
      className: "align-self-end",
    },
    {
      name: "leave_type",
      label: "Leave Type",
      type: "select",
      options: [
        { value: "", label: "Select Leave Type" },
        { value: "annual", label: "Annual Leave" },
        { value: "sick", label: "Sick Leave" },
        { value: "personal", label: "Personal Leave" },
      ],
      required: true,
    },
    {
      name: "start_date",
      label: "Start Date",
      type: "date",
      required: true,
    },
    {
      name: "end_date",
      label: "End Date",
      type: "date",
      required: true,
    },
    {
      name: "total_leave_days",
      label: "Total Leave Days",
      type: "text",
      disabled: true,
    },
    {
      name: "reason",
      label: "Leave Reason",
      type: "textarea",
      required: false,
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="selfLeaveContainer w-100">
      <Toast ref={toast} position="bottom-right" />
      <div className="container p-3 p-md-4 d-flex flex-column gap-3 gap-md-4">
        <LeaveShowGrid showData={showData} />
        <button
          className="btn btn-success d-flex gap-3 align-items-center text-nowrap"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          style={{ width: "min-content", fontSize: "clamp(12px, 1.4vw, 16px)" }}
        >
          Apply New Leave <i className="bi bi-plus-circle"></i>
        </button>
        {leaveData.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-end position-relative"
            style={{ height: "50vh" }}
          >
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{
                backgroundImage: "url(/assets/file.png)",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
              }}
            ></div>
            <p className="m-0 fw-light fs-5 position-relative">No Leaves!</p>
          </div>
        ) : (
          <Table
            header={header}
            product={leaveData}
            onRowClick={handleRowClick}
          />
        )}
      </div>
      <Modal
        heading="Apply New Leave"
        fields={modalFieldsConfig}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default SelfLeaveTable;
