import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import MuiSelect from "../ogComponent/muiSelect";
import BasicTabs from "../ogComponent/muiTabs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Spinner from "../ogComponent/spinner";
import { Toast } from 'primereact/toast';
import "./addEmp.css";

function AddEmployee() {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      employee_code: "",
      profile_photo: "",
      nationality: "",
      blood_group: "",
      education_level: "",
      password: "",
      gender: "",
      marital_status: "",
      work_experience: "",
      status: "",
      phone_number: "",
      email: "",
      branch: "",
      job_type: "",
      type_of_salary: "",
      department: "",
      job_position: "",
      joined_date: "",
      end_of_probation: "",
      effective_date: "",
      basic_salary: "",
      currency: "",
      benifits: "",
      bonus: "",
      deduction: "",
      city: "",
      state: "",
      pincode: "",
      office_phone: "",
      relative_name: "",
      relation: "",
      rel_phone_number: "",
      address_1: "",
      address_2: "",
      hr_to: [],
      reporting_to: [],
      roles: [],
    },
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [salaryTypes, setSalaryTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [reportingOptions, setReportingOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hrPositions, setHrpositions] = useState([]);
  const [error, setError] = useState(null);
  const [isDepartmentDisabled, setDepartmentDisabled] = useState(true);
  const [isJobPositionDisabled, setJobPositionDisabled] = useState(true);
  const Genoptions = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
  const token = localStorage.getItem("accessToken");
  const toast = useRef(null);


  // Fetch the reporting options from the API
  useEffect(() => {
    const fetchReportingOptions = async () => {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get("https://hrmsbackend.prosevo.com/api/account/users-list/", config);
        setReportingOptions(
          response.data.map(user => ({
            value: user.id,
            label: user.name, // Show the name in the dropdown
          }))
        );
      } catch (error) {
        console.error("Error fetching reporting options:", error);
        setError("Failed to fetch reporting options. Please try again later.");
      }
    };

    fetchReportingOptions();
  }, [token]);

  // Handle error using Toast
  useEffect(() => {
    if (error && toast.current) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
      setError(null);
    }
  }, [error]);


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "white",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const SelectStyle = {
    control: (base, state) => ({
      ...base,
      minHeight: '38px', // Standard height for the control
      maxHeight: '38px', // Fixed maximum height
      overflow: 'hidden' // Prevent control from expanding
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: '38px',
      overflow: 'auto',
      flexWrap: 'nowrap',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      padding: '2px 8px'
    }),
    multiValue: (base) => ({
      ...base,
      flexShrink: 0 // Prevent values from shrinking
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };

        const [branchesResponse, rolesResponse, jobTypesResponse, salaryTypesResponse, hrPositionResponse] = await Promise.all([
          axios.get("https://hrmsbackend.prosevo.com/api/employee/branches/", config),
          axios.get("https://hrmsbackend.prosevo.com/api/common/roles/", config),
          axios.get("https://hrmsbackend.prosevo.com/api/employee/jobtypes/", config),
          axios.get("https://hrmsbackend.prosevo.com/api/employee/salarytypes/", config),
          axios.get("https://hrmsbackend.prosevo.com/api/common/hrroles/list", config),
        ]);

        setBranches(branchesResponse.data.map(branch => ({ name: `${branch.branch_name} - ${branch.location}`, id: branch.id })));
        setRoles(rolesResponse.data.map(role => ({ value: role.id, label: role.role_name })));
        setJobTypes(jobTypesResponse.data.map(item => ({ value: item.id, name: item.job_type })));
        setSalaryTypes(salaryTypesResponse.data.map(item => ({ value: item.id, name: item.type_of_salary })));

        // Flatten the hr_role_details and map the data for the select options
        const hrPositionsFlattened = hrPositionResponse.data.flatMap(role =>
          role.hr_role_details.map(detail => ({
            value: detail.id,
            label: detail.name
          }))
        );

        setHrpositions(hrPositionsFlattened);

      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Failed to fetch necessary data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);


  // Handle branch selection and enable department field
  const handleBranchChange = async (branchId) => {
    setValue("branch", branchId);
    setDepartmentDisabled(true);
    setJobPositionDisabled(true);
    setDepartments([]);
    setJobPositions([]);
    console.log("branch id", branchId)

    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await axios.get(`https://hrmsbackend.prosevo.com/api/employee/departments/?branches=${branchId.id}`, config);
      console.log("departments", response)
      setDepartments(response.data.map(dept => ({ name: dept.dep_name, id: dept.id })));
      setDepartmentDisabled(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError("Failed to fetch departments. Please try again later.");
    }
  };

  // Handle department selection and enable job_position field
  const handleDepartmentChange = async (branchId, departmentId) => {
    console.log("second BranchId", branchId)
    setValue("department", departmentId);
    setJobPositionDisabled(true);
    setJobPositions([]);

    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await axios.get(`https://hrmsbackend.prosevo.com/api/employee/jobpositions/?branch=${branchId}&department=${departmentId.id}`, config);
      setJobPositions(response.data.map(position => ({ name: position.job_title, id: position.id })));
      setJobPositionDisabled(false);
    } catch (error) {
      console.error("Error fetching job positions:", error);
      setError("Failed to fetch job positions. Please try again later.");
    }
  };

  useEffect(() => {
    if (error && toast.current) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
      setError(null);
    }
  }, [error]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setValue("profile_photo", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      setSelectedImage(null);
      setValue("profile_photo", "");
    }
  };

  const handleSelectImage = () => {
    document.getElementById("employee-image-input").click();
  };

  const handleReset = () => {
    reset();
    setSelectedImage(null);
  };



  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();

      const getIdOrName = (value) => {
        if (value && typeof value === "object") {
          return value.id || value.value || value.name || value;
        }
        return value;
      };

      const appendMultipleValues = (key, values) => {
        if (Array.isArray(values)) {
          values.forEach((value) => {
            formData.append(key, String(getIdOrName(value)));
          });
        } else if (values && typeof values === "object") {
          formData.append(key, String(getIdOrName(values)));
        }
      };

      Object.keys(data).forEach((key) => {
        if (key === "profile_photo") return;

        let value = data[key];

        if (
          [
            "blood_group",
            "gender",
            "marital_status",
            "work_experience",
            "currency",
            "job_type",
            "type_of_salary",
            "department",
            "job_position",
            "branch",
          ].includes(key)
        ) {
          value = getIdOrName(value);
        }

        if (key === "status") {
          value = value?.value === "active";
        }

        if (["roles", "hr_to", "reporting_to"].includes(key)) {
          // Process multiple select fields
          appendMultipleValues(key, value);
        } else if (Array.isArray(value)) {
          // Handle any other array fields
          appendMultipleValues(key, value);
        } else {
          // Process other fields that are not arrays
          formData.append(key, value);
        }
      });

      if (selectedImage) {
        const profilePhotoFile = await fetch(selectedImage).then((res) =>
          res.blob()
        );
        formData.append("profile_photo", profilePhotoFile, "profile_photo.jpg");
      }

      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/users/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Employee added successfully:", response.data);
      handleReset();
      setOpenModal(true);
      setTimeout(() => {
        setOpenModal(false);
      }, 3000);
    } catch (error) {
      console.error("Error adding employee:", error);

      if (error.response && error.response.data && error.response.data.email) {
        const emailError = error.response.data.email[0];
        console.log("email error is", emailError);
        console.log("email error caught");
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: "This email already Exist",
          life: 3000,
        });
      } else {
        console.log("other error caught");
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to add employee. Please try again later.',
          life: 3000
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="addEmpContainer w-100 h-100 px-lg-5 py-lg-4 p-sm-1">
      <Toast ref={toast} position="bottom-right" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="formContainer w-100 h-100 d-flex gap-4 flex-column"
        style={{ backgroundColor: "#5853b70d" }}
      >
        <div className="firstContainer w-100 d-flex justify-content-center align-items-center">

          <div className="imageUploaderside d-flex justify-content-center align-items-center">
            <div className="image-uploader">
              {selectedImage ? (
                <div className="selected-image-container">
                  <img
                    src={selectedImage}
                    alt="Selected Employee Img"
                    className="selected-image"
                  />
                </div>
              ) : (
                <div className="upload-image-container">
                  <img
                    src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                    alt=""
                    className="w-100 h-100"
                  />
                </div>
              )}
              <button
                type="button"
                className="select-image-button"
                onClick={handleSelectImage}
              >
                <i className="pen-icon fas fa-pen"></i>
                Select Image
              </button>
              <input
                type="file"
                id="employee-image-input"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="dataSide d-grid w-100">

            <div className="SingleData">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="form-control"
                    label="Name"
                    variant="outlined"
                    size="small"
                    placeholder="Reni Thomas"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{ sx: { height: "35px" } }}
                  // style={{fontSize:'clamp(12px, 1.1vw, 13px)'}}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="employee_code"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="form-control"
                    label="Emp Code"
                    variant="outlined"
                    size="small"
                    placeholder="0001"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{ sx: { height: "35px" } }}
                  // style={{fontSize:'clamp(12px, 1.1vw, 13px)'}}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="phone_number"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\+?[1-9]\d{1,14}$/,
                    message: "Invalid phone number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="tel"
                    className="form-control"
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                    placeholder="+91 1234567890"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{ sx: { height: "35px" } }}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    className="form-control"
                    label="Email"
                    variant="outlined"
                    size="small"
                    placeholder="abcd@gmail.com"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{ sx: { height: "35px" } }}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 8,
                    message: "min 8 letter",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                    message: "must contain 1 letter & 1 number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    label="Password"
                    variant="outlined"
                    size="small"
                    placeholder="Enter your password"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      sx: { height: "35px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <MuiSelect
                    {...field}
                    label="Gender"
                    options={Genoptions}
                    className={`bg-white z-index-10`}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>

            {/* Branch Select */}
            <div className="SingleData">
              <Controller
                name="branch"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <MuiSelect
                    {...field}
                    label="Branch Name"
                    options={branches}
                    className="bg-white"
                    error={!!error}
                    helperText={error?.message}
                    onChange={(event) => handleBranchChange(event.target.value)}
                  />
                )}
              />
            </div>

            {/* Department Select */}
            <div className="SingleData">
              <Controller
                name="department"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <MuiSelect
                    {...field}
                    label="Department"
                    options={departments}
                    className="bg-white"
                    error={!!error}
                    helperText={error?.message}
                    disabled={isDepartmentDisabled}
                    onChange={(event) => handleDepartmentChange(field.value.branch, event.target.value)}
                  />
                )}
              />
            </div>

            {/* Job Position Select */}
            <div className="SingleData">
              <Controller
                name="job_position"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <MuiSelect
                    {...field}
                    label="Job Position"
                    options={jobPositions}
                    className="bg-white"
                    error={!!error}
                    helperText={error?.message}
                    disabled={isJobPositionDisabled}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="hr_to"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={hrPositions}
                    className={`bg-white z-index-10 ${errors.hr_to ? "error-border" : ""
                      }`}
                    isMulti
                    placeholder="Hr to"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    styles={SelectStyle}
                  />
                )}
              />
            </div>

            <div className="SingleData">
              <Controller
                name="reporting_to"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={reportingOptions} // Set fetched options here
                    className={`bg-white z-index-10 ${errors.reporting_to ? "error-border" : ""}`}
                    isMulti // Enable multiple selection
                    placeholder="Reporting To"
                    getOptionLabel={(option) => option.label} // Display 'name'
                    getOptionValue={(option) => option.value}
                    styles={SelectStyle} // Send 'id' to backend
                  />
                )}
              />
            </div>

            <div className="SingleData rolesField">
              <Controller
                name="roles"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roles}
                    className={`bg-white z-index-10 ${errors.roles ? "error-border" : ""
                      }`}
                    isMulti
                    placeholder="Select Roles"
                    styles={SelectStyle}
                  />
                )}
              />
            </div>

          </div>
        </div>
        <div className="containerCollapse w-100 d-flex flex-column gap-md-4">
          <BasicTabs
            tabs={["Personal Details", "Job Details", "Contact Details"]}
            control={control}
            jobTypes={jobTypes}
            salaryTypes={salaryTypes}
            type={"add"}
          />
        </div>
        <div className="fifthContainer d-flex justify-content-end me-md-5 me-3 gap-md-4 gap-2 pe-md-5 pe-2">
          <button
            type="button"
            className="btn text-white btn-success"
            onClick={handleReset}
          >
            Reset
          </button>
          <button
            type="submit"
            className="btn text-white d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#120e4d", minWidth: "120px", height: "38px" }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                {/* <span>Loading...</span> */}
              </>
            ) : (
              "Add Employee"
            )}
          </button>
        </div>
      </form>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <CheckCircleOutlineIcon
            sx={{ color: "#4CAF50", fontSize: 60, mb: 2 }}
          />
          <Box sx={{ fontWeight: "bold", fontSize: 24, mb: 1 }}>Success</Box>
          <Box sx={{ color: "#555", mb: 2 }}>
            New employee Added Succesfully !
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AddEmployee;
