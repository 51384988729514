import React, { useEffect, useRef, useState, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import ModalTable from "../ogComponent/modaltable";
import axios from "axios";

export function DemoCalendar() {
  const [value, onChange] = useState(new Date());
  const [hoveredDate, setHoveredDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef();

  const fetchAttendanceData = useCallback(async (date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    const employeeId = localStorage.getItem('userId');
    // const employeeId = 32;

    try {
      const response = await axios.get(`https://hrmsbackend.prosevo.com/api/attendance/attendance/`, {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          employee_id: employeeId
        }
      });
      console.log("responce is", response)
      setAttendanceData(response.data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  }, []);

  useEffect(() => {
    fetchAttendanceData(value);
  }, [value, fetchAttendanceData]);

  const handleClickDay = (date) => {
    onChange(date);
    setSelectedDate(date);
  };

  const handleMouseOver = (event) => {
    const abbr = event.currentTarget.querySelector("abbr");
    if (abbr) {
      const date = new Date(abbr.getAttribute("aria-label"));
      setHoveredDate(date);
    }
  };

  const handleMouseOut = () => {
    setHoveredDate(null);
  };

  const updateEventListeners = useCallback(() => {
    let tiles = [];
    if (calendarRef.current) {
      tiles = calendarRef.current.querySelectorAll(
        ".react-calendar__month-view__days__day"
      );
      tiles.forEach((tile) => {
        tile.addEventListener("mouseover", handleMouseOver);
        tile.addEventListener("mouseout", handleMouseOut);
      });
    }
    return () => {
      tiles.forEach((tile) => {
        tile.removeEventListener("mouseover", handleMouseOver);
        tile.removeEventListener("mouseout", handleMouseOut);
      });
    };
  }, []);

  useEffect(() => {
    const cleanupListeners = updateEventListeners();
    return cleanupListeners;
  }, [updateEventListeners]);

  useEffect(() => {
    const calendarElement = calendarRef.current;
    if (calendarElement) {
      const observer = new MutationObserver(() => {
        const cleanupListeners = updateEventListeners();
        return cleanupListeners;
      });
      observer.observe(calendarElement, {
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
  }, [updateEventListeners, value]);

  const getAttendanceForDate = (date) => {
    return attendanceData.find(item => new Date(item.date).toDateString() === date.toDateString());
  };

  return (
    <>
      <div ref={calendarRef} className="calenderContainer">
        <Calendar
          onChange={onChange}
          value={value}
          onClickDay={handleClickDay}
          onActiveStartDateChange={(activeStartDate) => {
            setHoveredDate(null);
            fetchAttendanceData(activeStartDate.activeStartDate);
          }}
          tileContent={({ date, view }) => {
            const attendance = getAttendanceForDate(date);
            return (
              view === "month" &&
              hoveredDate &&
              date.toDateString() === hoveredDate.toDateString() && (
                <div className="tooltip-active d-flex flex-column">
                  <span className="z-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    {attendance ? (
                      <>
                        <p>Check-in: {attendance.clock_in}</p>
                        <p>Check-Out: {attendance.clock_out}</p>
                      </>
                    ) : (
                      <p>No attendance data</p>
                    )}
                  </span>
                </div>
              )
            );
          }}
        />
      </div>
      <ModalTable selectedDate={selectedDate} />
    </>
  );
}