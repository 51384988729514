import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from "axios";
import { CSSTransition } from "react-transition-group";

export default function Navbar({
  mainwidth,
  setMainWidth,
  setCanvasWidth,
  setOpacity,
  setHide,
  closeAllCollapses,
  isBelow1000px,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  function settingWidth() {
    if (mainwidth === false) {
      setMainWidth(true);
      setCanvasWidth("250px");
      setTimeout(() => {
        setOpacity("100%");
        setHide("block");
      }, 300);
    } else {
      setMainWidth(false);
      setCanvasWidth("60px");
      setOpacity("0%");
      setHide("none");
      closeAllCollapses();
    }
  }

  const profilePhoto = useSelector((state) => state.emp.emp_list.profile_photo);
  const userName = useSelector((state) => state.emp.emp_list.name);
  const userMail = useSelector((state) => state.emp.emp_list.email);
  const userId = useSelector((state) => state.emp.emp_list.id);

  const profileImageStyle = {
    backgroundImage: profilePhoto
      ? `url(https://hrmsbackend.prosevo.com${profilePhoto})`
      : `url(/assets/user.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "2px solid white",
    cursor: "pointer",
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/logout/",
        {
          refresh: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "clamp(400px, 20vw, 300px)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    borderRadius: "13px",
  };

  return (
    <div
      style={{
        backgroundColor: "#120e4d",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "20",
        boxShadow: "5px 12px 13px -10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <nav className="navbar">
        <div className="Maincontainer-fluid w-100 d-flex align-items-center flex-nowrap justify-content-between">
          <div className="leftSide d-flex align-items-center ps-lg-4 ps-sm-1 justify-content-center">
            <button
              className="btn"
              type="button"
              onClick={() => settingWidth()}
              {...(isBelow1000px && {
                "data-bs-toggle": "offcanvas",
                "data-bs-target": "#offcanvasExample",
                "aria-controls": "offcanvasExample",
              })}
            >
              <i
                style={{ fontSize: "22px", color: "white" }}
                className="bi bi-list"
              ></i>
            </button>
            <h1
              className="navbar-brand ms-lg-5 ms-sm-1 d-flex align-items-center justify-content-center pb-0"
              style={{ color: "white", fontSize: 'clamp(18px, 1.7vw, 20px)' }}
            >
              Circle <span style={{ fontWeight: "700" }}>Soft</span>
            </h1>
          </div>
          <div className="centerSide w-50">
            <form className="d-flex w-100" role="search">
              <div className="searchBox d-flex align-items-center w-75 position-relative">
                <input
                  className="form-control border-0"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  style={{height:'30px', fontSize:'clamp(13px, 1.3vw, 15px)'}}
                />
                <span style={{ position: "absolute", right: "15px" }}>
                  <i className="bi bi-search"></i>
                </span>
              </div>
            </form>
          </div>
          <div className="rightSide d-flex justify-content-evenly">
            <button
              style={{
                backgroundColor: "#f7f7f7",
                borderRadius: "8px",
                position: "relative",
                fontSize:'clamp(13px, 1.3vw, 14px)',
                width:'30px',
                height:'30px'
              }}
              className="notificationBtn btn border-0 me-lg-4 me-sm-3 p-0"
            >
              <i className="bi bi-bell" style={{fontSize:'clamp(13px, 1.3vw, 14px)'}}></i>
              <span
                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-1"
                style={{ fontSize: "8px" }}
              >
                9+
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <div className="dropdown">
              <div
                className="profileImage"
                style={profileImageStyle}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              ></div>
              {dropdownOpen && (
                <div
                  className="dropdown-menu show"
                  style={{
                    position: "absolute",
                    right: 35,
                    padding: "0 0 0.3rem 0",
                  }}
                >
                  <div className="user-info px-3 py-1 border-bottom">
                    <div
                      className="font-weight-bold fw-bolder"
                      style={{ fontSize: "clamp(11px, 1.2vw, 13px)" }}
                    >
                      {userName ? `${userName}` : "Admin"}
                    </div>

                    <div
                      className="text-muted small"
                      style={{ fontSize: "clamp(10px, 1vw, 11px)" }}
                    >
                      {userMail}
                    </div>
                  </div>
                  <Link
                    to={`/user/empdetails/${userId}`}
                    className="dropdown-item"
                    style={{ fontSize: "clamp(11px, 1.2vw, 13px)" }}
                    onClick={() => setDropdownOpen(false)}
                  >
                    <i className="lni lni-user me-2"></i>Profile
                  </Link>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setOpenModal(true);
                      setDropdownOpen(false);
                    }}
                    style={{ fontSize: "clamp(11px, 1.2vw, 13px)" }}
                  >
                    <i className="lni lni-enter me-2"></i>Log out
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <CSSTransition
        in={openModal}
        timeout={300}
        classNames={{
          enter: "modal-enter",
          exit: "modal-exit",
        }}
        unmountOnExit
      >
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ outline: "none" }} // Remove focus outline from Modal
        >
          <Box sx={{ ...modalStyle, outline: "none" }}>
            {" "}
            {/* Remove focus outline from Box */}
            <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 60, mb: 2 }} />
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "clamp(18px, 1.7vw, 22px)",
                mb: 1,
              }}
            >
              Logout
            </Box>
            <Box
              sx={{
                color: "#555",
                mb: 2,
                fontSize: "clamp(12px, 1.2vw, 15px)",
                textWrap: "nowrap",
              }}
            >
              Are you sure you want to logout?
            </Box>
            <button
              className="btn btn-primary"
              onClick={handleLogout}
              style={{ fontSize: "clamp(13px, 1.3vw, 16px)" }}
            >
              Confirm
            </button>
          </Box>
        </Modal>
      </CSSTransition>
    </div>
  );
}
