// CustomAccordion.js
import React, { useState } from 'react';

export default function CustomAccordion({ items, onBodyClick }) {
    const [expandedId, setExpandedId] = useState(null);

    const toggleAccordion = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div>
            {items.map((item) => (
                <div key={item.id} className="mb-2">
                    <div 
                        className="p-3 bg-primary text-white cursor-pointer"
                        onClick={() => toggleAccordion(item.id)}
                    >
                        <h6 className="m-0">{item.course_name}</h6>
                    </div>
                    {expandedId === item.id && (
                        <div 
                            className="p-3 border border-top-0" 
                            onClick={() => onBodyClick(item.id)}
                            style={{ cursor: "pointer" }}
                        >
                            <p><strong>Instructor:</strong> {item.course_instructor?.name}</p>
                            <p><strong>Duration:</strong> {item.course_duration} Days</p>
                            <p><strong>Students:</strong> {item.student_count}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
