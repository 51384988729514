import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress, TextField } from '@mui/material';
import MuiSelect from '../ogComponent/muiSelect';
import axios from 'axios';
import Select from 'react-select';
import BasicTabs from '../ogComponent/muiTabs';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function EditEmployee() {
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            employee_code: "",
            profile_photo: "",
            nationality: "",
            blood_group: "",
            education_level: "",
            password: "",
            gender: "",
            marital_status: "",
            work_experience: "",
            status: "",
            phone_number: "",
            email: "",
            branch: "",
            job_type: "",
            type_of_salary: "",
            department: "",
            job_position: "",
            joined_date: "",
            end_of_probation: "",
            effective_date: "",
            basic_salary: "",
            currency: "",
            benifits: "",
            bonus: "",
            deduction: "",
            city: "",
            state: "",
            pincode: "",
            office_phone: "",
            relative_name: "",
            relation: "",
            rel_phone_number: "",
            address_1: "",
            address_2: "",
            hr_to: [],
            reporting_to: [],
            roles: [],
        },
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    // const [showPassword, setShowPassword] = useState(false);
    const Genoptions = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
    const [branches, setBranches] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [isDepartmentDisabled, setDepartmentDisabled] = useState(true);
    const [jobPositions, setJobPositions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [selectedPosition, setSelectedPosition] = useState({});
    const [selectedJobType, setSelectedJobtype] = useState({});
    const [selectedSalaryType, setSelectedSalaryType] = useState({});
    const [isJobPositionDisabled, setJobPositionDisabled] = useState(true);
    const [hrPositions, setHrpositions] = useState([]);
    const [reportingOptions, setReportingOptions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jobTypes, setJobTypes] = useState([]);
    const [salaryTypes, setSalaryTypes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [employeeData, setEmployeeData] = useState(null);
    const { emp_id } = useParams();
    const [error, setError] = useState(null);
    const token = localStorage.getItem("accessToken");
    const toast = useRef(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get(
                    `https://hrmsbackend.prosevo.com/api/account/users-list/${emp_id}/`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setEmployeeData(response.data);
                console.log("emp is", response.data);
            } catch (error) {
                console.error("Error fetching employee data:", error);
            }
        };

        if (emp_id) {
            fetchEmployeeData();
        }
    }, [emp_id]);

    useEffect(() => {
        if (employeeData) {
            setValue('name', employeeData.name || "");
            setValue('employee_code', employeeData.employee_code || "")
            setValue('email', employeeData.email || "");
            setValue('phone_number', employeeData.phone_number || "");
            setValue('nationality', employeeData.nationality || "");
            setValue('gender', employeeData.gender || "");
            setValue('blood_group', employeeData.blood_group || "")
            setValue('education_level', employeeData.education_level || "")
            setValue('marital_status', employeeData.marital_status || "");
            setValue('work_experience', employeeData.work_experience || "");
            setValue('status', employeeData.status ? "Active" : "Inactive");
            setValue('joined_date', employeeData.joined_date || "")
            setValue('end_of_probation', employeeData.end_of_probation || "")
            setValue('effective_date', employeeData.effective_date || "")
            setValue('currency', employeeData.currency || "")
            setValue('benifits', employeeData.benifits || "")
            setValue('bonus', employeeData.bonus || "")
            setValue('deduction', employeeData.deduction || "")
            setValue('city', employeeData.city || "")
            setValue('state', employeeData.state || "")
            setValue('pincode', employeeData.pincode || "")
            setValue('office_phone', employeeData.office_phone || "")
            setValue('relative_name', employeeData.relative_name || "")
            setValue('relation', employeeData.relation || "")
            setValue('rel_phone_number', employeeData.rel_phone_number || "")
            setValue('address_1', employeeData.address_1 || "")
            setValue('address_2', employeeData.address_2 || "");
            setValue('basic_salary', employeeData.basic_salary || "");
            setSelectedImage(employeeData.profile_photo);

            handleSelectFields(employeeData);
        }
    }, [employeeData, setValue, emp_id, loading]);

    const handleSelectFields = (employeeData) => {
        if (branches.length) {
            const selectedBranch = branches.find(branch => branch.id === employeeData.branch?.id);
            console.log("selected Branch", selectedBranch)
            if (selectedBranch) {
                // handleBranchChange(selectedBranch.id)
                setValue('branch', { value: selectedBranch.id, label: selectedBranch.name });
                setSelectedBranch({ value: selectedBranch.id, label: selectedBranch.name })
            }
        }

        if (jobTypes.length) {
            // console.log("job_types",jobTypes)
            const selectedJobType = jobTypes.find(type => type.value === employeeData.job_type?.id);
            setSelectedJobtype(selectedJobType)
            if (selectedJobType) {
                setValue('job_type', { value: selectedJobType.id, label: selectedJobType.name });
            }
        }

        if (salaryTypes.length) {
            const selectedSalaryType = salaryTypes.find(type => type.id === employeeData.type_of_salary?.id);
            setSelectedSalaryType(selectedSalaryType)
            if (selectedSalaryType) {
                setValue('type_of_salary', { value: selectedSalaryType.id, label: selectedSalaryType.name });
            }
        }

        if (hrPositions.length) {
            const selectedHr = hrPositions.filter(hr => employeeData.hr_to.some(item => item === hr.value));
            setValue('hr_to', selectedHr);
        }

        if (reportingOptions.length) {
            const selectedReporting = reportingOptions.filter(rep => employeeData.reporting_to.some(item => item.id === rep.value));
            setValue('reporting_to', selectedReporting);
        }

        if (roles.length) {
            const selectedRoles = roles.filter(role => employeeData.roles.some(item => item.id === role.value));
            setValue('roles', selectedRoles);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const config = { headers: { Authorization: `Bearer ${token}` } };

                const [branchesResponse, rolesResponse, jobTypesResponse, salaryTypesResponse, hrPositionResponse] = await Promise.all([
                    axios.get("https://hrmsbackend.prosevo.com/api/employee/branches/", config),
                    axios.get("https://hrmsbackend.prosevo.com/api/common/roles/", config),
                    axios.get("https://hrmsbackend.prosevo.com/api/employee/jobtypes/", config),
                    axios.get("https://hrmsbackend.prosevo.com/api/employee/salarytypes/", config),
                    axios.get("https://hrmsbackend.prosevo.com/api/common/hrroles/list", config),
                ]);

                setBranches(branchesResponse.data.map(branch => ({ name: `${branch.branch_name} - ${branch.location}`, id: branch.id })));
                setRoles(rolesResponse.data.map(role => ({ value: role.id, label: role.role_name })));
                setJobTypes(jobTypesResponse.data.map(item => ({ value: item.id, name: item.job_type })));
                setSalaryTypes(salaryTypesResponse.data.map(item => ({ value: item.id, name: item.type_of_salary })));
                // Handling the new hr roles format
                const hrRoles = hrPositionResponse.data.map(hrRole => {
                    return hrRole.hr_role_details.map(detail => ({
                        value: detail.id,
                        label: `${detail.name}`
                    }));
                }).flat(); // Flatten the array as hr_role_details is nested within each hrRole

                setHrpositions(hrRoles);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError('Failed to fetch necessary data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, employeeData]);

    console.log("setJobTypes", jobTypes)

    // Fetch the reporting options from the API
    useEffect(() => {
        const fetchReportingOptions = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${token}` } };
                const response = await axios.get("https://hrmsbackend.prosevo.com/api/account/users-list/", config);
                setReportingOptions(
                    response.data.map(user => ({
                        value: user.id,
                        label: user.name,
                    }))
                );
            } catch (error) {
                console.error("Error fetching reporting options:", error);
                setError("Failed to fetch reporting options. Please try again later.");
            }
        };

        fetchReportingOptions();
    }, [token]);

    useEffect(
        () => {

            const handleBranchChange = async (branchId) => {
                // setValue("branch", branchId);
                setDepartmentDisabled(true);
                setJobPositionDisabled(true);
                setDepartments([]);
                setJobPositions([]);

                try {
                    const response = await axios.get(
                        `https://hrmsbackend.prosevo.com/api/employee/departments/?branches=${branchId}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    console.log("branch responce", response)
                    const departments = response.data.map(dept => ({ name: dept.dep_name, id: dept.id }))
                    setDepartments(departments);
                    if (departments.length) {
                        const selectedDept = departments.find(dept => dept.id === employeeData.department?.id);
                        if (selectedDept) {
                            // handleDepartmentChange(selectedDept.id)
                            setValue('department', { value: selectedDept.id, label: selectedDept.name });
                            setSelectedDepartment({ value: selectedDept.id, label: selectedDept.name })
                        }
                    }
                    setDepartmentDisabled(false);
                } catch (error) {
                    console.error("Error fetching departments:", error);
                    setError('Failed to fetch departments. Please try again later.');
                }
            };

            if (selectedBranch?.value) {
                handleBranchChange(selectedBranch?.value)
            }

        }, [selectedBranch?.value]
    )



    useEffect(() => {

        const handleDepartmentChange = async (deptId) => {
            // setValue("department", deptId);
            setJobPositionDisabled(true);
            setJobPositions([]);

            try {
                const response = await axios.get(
                    `https://hrmsbackend.prosevo.com/api/employee/jobpositions/?department=${deptId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                console.log("jobpositions is", response)
                const jobPositions = (response.data.map(pos => ({ value: pos.id, name: pos.job_title })));
                setJobPositions(jobPositions)
                setJobPositionDisabled(false);
                if (jobPositions.length) {
                    console.log("inside positions", jobPositions)
                    const selectedPosition = jobPositions.find(pos => pos.value === employeeData.job_position?.id);
                    console.log("selected position", selectedPosition);
                    if (selectedPosition) {
                        setValue('job_position', { value: selectedPosition.id, label: selectedPosition.name });
                        setSelectedPosition({ value: selectedPosition.id, label: selectedPosition.name })
                    }
                }
            } catch (error) {
                console.error("Error fetching job positions:", error);
                setError('Failed to fetch job positions. Please try again later.');
            }
        };

        if (selectedDepartment?.value) {
            handleDepartmentChange(selectedDepartment?.value)
        }

    }, [selectedDepartment?.value])

    console.log("hr", hrPositions)

    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    const handleReset = () => {
        reset();
        setSelectedImage(null);
    };

    // if (employeeData) {
    //     handleSelectFields(employeeData);
    // }

    const SelectStyle = {
        control: (base, state) => ({
          ...base,
          minHeight: '38px', // Standard height for the control
          maxHeight: '38px', // Fixed maximum height
          overflow: 'hidden' // Prevent control from expanding
        }),
        valueContainer: (base) => ({
          ...base,
          maxHeight: '38px',
          overflow: 'auto',
          flexWrap: 'nowrap',
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          padding: '2px 8px'
        }),
        multiValue: (base) => ({
          ...base,
          flexShrink: 0 // Prevent values from shrinking
        }),
        input: (base) => ({
          ...base,
          margin: 0,
          padding: 0
        })
      };

      const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const formData = new FormData();
            
            // Basic Information
            formData.append('name', data.name || '');
            formData.append('employee_code', data.employee_code || '');
            formData.append('nationality', data.nationality || '');
            formData.append('blood_group', data.blood_group || '');
            formData.append('education_level', data.education_level || '');
            formData.append('gender', data.gender || '');
            formData.append('marital_status', data.marital_status || '');
            formData.append('work_experience', data.work_experience || '');
            formData.append('status', data.status === "Active" ? true : false);
            formData.append('phone_number', data.phone_number || '');
            formData.append('email', data.email || '');
    
            // Handle Image
            if (imageFile) {
                formData.append('profile_photo', imageFile);
            }
    
            // Company Related Information
            formData.append('branch', data.branch?.value || '');
            formData.append('job_type', data.job_type?.value || '');
            formData.append('type_of_salary', data.type_of_salary?.value || '');
            formData.append('department', data.department?.value || '');
            formData.append('job_position', data.job_position?.value || '');
    
            // Dates
            formData.append('joined_date', data.joined_date || '');
            formData.append('end_of_probation', data.end_of_probation || '');
            formData.append('effective_date', data.effective_date || '');
    
            // Salary Information
            formData.append('basic_salary', data.basic_salary || '');
            formData.append('currency', data.currency || '');
            formData.append('benifits', data.benifits || '');
            formData.append('bonus', data.bonus || '');
            formData.append('deduction', data.deduction || '');
    
            // Address Information
            formData.append('city', data.city || '');
            formData.append('state', data.state || '');
            formData.append('pincode', data.pincode || '');
            formData.append('office_phone', data.office_phone || '');
            formData.append('address_1', data.address_1 || '');
            formData.append('address_2', data.address_2 || '');
    
            // Emergency Contact Information
            formData.append('relative_name', data.relative_name || '');
            formData.append('relation', data.relation || '');
            formData.append('rel_phone_number', data.rel_phone_number || '');
    
            // Handle Arrays
            // HR To
            if (data.hr_to && Array.isArray(data.hr_to)) {
                data.hr_to.forEach((hr, index) => {
                    formData.append(`hr_to[${index}]`, hr.value);
                });
            }
    
            // Reporting To
            if (data.reporting_to && Array.isArray(data.reporting_to)) {
                data.reporting_to.forEach((report, index) => {
                    formData.append(`reporting_to[${index}]`, report.value);
                });
            }
    
            // Roles
            if (data.roles && Array.isArray(data.roles)) {
                data.roles.forEach((role, index) => {
                    formData.append(`roles[${index}]`, role.value);
                });
            }
    
            // Log formData entries for debugging (optional)
            // for (let pair of formData.entries()) {
            //     console.log(pair[0] + ': ' + pair[1]);
            // }
    
            const response = await axios.put(
                `https://hrmsbackend.prosevo.com/api/account/users/${emp_id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
    
            if (response.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Employee updated successfully',
                    life: 3000,
                });
                
                // Navigate to the employee list page after successful update
                navigate('/user/emp');
            }
        } catch (error) {
            console.error('Error updating employee:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response?.data?.message || 'Failed to update employee',
                life: 3000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSelectImage = () => {
        document.getElementById("employee-image-input").click();
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setImageFile(file); // Store the actual file

            // Create preview
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedImage(null);
            setImageFile(null);
        }
    };

    return (
        <>
            <div className='editEmpContainer w-100 h-100 px-lg-5 py-lg-4 p-sm-1'>
                <Toast ref={toast} position="bottom-right" />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formContainer w-100 h-100 d-flex gap-4 flex-column"
                    style={{ backgroundColor: "#5853b70d" }}
                >
                    <div className="firstContainer w-100 d-flex justify-content-center align-items-center">
                        <div className="imageUploaderside d-flex justify-content-center align-items-center">
                            <div className="image-uploader">
                                {selectedImage ? (
                                    <div className="selected-image-container">
                                        <img
                                            src={selectedImage}
                                            alt="Selected Employee Img"
                                            className="selected-image"
                                        />
                                    </div>
                                ) : (
                                    <div className="upload-image-container">
                                        <img
                                            src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                                            alt=""
                                            className="w-100 h-100"
                                        />
                                    </div>
                                )}
                                <button
                                    type="button"
                                    className="select-image-button"
                                    onClick={handleSelectImage}
                                >
                                    <i className="pen-icon fas fa-pen"></i>
                                    Select Image
                                </button>
                                <input
                                    type="file"
                                    id="employee-image-input"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                />
                            </div>
                        </div>
                        <div className="dataSide d-grid w-100">

                            <div className="SingleData">
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            className="form-control"
                                            label="Name"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Reni Thomas"
                                            error={!!error}
                                            helperText={error?.message}
                                            InputProps={{ sx: { height: "35px" } }}
                                        // style={{fontSize:'clamp(12px, 1.1vw, 13px)'}}
                                        />
                                    )}
                                />
                            </div>

                            <div className="SingleData">
                                <Controller
                                    name="employee_code"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            className="form-control"
                                            label="Emp Code"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0001"
                                            error={!!error}
                                            helperText={error?.message}
                                            InputProps={{ sx: { height: "35px" } }}
                                        // style={{fontSize:'clamp(12px, 1.1vw, 13px)'}}
                                        />
                                    )}
                                />
                            </div>

                            <div className="SingleData">
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: /^\+?[1-9]\d{1,14}$/,
                                            message: "Invalid phone number",
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            type="tel"
                                            className="form-control"
                                            label="Phone Number"
                                            variant="outlined"
                                            size="small"
                                            placeholder="+91 1234567890"
                                            error={!!error}
                                            helperText={error?.message}
                                            InputProps={{ sx: { height: "35px" } }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="SingleData">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address",
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            type="email"
                                            className="form-control"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            placeholder="abcd@gmail.com"
                                            error={!!error}
                                            helperText={error?.message}
                                            InputProps={{ sx: { height: "35px" } }}
                                        />
                                    )}
                                />
                            </div>

                            {/* <div className="SingleData">
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: true,
                                        minLength: {
                                            value: 8,
                                            message: "min 8 letter",
                                        },
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: "must contain 1 letter & 1 number",
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Enter your password"
                                            error={!!error}
                                            helperText={error?.message}
                                            InputProps={{
                                                sx: { height: "35px" },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={togglePasswordVisibility}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div> */}

                            <div className="SingleData">
                                <Controller
                                    name="gender"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                        <MuiSelect
                                            {...field}
                                            label="Gender"
                                            options={Genoptions}
                                            className={`bg-white z-index-10`}
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </div>

                            {/* Branch Select */}
                            <div className="SingleData">
                                <Controller
                                    name="branch"
                                    control={control}
                                    render={({ field: { onChange, value, ...rest } }) => (
                                        <Select
                                            {...rest}
                                            // value={branches.find(option => option.value === value?.value) || null}
                                            options={branches.map(branch => ({ value: branch.id, label: branch.name }))}
                                            isDisabled={loading}
                                            onChange={(selectedOption) => {
                                                onChange(selectedOption);
                                                // handleBranchChange(selectedOption.value);
                                                setSelectedBranch(selectedOption)
                                            }}
                                            value={selectedBranch}
                                        // getOptionLabel={(option) => option.label}
                                        // getOptionValue={(option) => option.value}
                                        />
                                    )}
                                />
                            </div>

                            {/* Department Select */}
                            <div className="SingleData">
                                <Controller
                                    name="department"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, ...rest } }) => (
                                        <Select
                                            {...rest}
                                            // label="Department"
                                            options={departments.map(dept => ({ value: dept.id, label: dept.name }))}
                                            className="bg-white"
                                            // helperText={error?.message}
                                            disabled={isDepartmentDisabled}
                                            onChange={(selectedOption) => {
                                                onChange(selectedOption);
                                                // handleDepartmentChange(selectedOption.value);
                                                setSelectedDepartment(selectedOption)
                                            }}
                                            value={selectedDepartment}
                                        />
                                    )}
                                />
                            </div>

                            {/* Job Position Select */}
                            <div className="SingleData">
                                <Controller
                                    name="job_position"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, ...rest } }) => (
                                        <Select
                                            {...rest}
                                            label="Job Position"
                                            options={jobPositions.map(position => ({ value: position.value, label: position.name }))}
                                            className="bg-white"
                                            disabled={isJobPositionDisabled}
                                            onChange={(position) => {
                                                setValue("job_position", { value: position.id, label: position.name })
                                                setSelectedPosition(position)
                                            }}
                                            value={selectedPosition}
                                        />
                                    )}
                                />
                            </div>

                            <div className="SingleData">
                                <Controller
                                    name="hr_to"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={hrPositions}
                                            className={`bg-white z-index-10 ${errors.hr_to ? "error-border" : ""}`}
                                            isMulti
                                            placeholder="Hr to"
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            styles={SelectStyle}
                                        />
                                    )}
                                />

                            </div>

                            <div className="SingleData">
                                <Controller
                                    name="reporting_to"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={reportingOptions}
                                            className={`bg-white z-index-10 ${errors.reporting_to ? "error-border" : ""
                                                }`}
                                            isMulti
                                            placeholder="Reporting To"
                                            getOptionLabel={(option) => option.label} // Ensure label property exists
                                            getOptionValue={(option) => option.value} // Ensure value property exists
                                            styles={SelectStyle}
                                        />
                                    )}
                                />
                            </div>

                            <div className="SingleData rolesField">
                                <Controller
                                    name="roles"
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={roles}
                                            className={`bg-white z-index-10 ${errors.roles ? "error-border" : ""
                                                }`}
                                            isMulti
                                            placeholder="Select Roles"
                                            styles={SelectStyle}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="containerCollapse w-100 d-flex flex-column gap-md-4">
                        <BasicTabs
                            tabs={["Personal Details", "Job Details", "Contact Details"]}
                            control={control}
                            jobTypes={jobTypes}
                            salaryTypes={salaryTypes}
                            selectedJobType={selectedJobType}
                            selectedSalaryType={selectedSalaryType}
                            type={"edit"}
                        />
                    </div>
                    <div className="fifthContainer d-flex justify-content-end me-3 gap-md-4 gap-2 pe-md-5 pe-2">
                        <button
                            type="button"
                            className="btn text-white btn-success"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button
                            type="submit"
                            className="btn text-white d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "#120e4d", minWidth: "120px", height: "38px" }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                    {/* <span>Loading...</span> */}
                                </>
                            ) : (
                                "Update"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}
