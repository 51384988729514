import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useParams } from "react-router-dom";

export default function SalarySlip() {
    const [selectedYear, setSelectedYear] = useState(null);
    const navigate = useNavigate();
    const { emp_id } = useParams();

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
    };

    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let i = currentYear; i >= 2000; i--) {
        yearOptions.push({ value: i, label: i });
    }

    // Navigate to individual salary slip
    function singleSlipView(id) {
        navigate(`/user/salaryslip/${emp_id}/${id}`);
    }

    // Sample card data for demonstration
    const cards = [
        { id: 1, title: 'January', content: 'This is the content for January' },
        { id: 2, title: 'February', content: 'This is the content for February' },
        { id: 3, title: 'March', content: 'This is the content for March' },
        { id: 4, title: 'April', content: 'This is the content for April' },
        { id: 5, title: 'May', content: 'This is the content for May' },
        { id: 6, title: 'June', content: 'This is the content for June' },
        { id: 7, title: 'July', content: 'This is the content for July' },
        { id: 8, title: 'August', content: 'This is the content for August' },
        { id: 9, title: 'September', content: 'This is the content for September' },
        { id: 10, title: 'October', content: 'This is the content for October' },
        { id: 11, title: 'November', content: 'This is the content for November' },
        { id: 12, title: 'December', content: 'This is the content for December' }
    ];

    return (
        <>
            <div className='px-4 py-4'>
                <div className='mb-3'>
                    <h4>Salary Slip</h4>
                </div>
                <div style={{ width: '200px' }}>
                    <Select
                        id="salary-year"
                        value={selectedYear}
                        onChange={handleYearChange}
                        options={yearOptions}
                        placeholder="Select Year"
                        className="border rounded"
                        style={{ width: '200px' }}
                    />
                </div>

                <div className="slipCardContainer mt-4">
                    <div className="row">
                        {cards.map((card) => (
                            <div key={card.id} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
                                <div className="card" style={{ height: '200px' }}>
                                    <div
                                        className="card-body h-100 d-flex justify-content-center align-items-center flex-column"
                                        style={{
                                            background: 'url(/assets/loginBackground.jpg) no-repeat center center',
                                            backgroundSize: 'cover',
                                            height: '100%',  // Ensure the background covers the full height
                                            cursor: 'pointer'
                                        }}
                                        // Corrected onClick handler
                                        onClick={() => singleSlipView(card.id)}
                                    >
                                        <h5 className="card-title">{card.title}</h5>
                                        <button className='btn btn-success'>Download</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
