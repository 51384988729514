import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Select, MenuItem, FormControl, Modal, Box } from "@mui/material";
import Table from "../ogComponent/table";
import Spinner2 from "../ogComponent/Spinner2";
import CustomModal from "../ogComponent/modal";
import { Toast } from 'primereact/toast';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconEdit, IconTrash } from "@tabler/icons-react";

const DepartmentDetails = () => {
  const toast = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [department, setDepartment] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState('All Positions');
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [jobPosition, setJobposition] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [branchoptions, setBranchoptions] = useState([]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px',
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "name", label: "Name", active: true },
    { name: "job_position", label: "Job Position", active: true },
    { name: "contact_info", label: "Contact Info", active: true },
    { name: "Status", label: "Active", active: true },
  ];

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleRowClick = (item) => {
    navigate(`/user/empdetails/${item.id}`);
  };

  const formatText = (text) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`https://hrmsbackend.prosevo.com/api/employee/jobpositions/?department=${id}`, {
          headers: {
            Authorization: `Bearer ${token}` // Bearer token is added to the request header
          }
        });
        setJobposition(response.data); // Set the fetched data to departments
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    if (id) {
      fetchDepartments(); // Call the function to fetch data when component mounts
    }
  }, [id]);


  const handleDeleteClick = () => {
    if (department.employee_count > 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Cannot Delete Branch',
        detail: "This branch contains employees. Please remove employees before deleting.",
        life: 3000,
      });
    } else {
      setOpenDeleteModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/employee/departments/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenDeleteModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: "Department Deleted Successfully",
        life: 3000,
      });

      setTimeout(() => {
        navigate('/user/deps/');
      }, 3000);

    } catch (error) {
      console.error("Error deleting department:", error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: "Failed to delete department",
        life: 3000,
      });
    }
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const handleEditSubmit = async (data) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.put(
        `https://hrmsbackend.prosevo.com/api/employee/departments/${id}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDepartment(response.data);
      setShowEditModal(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Department Updated Successfully",
        life: 3000,
      });
    } catch (error) {
      console.error("Error updating department details:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to update department",
        life: 3000,
      });
    }
  };


  const editModalFields = [
    {
      name: "dep_name",
      label: "Department Name",
      type: "text",
      value: formatText(department?.dep_name),
      required: true,
    },
    {
      name: "dep_head",
      label: "Department Head",
      type: "text",
      value: formatText(department?.dep_head),
      required: true,
    },
    {
      name: "branches",
      label: "Branches",
      type: "multiSelect",
      options: branchoptions || [],  // Assuming branchoptions is an array of all available branches
      value: department?.branches_details?.map(branch => ({
        value: branch.id,
        label: `${branch.name} - ${branch.location}`,
      })) || [],  // Pre-select branches based on fetched data
      required: true,
    },
    {
      name: "contact_info",
      label: "Contact Info",
      type: "text",
      value: department?.contact_info,
      required: true,
    },
  ];



  console.log("modal feilds is", editModalFields)

  useEffect(() => {
    const fetchDepartmentDetails = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/employee/departments/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartment(response.data);
      } catch (error) {
        console.error("Error fetching department details:", error);
      }
    };

    const fetchBranches = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get('https://hrmsbackend.prosevo.com/api/employee/branches/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const options = response.data.map(branch => ({
          value: branch.id,
          label: `${branch.branch_name} - ${branch.location}`,
        }));
        setBranchoptions(options);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchDepartmentDetails();
    fetchBranches();
  }, [id]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("accessToken");
      setLoading(true);
      try {
        const positionParam =
          selectedPosition !== "All Positions"
            ? `&job_position=${selectedPosition}`
            : "";
        const response = await axios.get(
          `https://hrmsbackend.prosevo.com/api/account/users-list/?department=${id}${positionParam}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFilteredEmployees(response.data || []);
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [id, selectedPosition]);

  // Add selectedPosition to the dependency array
  const transformEmployeeData = (employees) => {
    return employees.map((employee, index) => ({
      id: employee.id,
      "Si No": index + 1,
      name: (
        <td
          className="d-flex gap-3 align-items-center px-0"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img
            style={{
              width: "25px",
              height: "25px",
              flexShrink: 0,
              borderRadius: "50%",
            }}
            src={
              employee.profile_photo
                ? employee.profile_photo
                : "/assets/user.png"
            }
            alt="user img"
          />
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {employee.name || "N/A"}
          </div>
        </td>
      ),
      job_position: employee.job_position?.job_title || "N/A",
      contact_info: employee.phone_number || "N/A",
      Status: (
        <div style={{ color: employee.status ? "green" : "tomato" }}>
          <span
            style={{
              display: "inline-block",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: employee.status ? "green" : "tomato",
              marginRight: "5px",
            }}
          ></span>
          {employee.status ? "Active" : "Inactive"}
        </div>
      ),
    }));
  };

  const handlePositionchChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  if (loading) {
    return <Spinner2 />;
  }

  return (
    <>
      <style>
        {`
        @media (max-width: 500px) {
          .modalmainbox {
            min-width: 75% !important;
          }
        }
      `}
      </style>
      <div className="singleDepartmentContainer px-md-5 px-3 pt-4 w-100">
        <Toast ref={toast} position="bottom-right" />
        {department ? (
          <>
            <h4>{department.dep_name}</h4>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Department Head:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {department.dep_head}
              </span>
            </p>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Contact Info:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {department.contact_info}
              </span>
            </p>
            <p
              className="mb-0 fw-bolder"
              style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
            >
              Total Employees:{" "}
              <span
                className="ms-2 fw-lighter"
                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
              >
                {department.employee_count}
              </span>
            </p>

            <div className="employeeTableContainer w-100">
              <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  <Select
                    value={selectedPosition}
                    onChange={handlePositionchChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="All Positions">All Positions</MenuItem>
                    {jobPosition.map((position) => (
                      <MenuItem key={position.id} value={position.id}>
                        {position.job_title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <button
                  className="btn btn-outline-primary"
                  onClick={handleEditClick}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <IconEdit stroke={2} />
                </button>
                <button className="btn btn-outline-danger" onClick={handleDeleteClick}>
                  <IconTrash stroke={2} />
                </button>

              </div>
              {filteredEmployees.length > 0 ? (
                <Table
                  header={header}
                  product={transformEmployeeData(filteredEmployees)}
                  onRowClick={handleRowClick}
                />
              ) : (
                <p>No employees found in this department.</p>
              )}
            </div>
          </>
        ) : (
          <p>Department not found.</p>
        )}
        <CustomModal
          heading="Edit Department Data"
          fields={editModalFields}
          onSubmit={handleEditSubmit}
          type={"branch"}
        />
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ outline: "none" }}
        >
          <Box
            sx={{ ...modalStyle, outline: "none", padding: "25px" }}
            className="modalmainbox"
          >
            <div className="w-100 d-flex align-items-center flex-column">
              <WarningAmberIcon
                sx={{ color: "#FFA000", fontSize: 60, width: "100%" }}
              />
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "clamp(13px, 1.4vw, 17px)",
                  mb: 2,
                }}
              >
                Confirm Delete
              </Box>
              <Box
                sx={{
                  color: "#555",
                  mb: 2,
                  fontSize: "clamp(12px, 1.2vw, 14px)",
                  textWrap: "wrap",
                  textAlign: "center",
                  paddingX: "10px",
                }}
              >
                Are you sure you want to delete this department?
              </Box>
            </div>
            <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
              <button
                className="btn btn-danger"
                onClick={handleConfirmDelete}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleCloseDeleteModal}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default DepartmentDetails;