import React from "react";
import "./profilePage.css";

export const ProfilePage = () => {
  return (
    <>
      <div className="profileContainer w-100 p-3 p-md-4">
         {/* leaves show columns  */}
    <div class="displayContainer d-flex gap-2 gap-md-4 mb-0 overflow-auto flex-wrap justify-content-center mb-3 mb-md-4">
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey"}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#eaedff'}}><i class="bi bi-people" style={{fontSize:'25px', color:'blue'}}></i></div>   
          </div>
          <div className="textHead d-flex h-100">
            <div className="headerText">Total Days</div>
            <div className="countText" style={{ fontSize: "clamp(20px, 2vw, 30px)", fontWeight: "700"}}>176</div>
          </div>
        </div>
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey"}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#e3fdd9' }}><i class="bi bi-person-check" style={{fontSize:'25px', color:'green'}}></i></div>   
          </div>
          <div className="textHead d-flex  h-100">
            <p className="headerText">Attendence</p>
            <p className="countText" style={{fontSize: "clamp(20px, 2vw, 30px)", fontWeight: "700"}}>92%</p>
          </div>
        </div>
        <div
          class="col p-0 overflow-hidden d-flex align-items-center"
          style={{border: "2px solid grey"}}>
          <div className="IconParent h-100  d-flex">
            <div className="mainIcon d-flex justify-content-center align-items-center" style={{backgroundColor:'#fde9cf'}}><i class="bi bi-person-x" style={{fontSize:'25px', color:'orange'}}></i></div>   
          </div>
          <div className="textHead d-flex h-100" >
            <div className="headerText">Leaves</div>
            <div className="countText" style={{fontSize: "clamp(20px, 2vw, 30px)", fontWeight: "700"}}>13</div>
          </div>
        </div>
            </div>
        <div className="profileGrid d-grid w-100">
        <div className="col-1 w-100 border border-2 border-secondary-subtle p-2 p-md-4 rounded-3">
          <div className="proImage pb-3 d-flex justify-content-center align-items-center flex-column">
            <img
              src="/assets/profilePic.png"
              alt="profilepicture"
              className="mb-2"
              style={{ width: "200px", borderRadius: "15px" }}
            />
            <h5 className="text-nowrap">Aroura Georgina</h5>
            <h6>Mern Stack Dev</h6>
          </div>
          <div className="proData w-100">
            {/* <div className="commonData">
              <p>Phone :</p>
              <h6>+91 9876543210</h6>
            </div>
            <div className="commonData">
              <p>Branch :</p>
              <h6>Vengara</h6>
            </div> */}
            <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Personal Details
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                 <div className="commonData">
              <p>Phone :</p>
              <h6>+91 9876543210</h6>
            </div>
            <div className="commonData">
              <p>Branch :</p>
              <h6>Vengara</h6>
            </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Job Details
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                 <div className="commonData">
              <p>Phone :</p>
              <h6>+91 9876543210</h6>
            </div>
            <div className="commonData">
              <p>Branch :</p>
              <h6>Vengara</h6>
            </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Contact Details
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className="commonData">
              <p>Phone :</p>
              <h6>+91 9876543210</h6>
            </div>
            <div className="commonData">
              <p>Branch :</p>
              <h6>Vengara</h6>
            </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-2 w-100 border">
          others
        </div>
        <div className="col-3 w-100 border">datas</div>
      </div>
      </div>
    </>
  );
};
