// SubjectDetails.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';  // <-- useNavigate instead of useHistory
import { Toast } from 'primereact/toast';
import CustomAccordion from './CustomAccordion';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';

export default function SubjectDetails() {
    const toast = useRef(null);
    const navigate = useNavigate();  // <-- useNavigate hook
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [formData, setFormData] = useState({
        task_name: '',
        task_description: '',
        refer_link: '',
        total_mark: '',
        time_duration: ''
    });

    const Tasks = [
        {
            id: 1,
            task_name: 'Introduction To React',
            course_name: 'Introduction to React',
            Description: 'React introduction',
            student_count: 3,
            course_instructor: { name: 'Instructor A' },
            course_duration: 1,
            total_mark: 100,
            duration: '1 Day',
        },
        {
            id: 2,
            task_name: 'Introduction Video Watch',
            course_name: 'Video Watch - React Intro',
            Description: 'React introduction 2',
            student_count: 2,
            course_instructor: { name: 'Instructor B' },
            course_duration: 1,
            total_mark: 100,
            duration: '1 Day',
        }
    ];

    const handleBodyClick = (id) => {
        navigate(`/user/task/${id}`);  // <-- Use navigate to change the route
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prev) => [...prev, ...files]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Authentication token not found',
                    life: 3000
                });
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach((key) => {
                formDataToSend.append(key, formData[key]);
            });
            selectedFiles.forEach((file) => {
                formDataToSend.append('files', file);
            });

            const response = await fetch('https://hrmsbackend.prosevo.com/api/employee/tasks/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSend
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Task created successfully',
                life: 3000
            });

            setFormData({
                task_name: '',
                task_description: '',
                refer_link: '',
                total_mark: '',
                time_duration: ''
            });
            setSelectedFiles([]);

        } catch (error) {
            console.error('Error submitting form:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to create task',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="singleSubjectContainer px-md-5 px-3 pt-4 w-100">
            <Toast ref={toast} position="bottom-right" />
            <h4>React Course Details</h4>
            <div className="employeeTableContainer w-100">
                <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                    <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Create Task <IconPlus stroke={2} />
                    </button>
                    <button className="btn btn-outline-primary" onClick={() => console.log('Edit clicked')}>
                        <IconEdit stroke={2} />
                    </button>
                    <button className="btn btn-outline-danger" onClick={() => console.log('Delete clicked')}>
                        <IconTrash stroke={2} />
                    </button>
                </div>
            </div>

            {/* Custom Accordion for Tasks */}
            <CustomAccordion items={Tasks} onBodyClick={handleBodyClick} />

            {/* Task Creation Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Task</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Task Name *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="task_name"
                                        value={formData.task_name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Task Description</label>
                                    <textarea
                                        className="form-control"
                                        name="task_description"
                                        value={formData.task_description}
                                        onChange={handleInputChange}
                                        rows="3"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Reference Link</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="refer_link"
                                        value={formData.refer_link}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Attach Files</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={handleFileChange}
                                        multiple
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Total Mark *</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="total_mark"
                                        value={formData.total_mark}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Time Duration (minutes) *</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="time_duration"
                                        value={formData.time_duration}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Create Task</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
