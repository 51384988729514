import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControl, MenuItem, Select, Modal, Box } from '@mui/material';
import Table from '../ogComponent/table';
import CustomModal from "../ogComponent/modal";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Spinner from '../ogComponent/spinner';
import { IconEdit, IconTrash } from '@tabler/icons-react';

export default function ShiftDetails() {
  const [shift, setShift] = useState(null)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { id } = useParams();

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "name", label: "Name", active: true },
    { name: "branch", label: "Branch", active: true },
    { name: "department", label: "Department", active: true },
    { name: "contact_info", label: "Contact Info", active: true },
    { name: "Status", label: "Active", active: true },
  ];

  const editModalFields = [
    {
      name: "shift_name",
      label: "Shift Name",
      type: "text",
      value: shift?.shift_name || "",
      required: true,
    },
    {
      name: "login_time",
      label: "Login Time",
      type: "time",
      value: shift?.login_time || "",
      required: true,
    },
    {
      name: "logout_time",
      label: "Logout Time",
      type: "time",
      value: shift?.logout_time || "",
      required: true,
    },
    {
      name: "time_bound_for_overtime",
      label: "Min Overtime",
      type: "duration",
      value: shift?.time_bound_for_overtime || "",
      required: true,
    },
  ];


  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px',
  };

  useEffect(() => {
    const fetchShiftDetails = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://hrmsbackend.prosevo.com/api/account/shifts/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShift(response.data);
      } catch (error) {
        console.error("Error fetching shift details:", error);
        setError('Error fetching shift details');
      } finally {
        setLoading(false); // Set loading to false after the API call is finished
      }
    };

    fetchShiftDetails();
  }, [id]);
  console.log("shift is", shift)


  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("accessToken");
      setLoading(true);

      try {
        let url = `https://hrmsbackend.prosevo.com/api/account/users-list/?shift=${id}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFilteredEmployees(response.data || []);
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [id]);

  console.log("employees", filteredEmployees)


  const handleEditSubmit = async (data) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.put(
        `https://hrmsbackend.prosevo.com/api/account/shifts/${id}/`,
        data, // Pass the form data to the API
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShift(response.data); // Update shift details with the new data
      setShowEditModal(false);
      toast.current.show({ severity: 'success', summary: 'Success', detail: "Shift Updated Successfully", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: "Failed to update shift", life: 3000 });
    }
  };



  const handleConfirmDelete = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/account/shifts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpenDeleteModal(false);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: "Position Deleted Successfully",
        life: 3000,
      });

      setTimeout(() => {
        navigate('/user/shifts/');
      }, 3000);
    } catch (error) {
      console.error('Error deleting position:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: "Failed to delete position",
        life: 3000,
      });
    }
  };



  const transformEmployeeData = (employees) => {
    if (!employees || !Array.isArray(employees)) return [];
    return employees.map((employee, index) => ({
      id: employee.id,
      "Si No": index + 1,
      name: (
        <td className="d-flex gap-3 align-items-center px-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <img
            style={{ width: "25px", height: "25px", flexShrink: 0, borderRadius: '50%' }}
            src={employee.profile_photo ? employee.profile_photo : "/assets/user.png"}
            alt="user img"
          />
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{employee.name || "N/A"}</div>
        </td>
      ),
      branch: employee.branch?.branch_name || "N/A",
      department: employee.department?.dep_name || "N/A",
      contact_info: employee.phone_number || "N/A",
      Status: (
        <div style={{ color: employee.status ? 'green' : 'tomato' }}>
          <span
            style={{
              display: 'inline-block',
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              backgroundColor: employee.status ? 'green' : 'tomato',
              marginRight: '5px',
            }}
          ></span>
          {employee.status ? 'Active' : 'Inactive'}
        </div>
      ),
    }));
  };

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRowClick = (item) => {
    navigate(`/user/empdetails/${item.id}`);
  };

  const handleDeleteClick = () => {
    if (shift.employee_count > 0) {
      toast.current.show({
        severity: 'warn',
        summary: 'Cannot Delete Branch',
        detail: "This branch contains employees. Please remove employees before deleting.",
        life: 3000,
      });
    } else {
      setOpenDeleteModal(true);
    }
  };


  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <>
      <style>
        {`
          @media (max-width: 500px) {
            .modalmainbox {
              min-width: 75% !important;
            }
          }
        `}
      </style>
      <div className="singlePositionContainer px-4 pt-4 w-100">
        <Toast ref={toast} position="bottom-right" />
        <h4>{shift?.shift_name}</h4>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Start Time: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.login_time}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>End Time: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.logout_time || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Min Overtime: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.time_bound_for_overtime || "N/A"}</span></p>
        <p className='mb-0 fw-bolder' style={{ fontSize: 'clamp(12px, 1.2vw, 13px)' }}>Total Employees: <span className='ms-2 fw-lighter' style={{ fontSize: 'clamp(14px, 1.3vw, 16px)' }}>{shift?.employee_count || "N/A"}</span></p>

        <div className="employeeTableContainer mt-4 w-100">
          <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
            <button
              className="btn btn-outline-primary"
              onClick={handleEditClick}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <IconEdit stroke={2} />
            </button>
            <button className="btn btn-outline-danger" onClick={handleDeleteClick}>
              <IconTrash stroke={2} />
            </button>
            {/* <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <Select
                value={selectedBranch}
                onChange={handleBranchChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="All Branches">All Branches</MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          {filteredEmployees.length > 0 ? (
            <Table
              header={header}
              product={transformEmployeeData(filteredEmployees)}
              onRowClick={handleRowClick}
              loading={loading}
            />
          ) : (
            <p>No employees found in this position.</p>
          )}
        </div>

        <CustomModal
          heading="Edit Position Data"
          fields={editModalFields}
          onSubmit={handleEditSubmit}
          type={"shift"}
        />
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ outline: "none" }}
        >
          <Box
            sx={{ ...modalStyle, outline: "none", padding: "25px" }}
            className="modalmainbox"
          >
            <div className="w-100 d-flex align-items-center flex-column">
              <WarningAmberIcon
                sx={{ color: "#FFA000", fontSize: 60, width: "100%" }}
              />
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "clamp(13px, 1.4vw, 17px)",
                  mb: 2,
                }}
              >
                Confirm Delete
              </Box>
              <Box
                sx={{
                  color: "#555",
                  mb: 2,
                  fontSize: "clamp(12px, 1.2vw, 14px)",
                  textWrap: "wrap",
                  textAlign: "center",
                  paddingX: "10px",
                }}
              >
                Are you sure you want to delete this position?
              </Box>
            </div>
            <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
              <button
                className="btn btn-danger"
                onClick={handleConfirmDelete}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleCloseDeleteModal}
                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>


      </div>
    </>
  )
}
