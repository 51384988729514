import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import MuiSelect from "../ogComponent/muiSelect";

const JobPositionPage = ({ departmentOptions }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const formatText = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

  const onSubmitJobPosition = async (data) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(
        "https://hrmsbackend.prosevo.com/api/employee/jobpositions/",
        {
          job_title: formatText(data.position_name),
          department: data.department.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      reset();
      toast.success("New Job-Position Added!", {
        position: "bottom-right",
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Error saving job position data:", error);
      toast.error("Failed to save Job-Position data. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitJobPosition)}
      className="d-flex justify-content-center align-items-start gap-4 flex-column"
    >
      <Controller
        name="position_name"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Position Name"
            variant="outlined"
            size="small"
            error={!!errors.position_name}
            helperText={errors.position_name?.message}
            className="w-100"
          />
        )}
      />
      <Controller
        name="department"
        control={control}
        defaultValue={null}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <MuiSelect
            {...field}
            label="Department"
            options={departmentOptions}
            className="w-100"
            onChange={(selectedDepartment) => field.onChange(selectedDepartment)}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        className="align-self-end px-4"
      >
        Save <i className="bi bi-check-circle ms-2"></i>
      </Button>
    </form>
  );
};

export default JobPositionPage;
