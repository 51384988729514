import React from "react";
import Notes from "./notes";
import { DemoCalendar } from "./calender";


// import Document from "./document";

export default function RightContainer() {
  return (
    <div className="rightContainer d-flex gap-3 gap-xxl-4 px-3 px-xxl-3 pt-xxl-4 pb-2 pb-md-3 me-0 pt-3 pt-md-4">
        {/* <Document /> */}
        <DemoCalendar />
        <Notes />
    </div>
  );
}
