import { TextareaAutosize, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import MuiSelect from "./muiSelect";
import { Label } from "@mui/icons-material";
import Select from 'react-select';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ control, jobTypes, salaryTypes, selectedJobType, selectedSalaryType, type }) {
  const [value, setValue] = useState(0);
  const [initialJobValue, setInitialJobValue] = useState(null);
  const [initialSalaryValue, setInitialSalaryValue] = useState(null);

  React.useEffect(() => {
    if (type === 'edit' && selectedJobType) {
      const matchedJob = jobTypes.find(job => job.id === selectedJobType.id);
      console.log("matchedJob", matchedJob)
      if (matchedJob) {
        setInitialJobValue({
          value: matchedJob.value,
          label: matchedJob.name
        });
      }
      console.log("initialJobVAlue", initialJobValue)
    }
  }, [type, selectedJobType, jobTypes]);

  React.useEffect(() => {
    if (type === 'edit' && selectedSalaryType) {
      const matchedSalary = salaryTypes.find(salary => salary.id === selectedSalaryType.id);
      if (matchedSalary) {
        setInitialSalaryValue({
          value: matchedSalary.id,
          label: matchedSalary.name
        });
      }
    }
  }, [type, selectedSalaryType, salaryTypes]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currencys = [
    { name: "Rupees" },
    { name: "Dollar" },
    { name: "Riyaal" },
  ];
  const maritalStatus = [
    { name: "Single" },
    { name: "Married" },
    { name: "Other" },
  ];

  const bloodGroup = [
    { name: "A+" },
    { name: "A-" },
    { name: "B+" },
    { name: "B-" },
    { name: "O+" },
    { name: "O-" },
    { name: "AB+" },
    { name: "AB-" },
  ];

  const experiances = [
    { name: "Fresher" },
    { name: "6 Months" },
    { name: "1 Year" },
    { name: "2 Year" },
    { name: "3 Year +" },
    { name: "Above 5 year" },
  ];

  const statusOptions = [
    { name: "Active", value: 'active' },
    { name: "Not Active", value: 'notactive' },
  ];

  // const watchStatus = useWatch({
  //   control,
  //   name: "status",
  //   defaultValue: null,
  // });

  const formatOptionsForReactSelect = (options) => {
    return options.map(option => ({
      value: option.value,
      label: option.name
    }));
  };

  const handleJobTypeChange = (selectedOption, onChange) => {
    console.log("hi", selectedOption)
    const formattedValue = {
      value: selectedOption.id,
      label: selectedOption.label,
      id: selectedOption.value,
      name: selectedOption.label
    };
    onChange(formattedValue);
    setInitialJobValue(formattedValue);
  };

  const handleSalaryTypeChange = (selectedOption, onChange) => {
    const formattedValue = {
      value: selectedOption.id,
      label: selectedOption.label,
      id: selectedOption.value,
      name: selectedOption.label
    };
    onChange(formattedValue);
    setInitialSalaryValue(formattedValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            minHeight: "35px",
            "& .MuiTab-root": {
              minHeight: "35px",
              height: "35px",
              padding: "0 16px",
              fontSize: 'clamp(12px, 1.2vw, 12px)'
            },
          }}
        >
          <Tab label="Personal Details" {...a11yProps(0)} />
          <Tab label="Job Details" {...a11yProps(1)} />
          <Tab label="Contact Details" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="collapseContainer d-grid mt-4">
          <div className="SingleData">
            <Controller
              name="nationality"
              control={control}
              // rules={{ required: "Nationality is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Nationality"
                  variant="outlined"
                  size="small"
                  placeholder="Indian"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            />
          </div>
          <div className="SingleData">
            <Controller
              name="blood_group"
              control={control}
              // rules={{ required: "Blood group is required" }}
              render={({ field, fieldState: { error } }) => (
                <MuiSelect
                  {...field}
                  label="Blood Group"
                  options={bloodGroup}
                  className="bg-white"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select Blood Group"
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="marital_status"
              control={control}
              // rules={{ required: "Marital status is required" }}
              render={({ field, fieldState: { error } }) => (
                <MuiSelect
                  {...field}
                  label="Marital Status"
                  options={maritalStatus}
                  className="bg-white"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="education_level"
              control={control}
              // rules={{ required: "Education level is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Educational Level"
                  variant="outlined"
                  size="small"
                  placeholder="Bachelor's Degree"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="work_experience"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MuiSelect
                  {...field}
                  label="Experience"
                  options={experiances}
                  className="bg-white"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select Experience"
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="status"
              control={control}
              defaultValue={null}
              render={({ field, fieldState: { error } }) => (
                <MuiSelect
                  {...field}
                  label="Status"
                  options={statusOptions}
                  className="bg-white"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            /></div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="collapseContainer d-grid mt-4">
          <div className="SingleData">
            <Controller
              name="job_type"
              control={control}
              defaultValue={initialJobValue}
              render={({ field, fieldState: { error } }) => (
                type === 'edit' ? (
                  <Select
                    {...field}
                    className="basic-single"
                    classNamePrefix="select"
                    // isDisabled={!canEdit}
                    options={formatOptionsForReactSelect(jobTypes)}
                    value={initialJobValue}
                    onChange={(selectedOption) => handleJobTypeChange(selectedOption, field.onChange)}
                    placeholder="Select Job Type"
                    isSearchable={true}
                  />
                ) : (
                  <MuiSelect
                    {...field}
                    label="Job Type"
                    options={jobTypes}
                    className="bg-white"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="Select Job Type"
                    getOptionLabel={(option) => option.name || ''}
                    getOptionValue={(option) => option.id}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )
              )}
            />
          </div>
          <div className="SingleData">
            <Controller
              name="joined_date"
              control={control}
              // rules={{ required: "Joining date is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="date"
                  className="form-control"
                  label="Joining Date"
                  variant="outlined"
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="end_of_probation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="date"
                  className="form-control"
                  label="End of Probation"
                  variant="outlined"
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            />
          </div>
          <div className="SingleData">
            <Controller
              name="effective_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="date"
                  className="form-control"
                  label="Effective Date"
                  variant="outlined"
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="basic_salary"
              control={control}
              rules={{
                // required: "Basic salary is required",
                pattern: {
                  value: /^\d+$/,
                  message: "Please enter a valid number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  className="form-control"
                  label="Basic Salary"
                  variant="outlined"
                  size="small"
                  placeholder="14990"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="type_of_salary"
              control={control}
              defaultValue={initialSalaryValue}
              render={({ field, fieldState: { error } }) => (
                type === 'edit' ? (
                  <Select
                    {...field}
                    className="basic-single"
                    classNamePrefix="select"
                    // isDisabled={!canEdit}
                    options={formatOptionsForReactSelect(salaryTypes)}
                    value={initialSalaryValue || null}
                    onChange={(selectedOption) => handleSalaryTypeChange(selectedOption, field.onChange)}
                    placeholder="Select Salary Type"
                    isSearchable={true}
                    // isClearable={true}
                  />
                ) : (
                  <MuiSelect
                    {...field}
                    label="Salary Type"
                    options={salaryTypes}
                    className="bg-white"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="Select Salary Type"
                    getOptionLabel={(option) => option.name || ''}
                    getOptionValue={(option) => option.id}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )
              )}
            />
          </div>
          <div className="SingleData">
            <Controller
              name="currency"
              control={control}
              // rules={{ required: "Currency is required" }}
              render={({ field, fieldState: { error } }) => (
                <MuiSelect
                  {...field}
                  label="Currency"
                  options={currencys}
                  className="bg-white"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="benifits"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Benefits"
                  variant="outlined"
                  size="small"
                  placeholder="Health Insurance"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="bonus"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  className="form-control"
                  label="Bonus"
                  variant="outlined"
                  size="small"
                  placeholder="2990"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="deduction"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  className="form-control"
                  label="Deduction"
                  variant="outlined"
                  size="small"
                  placeholder="900"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="collapseContainer d-grid mt-4">
          <div className="SingleData">
            <Controller
              name="city"
              control={control}
              // rules={{ required: "City is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="City"
                  variant="outlined"
                  size="small"
                  placeholder="Calicut"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="state"
              control={control}
              // rules={{ required: "State is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="State"
                  variant="outlined"
                  size="small"
                  placeholder="Kerala"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="pincode"
              control={control}
              rules={{
                // required: "Pincode is required",
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please enter a valid 6-digit pincode",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Pincode"
                  variant="outlined"
                  size="small"
                  placeholder="673433"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="office_phone"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  className="form-control"
                  label="Office Phone"
                  variant="outlined"
                  size="small"
                  placeholder="0483 8765432"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            />
          </div>
          <div className="SingleData">
            <Controller
              name="relative_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Relative Name"
                  variant="outlined"
                  size="small"
                  placeholder="Shinoy Mohan"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="relation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="form-control"
                  label="Relation"
                  variant="outlined"
                  size="small"
                  placeholder="Spouse"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="rel_phone_number"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  className="form-control"
                  label="Relative Phone Number"
                  variant="outlined"
                  size="small"
                  placeholder="+91 9876543210"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ sx: { height: "40px" } }}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="address_1"
              control={control}
              // rules={{ required: "Address is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextareaAutosize
                  {...field}
                  className="form-control"
                  placeholder="Address 1"
                  // style={{ minHeight: 100 }}
                  error={!!error}
                />
              )}
            /></div>
          <div className="SingleData">
            <Controller
              name="address_2"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextareaAutosize
                  {...field}
                  className="form-control"
                  placeholder="Address 2"
                  // style={{ minHeight: 100 }}
                  error={!!error}
                />
              )}
            /></div>
        </div>
      </CustomTabPanel>
    </Box>
  );
}
