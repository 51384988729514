import React, { useEffect, useState } from "react";
import Table from "../ogComponent/table";
import Spinner from "../ogComponent/spinner";
import axios from "axios";
import RoleModal from "../ogComponent/roleModal";
import { Modal, Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius:'10px',
};

const AddUser = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [header, setHeader] = useState([]);
  const [roleCount, setRoleCount] = useState(0);
  const [editingRole, setEditingRole] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        "https://hrmsbackend.prosevo.com/api/common/roles/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        const transformedRoles = response.data.map((role, index) => ({
          "SI NO": index + 1,
          id: role.id,
          role_name: role.role_name,
          // edit: (
          //   <button
          //     className="btn btn-primary btn-sm"
          //     onClick={() => handleEdit(role.id)}
          //   >
          //     <i className="bi bi-pencil"></i>
          //   </button>
          // ),
          delete: (
            <div className="w-100 h-75 d-flex justify-content-end">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleOpenModal(role)}
            >
              <i className="bi bi-trash"></i>
            </button>
            </div>
          ),
        }));

        setRoles(transformedRoles);
        setHeader([
          { name: "SI NO", label: "#", active: true },
          { name: "role_name", label: "ROLE NAME", active: true },
          { name: "delete", label: "", active: true },
          // { name: "edit", label: "", active: true },
        ]);
        setLoading(false);
        setRoleCount(transformedRoles.length);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(
        `https://hrmsbackend.prosevo.com/api/common/roles/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Refresh the roles list after successful deletion
      fetchRoles();
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  };

  // const handleEdit = (role) => {
  //   setEditingRole(role);
  //   // Open the modal for editing
  //   // You might need to implement this part based on your modal component
  // };

  const handleOpenModal = (role) => {
    setRoleToDelete(role);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setRoleToDelete(null);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
    <style>
    {`
      @media (max-width: 500px) {
        .modalmainbox {
          min-width: 75% !important;
        }
      }
    `}
  </style>
    <div className="addUserContainer w-100">
      <div className="tableMain d-flex flex-column px-3 px-md-4 py-3 py-md-4 gap-3">
        <div>
          <h4 className="fw-light mb-0">Roles</h4>
          <p
            className="fw-bold align-self-center mb-0"
            style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)" }}
          >
            Total {roleCount} role{roleCount !== 1 ? "s" : ""}
          </p>
        </div>

        <div className="adduserButtons w-100 d-flex gap-2 gap-md-4">
          <button
            className="btn btn-primary d-flex gap-3 align-items-center text-nowrap px-3 py-1"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{
              width: "min-content",
              fontSize: "clamp(10px, 1.1vw, 13px)",
              height: "35px",
            }}
          >
            <i className="bi bi-plus-lg"></i> Create Role
          </button>

          {/* <div
            className="Tablesearch d-flex align-items-end position-relative align-items-md-center border border-1 rounded-2"
            style={{ height: "35px", fontSize: "12px, 1.2vw, 14px" }}
          >
            <input
              className="form-control border-0 h-100"
              type="search"
              placeholder="Search..."
              aria-label="Search"
            />
            <span style={{ position: "absolute", right: "15px" }}>
              <i className="bi bi-search"></i>
            </span>
          </div> */}
        </div>

        {roleCount === 0 ? (
          <div
            className="d-flex justify-content-center align-items-end position-relative"
            style={{ height: "50vh" }}
          >
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{
                backgroundImage: "url(/assets/file.png)",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
              }}
            ></div>
            <p className="m-0 fw-light fs-5 position-relative">No Roles !</p>
          </div>
        ) : (
          <Table header={header} product={roles} onRowClick={() => { }} />
        )}
      </div>
      <RoleModal onRoleCreated={fetchRoles} setOpenModal={setOpenModal} />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ outline: "none" }} // Remove focus outline from Modal
      >
        <Box sx={{ ...modalStyle, outline: "none", padding:'25px'}} className="modalmainbox">
          <div className="w-100 d-flex align-items-center flex-column">
          <WarningAmberIcon sx={{ color: "#FFA000", fontSize: 60, width:'100%'}} />
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: "clamp(13px, 1.4vw, 17px)",
              mb: 2,
            }}
          >
            Confirm Delete
          </Box>
          <Box
            sx={{
              color: "#555",
              mb: 2,
              fontSize: "clamp(12px, 1.2vw, 14px)",
              textWrap: "wrap",
              textAlign:'center',
              paddingX:'10px'
            }}
          >
            Are you sure you want to delete the role "{roleToDelete?.role_name}"?
          </Box>
          </div>
          <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(roleToDelete?.id)}
            style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
          >
            Confirm
          </button>
          <button
            className="btn btn-secondary"
            onClick={handleCloseModal}
            style={{ fontSize: "clamp(12px, 1.2vw, 15px)"}}
          >
            Cancel
          </button>
          </div>
        </Box>
      </Modal>
    </div>
    </>
  );
  
};

export default AddUser;
