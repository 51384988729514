import React, { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DurationPicker from "../ogComponent/durationPicker";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";

export default function ShiftForm() {
  const [lateInValue, setLateInValue] = useState(dayjs("2022-04-17T15:30"));
  const [earlyOutValue, setEarlyOutValue] = useState(dayjs("2022-04-17T17:30"));
  const [overtimeMinimum, setOvertimeMinimum] = useState("00:30:00"); // Initially set to 30 minutes
  const [shiftName, setShiftName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      shift_name: shiftName,
      login_time: lateInValue.format("HH:mm:ss"),
      logout_time: earlyOutValue.format("HH:mm:ss"),
      time_bound_for_overtime: overtimeMinimum, // This will be in HH:mm:00 format
    };

    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        "https://hrmsbackend.prosevo.com/api/account/shifts/",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data sent successfully:", response.data);
      toast.success("New Shift Added!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Failed to save shift.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="overtimeContainer p-3">
      <form className="d-flex flex-column gap-3 align-items-start" onSubmit={handleSubmit}>
        <TextField
          label="Enter shift name"
          variant="outlined"
          fullWidth
          value={shiftName}
          onChange={(e) => setShiftName(e.target.value)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["TimePicker", "TimePicker", "TimePicker"]}
            sx={{
              width: "100%",
            }}
          >
            <TimePicker
              label="Set late-in"
              value={lateInValue}
              onChange={(newValue) => setLateInValue(newValue)}
            />
            <TimePicker
              label="Set Early-out"
              value={earlyOutValue}
              onChange={(newValue) => setEarlyOutValue(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>

        <div className="d-flex align-items-center gap-4 ps-2 align-self-start">
          <label
            style={{ fontSize: "clamp(10px, 1.2vw, 13px)" }}
            className="fw-bolder"
          >
            Set overtime minimum
          </label>
          <DurationPicker
            value={overtimeMinimum}
            onChange={(value) => setOvertimeMinimum(value)} // Capture the HH:mm:00 format
          />
        </div>

        <Button
          type="submit"
          variant="contained"
          className="align-self-end px-4 mt-3"
        >
          Save <i className="bi bi-check-circle ms-2"></i>
        </Button>
      </form>
    </div>
  );
}
