import { createSlice } from "@reduxjs/toolkit";

const initial_state = {
    emp_list: {},
    emp_count: 0,
}

const empSlice = createSlice({
    name: "emp_personal",
    initialState: initial_state,
    reducers: {
        increment: (state) => {
            state.emp_count += 1;
        },
        decrement: (state) => {
            state.emp_count -= 1;
        },
        addtoCart: (state, action) => {
            console.log(action);
        },
        setUserData: (state, action) => {
            state.emp_list = action.payload;
        }
    }
})

export const { increment, decrement, addtoCart, setUserData } = empSlice.actions;
export default empSlice.reducer;