import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ResponsiveDatePickers from "../ogComponent/datePicker";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EditLeaveModal = ({ open, handleClose, leaveId, onLeaveUpdated }) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors: leaveErrors },
    } = useForm({
        defaultValues: {
            leave_name: "",
            start_date: dayjs(),
            end_date: dayjs(),
            days: "1",
            image: null,
        },
    });

    const [fileInputValue, setFileInputValue] = useState(null);

    useEffect(() => {
        if (leaveId && open) {
            const fetchLeaveDetails = async () => {
                try {
                    const token = localStorage.getItem("accessToken");
                    const response = await axios.get(
                        `https://hrmsbackend.prosevo.com/api/leavemanagement/company-holiday/${leaveId}/`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                    const data = response.data;
                    setValue("leave_name", data.title);
                    setValue("start_date", dayjs(data.start_date));
                    setValue("end_date", dayjs(data.end_date));
                    setValue("days", data.days);
                    setFileInputValue(data.image ? data.image : null);
                } catch (error) {
                    console.error("Failed to fetch leave details:", error);
                }
            };
            fetchLeaveDetails();
        }
    }, [leaveId, open, setValue]);

    const onSubmitLeave = async (data) => {
        try {
            const token = localStorage.getItem("accessToken");

            const formData = new FormData();
            formData.append("title", `${data.leave_name}`);
            formData.append("start_date", data.start_date.format("YYYY-MM-DD"));
            formData.append("end_date", data.end_date.format("YYYY-MM-DD"));
            formData.append("days", data.days.toString());

            if (data.image && data.image.length > 0) {
                formData.append("image", data.image[0]);
            }

            const response = await axios.put(
                `https://hrmsbackend.prosevo.com/api/leavemanagement/company-holiday/${leaveId}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            toast.success("Leave updated !", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            onLeaveUpdated(response.data);
            handleClose();
        } catch (error) {
            console.error("There was an error updating the leave data!", error);
            toast.error("Failed to update leave details. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth="md"
            fullWidth
            aria-labelledby="edit-leave-dialog-title"
            PaperProps={{
                sx: {
                    maxWidth: "50%",
                    width: "100%",
                    mx: "auto",
                },
            }}
        >
            <DialogTitle
                id="edit-leave-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                Edit Leave
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="pt-3">
                <form
                    className="d-flex justify-content-center align-items-start gap-4 flex-column"
                    onSubmit={handleSubmit(onSubmitLeave)}
                >
                    <Controller
                        name="leave_name"
                        control={control}
                        rules={{ required: "Leave name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="leave-name"
                                label="Leave Name"
                                variant="outlined"
                                size="small"
                                error={!!leaveErrors.leave_name}
                                helperText={leaveErrors.leave_name?.message}
                                className="w-100"
                            />
                        )}
                    />
                    <Controller
                        name="image"
                        control={control}
                        render={({ field: { onChange, ref, ...rest } }) => (
                            <TextField
                                id="image"
                                type="file"
                                onChange={(e) => {
                                    onChange(e.target.files);  // Pass file to react-hook-form
                                    setFileInputValue(e.target.value); // Store file name for UI
                                }}
                                ref={ref}
                                size="small"
                                className="w-100"
                                InputProps={{
                                    // You should not set `value` for file inputs to avoid errors
                                    // value: fileInputValue || "", // remove this line
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="start_date"
                        control={control}
                        rules={{ required: "Start Date is required" }}
                        render={({ field }) => (
                            <ResponsiveDatePickers
                                label="Start Date"
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    <Controller
                        name="end_date"
                        control={control}
                        rules={{ required: "End Date is required" }}
                        render={({ field }) => (
                            <ResponsiveDatePickers
                                label="End Date"
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    <Controller
                        name="days"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="days"
                                label="Duration (days)"
                                variant="outlined"
                                size="small"
                                disabled
                                className="w-100"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        )}
                    />
                    <Button type="submit" variant="contained" className="align-self-end px-4">
                        Update <i className="bi bi-check-circle ms-2"></i>
                    </Button>
                </form>
            </DialogContent>
        </Dialog>

    );
};

export default EditLeaveModal;